import { ChangeEventHandler, FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { Box, Collapse, Divider, Grid, IconButton, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import isEqual from 'fast-deep-equal';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Conversation as ConversationType, MessageType, Direction, SavedMessageWithName, SendRawPayload } from 'src/api/zrm';
import { ChatMessage } from 'src/components/chat';
import CustomerName from 'src/components/CustomerName';
import Scrollbar from 'src/components/Scrollbar';
import { useIdleHelperContext } from 'src/contexts/IdleHelperContext';
import useApi from 'src/hooks/useApi';
import useMessaging from 'src/hooks/useMessaging';
import useSettings from 'src/hooks/useSettings';
import useUser from 'src/hooks/useUser';
import XIcon from 'src/icons/X';
import { Stage } from 'src/types/stage';

const Conversation: FC<ConversationType & { borderRadius: string, width?: string | number }> = memo((props) => {
  const { customer_id: customerId, open, borderRadius, width, application_id_or_lead_id: applicationIdOrLeadId, phone_number: phoneNumber, pni } = props;
  const { api } = useApi();
  const theme = useTheme();
  const user = useUser();
  const { t } = useTranslation();
  const { maximizeConversation, minimizeConversation, removeConversation } = useMessaging();
  const { country } = useSettings();
  const { isTabActive } = useIdleHelperContext();

  const toggleOpen = useMemo(() => () => open ? minimizeConversation(customerId) : maximizeConversation(customerId), [open, customerId]);
  const [data, setData] = useState<{ stage: Stage, messages: Array<SavedMessageWithName> }>({ stage: null, messages: [] });
  const [messageString, setMessageString] = useState('');
  const rootRef = useRef<any>(null);
  const [page] = useState(0);

  const scrollToBottom = useMemo(() => () => {
    // eslint-disable-next-line no-underscore-dangle
    if (rootRef?.current?._container) rootRef.current._container.scrollTop = rootRef.current._container.scrollHeight;
  }, [rootRef]);

  useEffect(scrollToBottom, [data.messages.length]);

  const fetchMessages = useMemo(() => async (requestId?: string) => {
    if (!requestId) requestId = uuid();

    const requestBody = {
      customer_id: customerId,
      phone_number: phoneNumber,
      pni,
    };
    try {
      const resp = await api.message.getMessagesByCustomerIdMessageByCustomerPost(requestBody, { page }, { cancelToken: requestId, headers: { 'X-Request-ID': requestId } });
      setData((p) => (p.stage === Stage.fulfilled && isEqual(p.messages, resp.data)) ? p : { ...p, messages: resp.data, stage: Stage.fulfilled });
    } catch (e) {
      console.log('Error', e);

      if (e?.name === 'AbortError') return;

      setData((p) => ({ ...p, stage: Stage.rejected }));
    }
  }, [api, customerId, page]);

  useEffect(() => {
    if (!isTabActive) return () => {};

    const requestIdInit = uuid();
    setData((p) => ({ ...p, stage: Stage.pending }));
    fetchMessages(requestIdInit);

    let requestId: string;
    const i = setInterval(() => {
      api.abortRequest(requestId);
      requestId = uuid();
      fetchMessages(requestId);
    }, 10000);

    return () => {
      api.abortRequest(requestIdInit);
      api.abortRequest(requestId);
      clearInterval(i);
    };
  }, [api, setData, fetchMessages, isTabActive]);

  const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useMemo(() => (e) => setMessageString(e.target.value), []);

  const handleSend = useMemo(() => async () => {
    const requestId = uuid();
    setData((p) => ({ ...p, stage: Stage.pending }));
    try {
      const payload: SendRawPayload = {
        application_id_or_lead_id: applicationIdOrLeadId,
        message: messageString.trim(),
        ...(customerId ? { customer_id: customerId } : { phone_number: phoneNumber, country }),
      };

      await api.message.sendRawMessageMessageSendRawMessageTypePost(MessageType.SMS, payload, { cancelToken: requestId, headers: { 'X-Request-ID': requestId } });

      unstable_batchedUpdates(() => {
        setData((p) => ({ ...p, stage: Stage.fulfilled, messages: [...p.messages, { timestamp: new Date().toISOString(), message: messageString.trim(), message_id: uuid(), direction: Direction.OUTGOING, message_type: MessageType.SMS, customer_id: customerId, application_id: applicationIdOrLeadId, sender_name: `${user.first_name} ${user.last_name}` }] }));
        setMessageString('');
      });
      await fetchMessages();
    } catch (e) {
      toast.error(t('Problem sending comment') as string);
      setData((p) => ({ ...p, stage: Stage.rejected }));
    }
  }, [messageString, applicationIdOrLeadId, api, user, t, country]);

  return (
    <Box
      sx={{
        marginRight: 1,
        boxShadow: theme.shadows[20],
        display: 'flex',
        flexDirection: 'column',
        borderRadius,
        position: 'absolute',
        bottom: 0,
        width: width ?? 300,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'primary.main',
          borderRadius,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={toggleOpen}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box
              sx={{
                paddingLeft: 1,
              }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid item>
                  <CustomerName
                    color={theme.palette.primary.contrastText}
                    variant="subtitle2"
                    customerId={customerId}
                    pni={pni}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    sx={{
                      fontStyle: 'italic',
                      textAlign: 'left',
                    }}
                  >
                    {`(${applicationIdOrLeadId})`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* <Grid item>
          </Grid> */}
          <Grid item>
            <IconButton
              onClick={() => removeConversation(customerId)}
            >
              <XIcon
                fontSize="small"
                sx={{ color: theme.palette.primary.contrastText }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Collapse
        in={open}
      >
        <Box
          sx={{
            flex: 1,
            height: 400,
            background: theme.palette.background.paper,
            padding: 1,
          }}
        >
          <Scrollbar
            style={{ maxHeight: '500px' }}
            options={{ suppressScrollX: true }}
            ref={rootRef}
          >
            {data.messages.map((message) => (
              <ChatMessage
                key={message.message_id}
                contentType=""
                body={message.message}
                createdAt={message.timestamp}
                senderName={message.sender_name}
                senderAvatar=""
                direction={message.direction}
                senderType="user"
                withoutAvatar
              />
            ))}
          </Scrollbar>
        </Box>
        <Divider orientation="horizontal" />
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'background.paper',
            display: 'flex',
            flexShrink: 0,
            paddingLeft: 1,
            py: 1,
          }}
        >
          <TextField
            fullWidth
            maxRows={5}
            multiline
            onChange={handleCommentChange}
            placeholder={t('Send a message')}
            value={messageString}
            size="small"
            variant="outlined"
          />
          <Tooltip title={t('Send')}>
            <span>
              <IconButton
                color="primary"
                disabled={!messageString.trim()}
                onClick={handleSend}
              >
                <SendIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Collapse>
    </Box>
  );
});

export default Conversation;
