/* eslint-disable no-case-declarations */
import { createContext, FC, useMemo, useState } from 'react';

import { useCookies } from 'react-cookie';

import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';

export interface WSHelperContextValue {
  websocketUrl: (path: string) => Promise<string>;
  isAuthenticated: boolean,
  renewWSToken: () => Promise<void | string>;
}

const WSHelperContext = createContext<WSHelperContextValue>(null);

const WSHelperProviderComp = ({ children }) => {
  const { api } = useApi();
  const [cookies, setCookie] = useCookies(['websocketToken']);
  const [websocketToken, setWebsocketToken] = useState(cookies.websocketToken);
  const { isAuthenticated } = useAuth();

  const getWSToken = useMemo(() => (): Promise<string> => {
    if (!isAuthenticated) return null;

    return api.other.getTokenOtherWsTokenForWebsocketGet().then((resp) => {
      setWebsocketToken(resp.data.token);
      setCookie('websocketToken', resp.data.token, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 10) });

      return resp.data.token;
    });
  }, [api, isAuthenticated]);

  const websocketUrl = useMemo(() => async (path: string): Promise<string> => {
    let token: string = websocketToken;

    if (!token) token = await getWSToken();
    if (!token) return null;

    return `${process.env.REACT_APP_ZRM_URL.replace(/^http/, 'ws')}${path}?token=${token}`;
  }, [websocketToken, getWSToken]);

  return (
    <WSHelperContext.Provider value={{
      websocketUrl,
      isAuthenticated: !!websocketToken,
      renewWSToken: getWSToken,
    }}
    >
      {children}
    </WSHelperContext.Provider>
  );
};

export default WSHelperContext;
export const WSHelperProvider: FC<any> = (props) => {
  const { platform } = useAuth();
  const [isBank, isCustomer] = useMemo(() => [platform === AuthenticationPlatform.JWT, platform === AuthenticationPlatform.BankIdNo], [platform]);

  // eslint-disable-next-line react/destructuring-assignment
  if (isBank || isCustomer) return <>{props.children}</>;

  return <WSHelperProviderComp {...props} />;
};
