import MemoryStorage from './memoryStorage';

let shouldOverwriteStorage = false;
// Chrome doesn't allow access to local storage and session storage as a part of iframe.
// Without this CC my pages won't work in iframe.
try {
  window.sessionStorage.getItem('!');
  window.localStorage.getItem('!');
} catch (e) {
  shouldOverwriteStorage = true;
}

if (shouldOverwriteStorage) {
  console.log('Overwriting storage');

  Object.defineProperty(window, 'sessionStorage', {
    value: new MemoryStorage(),
    configurable: true,
    writable: true,
  });

  Object.defineProperty(window, 'localStorage', {
    value: new MemoryStorage(),
    configurable: true,
    writable: true,
  });
}
