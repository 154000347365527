import { Country, ExtendedProduct, Product, ZCountry, ZOffice, Zoordinates, ZProduct } from 'src/api/zrm';

export const zProductToProduct = (z: ZProduct): Product => {
  if (!z) return null;

  switch (z) {
    case ZProduct.MO:
      return Product.Mortgage;
    case ZProduct.UL:
      return Product.Blanco;
    case ZProduct.CC:
      return Product.CreditCard;
    default:
      return undefined;
  }
};

export const zProductToExtendedProduct = (z: ZProduct): ExtendedProduct => {
  if (!z) return null;

  switch (z) {
    case ZProduct.MO:
      return ExtendedProduct.Mortgage;
    case ZProduct.UL:
      return ExtendedProduct.Blanco;
    case ZProduct.CC:
      return ExtendedProduct.CreditCard;
    case ZProduct.LI:
      return ExtendedProduct.LoanInsurance;
    default:
      return undefined;
  }
};

export const productToZProduct = (p: Product): ZProduct => {
  if (!p) return null;

  switch (p) {
    case Product.Mortgage:
      return ZProduct.MO;
    case Product.Blanco:
      return ZProduct.UL;
    case Product.CreditCard:
      return ZProduct.CC;
    default:
      return undefined;
  }
};

export const extendedProductToZProduct = (p: ExtendedProduct): ZProduct => {
  if (!p) return null;

  switch (p) {
    case ExtendedProduct.Mortgage:
      return ZProduct.MO;
    case ExtendedProduct.Blanco:
      return ZProduct.UL;
    case ExtendedProduct.CreditCard:
      return ZProduct.CC;
    case ExtendedProduct.LoanInsurance:
      return ZProduct.LI;
    default:
      return undefined;
  }
};

export const zCountryToCountry = (z: ZCountry): Country => {
  if (!z) return null;

  switch (z) {
    case ZCountry.SE:
      return Country.SE;
    case ZCountry.NO:
      return Country.NO;
    default:
      return undefined;
  }
};

export const countryToZCountry = (c: Country): ZCountry => {
  if (!c) return null;

  switch (c) {
    case Country.SE:
      return ZCountry.SE;
    case Country.NO:
      return ZCountry.NO;
    default:
      return undefined;
  }
};

/**
  def parse_zoordinates(z: str) -> Zoordinates:
    return Zoordinates(**{
        k: v for k, v in zip(
            ['Product', 'Country', 'Office', 'EmploymentType', 'Department'],
            [z[i:i + 2] for i in range(0, len(z), 2)]  # type: ignore
        )
    })
 */
const positions: Array<keyof Zoordinates> = ['Product', 'Country', 'Office', 'EmploymentType', 'Department'];
export const parseZoordinates = (z: string): Zoordinates => z ? z.match(/.{1,2}/g).reduce((acc, v, i) => ({ ...acc, [positions[i]]: v }), {} as Zoordinates) : null;
/*
  ...
  def __str__(self) -> str:
    return ''.join([self.Product, self.Country, self.Office, self.EmploymentType, self.Department])
 */
export const stringifyZoordinates = (z: Zoordinates): string => z ? positions.reduce((acc, k) => acc + z[k], '') : null;

export const zOfficeToString = (z: ZOffice): string => {
  switch (z) {
    case ZOffice.ST:
      return 'Sthlm';
    case ZOffice.UP:
      return 'Upps';
    default:
      return z;
  }
};

export const translateZoordinates = {
  zProductToProduct,
  productToZProduct,
  zCountryToCountry,
  countryToZCountry,
  zProductToExtendedProduct,
  extendedProductToZProduct,
};
