import { FC, useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  LoginAuth0,
  LoginJWT,
} from 'src/components/authentication/login';
import Logo from 'src/components/Logo';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import { useDialmakerDesktopUtils } from 'src/contexts/dialmakerDesktop/DialmakerDesktopUtilsContext';
import useAuth from 'src/hooks/useAuth';
import { internalAuthPlatforms } from 'src/utils/auth/authPlatforms';

const platformIcons = {
  Auth0: '/static/icons/auth0.svg',
  JWT: '/static/icons/jwt.svg',
};

const Login: FC = () => {
  const { t } = useTranslation();
  const { togglePlatform, platform, changePlatform } = useAuth();
  const { isDesktopDialmakerApp } = useDialmakerDesktopUtils();

  const bankLogin = useMemo(() => platform === 'JWT', [platform]);
  const internalLogin = useMemo(() => platform === 'Auth0', [platform]);

  useEffect(() => {
    // Making sure that correct auth plarform is selected, to avoid bug when user connot log in
    if (!internalAuthPlatforms.includes(platform)) changePlatform(AuthenticationPlatform.Auth0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`${t('Login')} | ZRM`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        {isDesktopDialmakerApp && (
          <Box sx={{
            width: '100%',
            height: 28,
            backgroundColor: '#eaeaea',
            WebkitUserSelect: 'none',
            WebkitAppRegion: 'drag',
          } as any}
          />
        )}
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                minHeight: '300px',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <Box>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    {t('Log in')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {t('Log in to ZRM')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto',
                    },
                  }}
                >
                  <CardMedia
                    alt="Auth platform"
                    src={platformIcons[platform]}
                    component="img"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {internalLogin && <LoginAuth0 />}
                {bankLogin && <LoginJWT />}
              </Box>
              {!isDesktopDialmakerApp && (
                <>
                  <Divider sx={{ my: 3 }} />
                  <Button
                    onClick={togglePlatform}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {bankLogin && t('I am a Zensum employee')}
                      {internalLogin && t('I am a bank partner')}
                    </Typography>
                  </Button>
                  <Divider sx={{ my: 3 }} />
                  <Tooltip title={t('Talk to your team leader')}>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {t('No account?')}
                    </Typography>
                  </Tooltip>
                </>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
