import { FC, useState } from 'react';

import { Box, Button, Checkbox, Container, Drawer, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';

import { useMarketingConsentContext } from 'src/customerPages/context/marketing/MarketingConsentContext';

const MarketingConsentBox: FC = () => {
  const { showConstentBox, closeConsent } = useMarketingConsentContext();

  const { t } = useTranslation();

  const [consentChecked, setConsentChecked] = useState(false);

  return (
    <Drawer
      sx={{
        width: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          p: 2,
        },
      }}
      variant="temporary"
      anchor="bottom"
      open={showConstentBox}
      onBackdropClick={() => closeConsent(consentChecked)}
      transitionDuration={750}
    >
      <Container
        maxWidth="lg"
        sx={{ px: { xs: 1, md: 1, lg: 1 } }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => closeConsent(consentChecked)}
          >
            <Close />
          </IconButton>
        </Box>
        <Typography sx={{
          fontSize: '0.9rem',
        }}
        >
          <Trans>
            <Typography sx={{ fontSize: 'inherit' }}>
              Do you consent to Zensum sending loan and financial information, offers, tips or similar marketing via email and sms?
              You can easily remove your consent via the link in each message.
              Consent is voluntary and not a requirement to use our services.
            </Typography>
            <a href="https://gdpr-info.eu/">About GDPR on the EUs website.</a>
          </Trans>
        </Typography>
        <Box
          sx={{ marginLeft: { xs: '2em', sm: 0 }, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <FormControlLabel
            checked={consentChecked}
            color="primary"
            control={<Checkbox />}
            onChange={() => setConsentChecked((p) => !p)}
            label={t('Yes, I consent')}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 }, '& .MuiFormControlLabel-label': { marginLeft: '-8px' } }}
          />
          <img
            src="/static/gdpr_eu.png"
            height="70"
            width="auto"
            alt="BankID"
            loading="lazy"
          />
        </Box>
        <Box sx={{ pt: 2, textAlign: 'left' }}>
          <Button
            variant="outlined"
            color="success"
            sx={{ width: '100%',
              maxWidth: '400px',
              borderWidth: '3px',
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': {
                borderWidth: '3px',
              } }}
            onClick={() => closeConsent(consentChecked)}

          >
            {t('Open My Pages')}
          </Button>
        </Box>
      </Container>

    </Drawer>
  );
};

export default MarketingConsentBox;
