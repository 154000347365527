import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import useWebSocket from 'react-use-websocket';
import { v4 as uuid } from 'uuid';

import { NotificationEvent } from 'src/api/zrm';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import useNotifications from 'src/hooks/useNotifications';
import BellIcon from 'src/icons/Bell';
import { NotificationExtended } from 'src/types/notifications/notificationExtended';
import logger from 'src/utils/logger';

import NotificationSingle from './NotificationSingle';

const NotificationsPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const { api } = useApi();
  const { platform } = useAuth();
  const { getNotifications, websocketUrl, markAsReceived, mapNotifications, renewNotificationToken } = useNotifications();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const isBank = useMemo(() => platform === AuthenticationPlatform.JWT, [platform]);
  const [notifications, setNotifications] = useState<NotificationExtended[]>([]);
  const [maxVisibleNotifications] = useState(5);
  const didUnmount = useRef(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [location]);

  const refreshNotifications = useMemo(() => async (requestId: string) => {
    if (isBank) return;

    try {
      const responseNotifications = await getNotifications(requestId, 0, undefined, [NotificationEvent.CREATED, NotificationEvent.RECEIVED]);
      setNotifications(responseNotifications);
    } catch (err) {
      if (err?.name === 'AbortError') return;

      logger.error(err, { source: 'NotificationsPopover', description: 'Get notifications' });
    }
  }, [isBank, getNotifications]);

  useEffect(() => {
    const requestId = uuid();
    refreshNotifications(requestId);

    return () => { api.abortRequest(requestId); };
  }, [getNotifications, api]);

  useEffect(() => {
    if (!open) return () => {};

    const requestId = uuid();
    refreshNotifications(requestId);

    return () => { api.abortRequest(requestId); };
  }, [open]);

  const deleteNotificationFromState = useMemo(() => (notificationId: string) => {
    setNotifications((current) => current.filter((notification) => notification.notification_id !== notificationId));
  }, []);

  useEffect(() => () => { didUnmount.current = true; }, []);

  const {
    lastJsonMessage,
  } = useWebSocket(websocketUrl, {
    shouldReconnect: () => didUnmount.current === false,
    onClose: (e) => {
      console.log(e);

      // TOKEN_EXPIRED is sent when token is expired or invalid
      if (e.code === 1008 && e.reason.startsWith('TOKEN_EXPIRED')) renewNotificationToken();
    },
    onMessage: (e) => {
      console.log(e);
    },
    share: true,
  });

  useEffect(() => {
    if (!lastJsonMessage) return;

    const message = typeof lastJsonMessage === 'string' ? JSON.parse(lastJsonMessage) : lastJsonMessage;
    let mappedNotifications: NotificationExtended[];
    let notificationToShow: NotificationExtended;

    // Websocket can return array of notifications or single one
    if (Array.isArray(message)) mappedNotifications = mapNotifications(message.map((m) => JSON.parse(m)));
    else if (typeof message === 'string') mappedNotifications = mapNotifications([JSON.parse(message)]);

    if (mappedNotifications.length) {
      markAsReceived(mappedNotifications.map((n) => n.notification_id));
      setNotifications((current) => [...mappedNotifications, ...current]);
      [notificationToShow] = mappedNotifications;

      // TODO: Show info about more than last notification from array
      toast(() => (
        <>
          <NotificationSingle
            notification={notificationToShow}
            inToaster
            deleteNotificationFromState={deleteNotificationFromState}
          />
        </>
      ), {
        style: { padding: 0 },
      });
    // TODO: Desktop notify user
    }
  }, [lastJsonMessage]);

  return (
    <>
      <Tooltip title={t('Notifications')}>
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={notifications?.length ? notifications.length : undefined}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            {t('Notifications')}
          </Typography>
        </Box>
        {(
          notifications?.length === 0
            ? (
              <Box sx={{ p: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {t('There are no unread notifications')}
                </Typography>
              </Box>
            )
            : (
              <>
                <List disablePadding>
                  {notifications?.slice(0, maxVisibleNotifications).map((notification, index) => (
                    <div key={notification.notification_id}>
                      <NotificationSingle
                        notification={notification}
                        deleteNotificationFromState={deleteNotificationFromState}
                      />

                      { index !== maxVisibleNotifications - 1 ? <Divider /> : null}
                    </div>
                  ))}
                  {notifications.length > maxVisibleNotifications ? (
                    <Box
                      sx={{
                        py: 1,
                        px: 2,
                        fontSize: '0.75rem',
                      }}
                    >
                      {t('And')}
                      {' '}
                      {notifications.length - maxVisibleNotifications}
                      {' '}
                      {t('more')}
                    </Box>
                  ) : null}

                </List>

              </>
            )
        )}
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
          }}
        >
          <Button
            color="primary"
            size="small"
            variant="text"
            component={RouterLink}
            to="/notifications"
          >
            {t('See all notifications')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
