/* eslint-disable curly */
import { createContext, FC, useContext, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';

interface IdleHelperContextProps {
  isTabActive: boolean;
}

const IdleHelperContext = createContext<IdleHelperContextProps>({
  isTabActive: true,
});

export const IdleHelperProvider: FC = (props) => {
  const { children } = props;

  const [isTabActive, setIsTabActive] = useState(true);

  useIdleTimer({
    onIdle: () => {
      setIsTabActive(false);
    },
    onActive: () => {
      setIsTabActive(true);
    },
    timeout: 1000 * 30,
    throttle: 500,
  });

  return (
    <IdleHelperContext.Provider value={{
      isTabActive,
    }}
    >
      {children}
    </IdleHelperContext.Provider>
  );
};

export const useIdleHelperContext = (): IdleHelperContextProps => useContext(IdleHelperContext);
