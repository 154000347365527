import { FC } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Card from 'src/components/Card';
import { useIframeUtils } from 'src/contexts/IframeUtilsContext';
import { useCustomerLogin } from 'src/customerPages/context/login/CustomerLoginContext';

import LoginBoxLogoNorway from './common/LoginBoxLogoNorway';
import LoginBoxTermsOfUse from './common/LoginBoxTermsOfUse';

interface LoginBoxProps {
  isGr?: boolean;
}

const LoginBoxNo: FC<LoginBoxProps> = (props) => {
  const { isGr } = props;

  const { t } = useTranslation();
  const { heightBaseElementRef } = useIframeUtils();
  const { loginNo } = useCustomerLogin();

  return (
    <Card>
      <Grid
        container
        spacing={2}
        ref={heightBaseElementRef}
        sx={{ textAlign: 'center' }}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="h6">{isGr ? t('Share your debt register') : t('Login with BankID to see your offers and Gjeldsregisteret')}</Typography>
          {isGr && (
            <Typography>
              {t('If you share your debt information from the Debt Register, the chance of getting good loan offers increases.')}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <LoginBoxLogoNorway />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
        >
          <Button
            variant="contained"
            color="info"
            sx={{ ml: 'auto', width: '200px' }}
            size="large"
            onClick={loginNo}
          >
            {t('Sign in')}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 1 }}
        >
          <LoginBoxTermsOfUse />
        </Grid>
      </Grid>
    </Card>
  );
};

export default LoginBoxNo;
