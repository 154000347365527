import { FC } from 'react';

import { Box, Toolbar } from '@material-ui/core';

import AccountPopover, { AccountPopoverSize } from './AccountPopover';
import { DashboardNavbarRoot } from './DashboardNavbar';
import LanguagePopover, { LanguagePopoverSize } from './LanguagePopover';

import type { AppBarProps } from '@material-ui/core';

interface DesktopDashboardNavbarProps extends AppBarProps {
}

const DesktopDashboardNavbar: FC<DesktopDashboardNavbarProps> = (props) => {
  const { ...other } = props;

  return (
    <DashboardNavbarRoot
      {...other}
    >
      <Toolbar sx={{ minHeight: { xs: 28 }, px: 1 }}>
        <Box
          sx={{
            flexGrow: 1,
            height: 28,
            WebkitUserSelect: 'none',
            WebkitAppRegion: 'drag',
          } as any}
        />
        <Box sx={{ mr: 0.5 }}>
          <LanguagePopover size={LanguagePopoverSize.SMALL} />
        </Box>
        <AccountPopover size={AccountPopoverSize.SMALL} />
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DesktopDashboardNavbar;
