import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Country } from 'src/api/zrm';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import { CustomerLoginProvider } from 'src/customerPages/context/login/CustomerLoginContext';
import CustomerLoginPageNo from 'src/customerPages/pages/auth/CustomerLoginPageNo';
import CustomerLoginPageSe from 'src/customerPages/pages/auth/CustomerLoginPageSe';
import useAuth from 'src/hooks/useAuth';
import useQuery from 'src/hooks/useQuery';
import useSettings from 'src/hooks/useSettings';
import Login from 'src/pages/authentication/Login';
import NotFound from 'src/pages/NotFound';

export enum DesiredAuthPage {
  Internal = 'internal',
  External = 'external',
}
interface AuthGuardProps {
  authenticationPlatform?: AuthenticationPlatform;
  children: ReactNode;
  desiredPage?: DesiredAuthPage;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children, authenticationPlatform, desiredPage = DesiredAuthPage.Internal } = props;
  const { isAuthenticated, platform: authenticatedPlatform } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [autoLogin] = useQuery<boolean>('login-redirect', false);

  const { country } = useSettings();

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) setRequestedLocation(location.pathname);

    if (desiredPage === DesiredAuthPage.External) {
      if (country === Country.NO) return (
        <CustomerLoginProvider>
          <CustomerLoginPageNo autoLogin={autoLogin} />
        </CustomerLoginProvider>

      );

      return (
        <CustomerLoginProvider redirectTo={requestedLocation}>
          <CustomerLoginPageSe
            autoLogin={autoLogin}
            redirectTo={requestedLocation}
          />
        </CustomerLoginProvider>
      );
    }

    return <Login />;
  }

  if (desiredPage === DesiredAuthPage.Internal && authenticatedPlatform !== authenticationPlatform) {
    if (location.pathname === '/') return <Navigate to="/bank" />;

    return <NotFound />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);

    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
