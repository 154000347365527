import { FC } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Customer } from 'src/types/applicant/customer';

interface CustomerTableProps {
  customers: Customer[];
}

const CustomerTable: FC<CustomerTableProps> = (props) => {
  const { customers } = props;

  const { t } = useTranslation();

  return (
    <>
      <Table component="div">
        <TableHead component="div">
          <TableRow component="div">
            <TableCell component="span">
              {t('PNI')}
            </TableCell>
            <TableCell component="span">
              {t('First name')}
            </TableCell>
            <TableCell component="span">
              {t('Last name')}
            </TableCell>
            <TableCell component="span">
              {t('Phone number')}
            </TableCell>
            <TableCell component="span">
              {t('E-mail')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {customers?.map((customer) => (
            <TableRow
              hover
              key={customer.customer_id}
              sx={{
                textDecoration: 'none',
              }}
              component={Link}
              to={`/_customers/${customer.customer_id}`}
              state={customer}
            >
              <TableCell component="span">
                {customer.pni}
              </TableCell>
              <TableCell component="span">
                {customer.first_name}
              </TableCell>
              <TableCell component="span">
                {customer.last_name}
              </TableCell>
              <TableCell component="span">
                {customer.phone_number}
              </TableCell>
              <TableCell component="span">
                {customer.email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!customers.length && (
        <Box
          sx={{ textAlign: 'center', fontStyle: 'italic', py: 1 }}
        >
          {t('No data')}
        </Box>
      )}
    </>
  );
};

export default CustomerTable;
