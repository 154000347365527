import { FC, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Avatar, Box, Button, experimentalStyled, Link, List, Tooltip, Typography } from '@material-ui/core';
import { v4 } from 'uuid';

import { Direction } from 'src/api/zrm';
import { formatLongDateTime } from 'src/utils/formatDates';

export interface ChatMessageProps {
  body: string | Array<string>;
  contentType: string;
  createdAt: number | Date | string;
  senderAvatar: string;
  senderName: string;
  senderType: 'contact' | 'user';
  direction: Direction

  hasButton?: boolean;
  buttonText?: string;
  onButtonClickHandler?: any;
  withoutAvatar?: boolean;
  listElements?: Array<string>;
  listTitle?: string;
}

const MessageContainer = experimentalStyled('pre')(
  (
    () => ({
      fontFamily: 'inherit',
      margin: 0,
      whiteSpace: 'pre-wrap',
      overflowWrap: 'anywhere',
    })
  ),
);

const ChatMessage: FC<ChatMessageProps> = (props) => {
  const {
    body,
    contentType,
    createdAt,
    senderAvatar,
    senderName,
    senderType,
    direction,
    hasButton,
    buttonText,
    onButtonClickHandler,
    withoutAvatar,
    listElements,
    listTitle,
    ...other
  } = props;

  const chatMessageUuid = useMemo(() => v4(), []);

  return (
    <Box
      sx={{
        display: 'flex',
        mb: 2,
      }}
      {...other}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: direction === Direction.OUTGOING
            ? 'row-reverse'
            : 'row',
          maxWidth: '70%',
          ml: direction === Direction.OUTGOING ? 'auto' : 0,
        }}
      >
        {!withoutAvatar && (
        <Avatar
          src={senderAvatar}
          sx={{
            height: 32,
            ml: direction === Direction.OUTGOING ? 2 : 0,
            mr: direction === Direction.OUTGOING ? 0 : 2,
            width: 32,
          }}
        />
        )}
        <div>
          <Box
            sx={{
              backgroundColor: direction === Direction.OUTGOING
                ? 'primary.main'
                : 'background.paper',
              borderRadius: 1,
              boxShadow: 1,
              color: direction === Direction.OUTGOING
                ? 'primary.contrastText'
                : 'text.primary',
              px: 2,
              py: 1,
            }}
          >
            <Link
              color="inherit"
              component={RouterLink}
              to="#"
              variant="subtitle2"
            >
              {senderName}
            </Link>
            <Box sx={{ mt: 1 }}>
              {(typeof body === 'string' ? (
                <MessageContainer>
                  {body}
                </MessageContainer>
              ) : (body?.map((b, i) => (
                <MessageContainer
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`${b}-${i}`}
                >
                  {b}
                </MessageContainer>
              )))
              )}
              {!!listElements?.length && (
                <Box sx={{ mt: 1 }}>
                  {listTitle && (
                    <Typography>
                      {listTitle}
                      :
                    </Typography>
                  )}
                  <List sx={{ pt: 0, listStyleType: 'disc', px: '1rem' }}>
                    {listElements.map((element, index) => (
                      /* eslint-disable-next-line react/no-array-index-key */
                      <li key={`${chatMessageUuid}-${index}`}>{element}</li>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: direction === Direction.OUTGOING
                ? 'flex-end'
                : 'flex-start',
              mt: 1,
              px: 2,
            }}
          >
            <Tooltip title={formatLongDateTime(createdAt)}>
              <Typography
                color="textSecondary"
                noWrap
                variant="caption"
              >
                {formatLongDateTime(createdAt)}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: direction === Direction.OUTGOING
                ? 'flex-end'
                : 'flex-start',
              mt: 1,
              px: 2,
            }}
          >
            { hasButton && (
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
              onClick={onButtonClickHandler}
            >
              {buttonText}
            </Button>
            ) }

          </Box>

        </div>
      </Box>
    </Box>
  );
};

export default ChatMessage;
