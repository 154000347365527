import { createContext, FC, useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { EmploymentType, LoanType } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import HousingType, { HousingTypeValues } from 'src/types/common/housingType';
import logger from 'src/utils/logger';

import { AuthenticationPlatform } from './AuthContext';

export interface CountryBasedValues {
  employmentTypes: EmploymentType[];
  loanTypes: LoanType[];
  housingTypes: HousingType[];
}

const CountryBasedValuesContext = createContext(null);

export const CountryBasedValuesProvider: FC = (props) => {
  const { children } = props;
  const { country } = useSettings();
  const { api } = useApi();
  const { isAuthenticated, platform } = useAuth();

  const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>(Object.values(EmploymentType));
  const [loanTypes, setLoanTypes] = useState<LoanType[]>(Object.values(LoanType));
  const [housingTypes, setHousingTypes] = useState<HousingType[]>(HousingTypeValues);

  useEffect(() => {
    if (!country || !isAuthenticated || platform !== AuthenticationPlatform.Auth0) return () => {};

    const employeeRequestId = uuid();
    const housingTypesRequestId = uuid();
    const loanRequestId = uuid();
    (async () => {
      await Promise.all([
        api.customer.employmentTypesCustomerEmploymentTypesCountryGet(
          country, { headers: { 'X-Request-ID': employeeRequestId }, cancelToken: employeeRequestId },
        )
          .then((resp) => setEmploymentTypes(resp.data as EmploymentType[]))
          .catch((e) => {
            if (e?.name === 'AbortError') return;

            logger.error(e, { source: 'CountryBasedValuesContext', description: 'Get employment types for country', requestId: employeeRequestId });
          }),
        api.customer.housingTypesCustomerHousingTypesCountryGet(
          country, { headers: { 'X-Request-ID': housingTypesRequestId }, cancelToken: housingTypesRequestId },
        )
          .then((resp) => setHousingTypes(resp.data as HousingType[]))
          .catch((e) => {
            if (e?.name === 'AbortError') return;

            logger.error(e, { source: 'CountryBasedValuesContext', description: 'Get housing types for country', requestId: housingTypesRequestId });
          }),
        api.application.getPossibleLoanTypesApplicationLoanTypesCountryGet(
          country, { headers: { 'X-Request-ID': loanRequestId }, cancelToken: loanRequestId },
        )
          .then((resp) => setLoanTypes(resp.data as LoanType[]))
          .catch((e) => {
            if (e?.name === 'AbortError') return;

            logger.error(e, { source: 'CountryBasedValuesContext', description: 'Get loan types for country', requestId: loanRequestId });
          }),
      ]);
    })();

    return () => {
      api.abortRequest(employeeRequestId);
      api.abortRequest(loanRequestId);
      api.abortRequest(housingTypesRequestId);
    };
  }, [isAuthenticated, country, api, platform]);

  return (
    <CountryBasedValuesContext.Provider
      value={{
        employmentTypes: employmentTypes || [],
        loanTypes: loanTypes || [],
        housingTypes: housingTypes || [],
      }}
    >
      {children}
    </CountryBasedValuesContext.Provider>
  );
};

export default CountryBasedValuesContext;
