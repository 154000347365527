import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router';

const useQuery = <State>(key: string, defaultValue: State, typeOfParam?: 'number' | 'string' | 'boolean', replace = false): [State, Dispatch<SetStateAction<State>>] => {
  const t = useMemo(() => {
    switch (typeOfParam ?? typeof defaultValue) {
      case 'number':
        return Number;
      case 'boolean':
        return (v: State | string) => v === 'true';
      case 'string':
      default:
        return String;
    }
  }, [typeOfParam, defaultValue]);
  const location = useLocation();
  const navigate = useNavigate();

  const value: [State, Dispatch<SetStateAction<State>>] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const currentValue = t(params.get(key) ?? defaultValue) as any as State;
    const dispatch: Dispatch<SetStateAction<State>> = (v) => {
      const previous = t(params.get(key) ?? defaultValue) as any as State;
      const newValue = typeof v === 'function' ? (v as (val: State) => State)(previous) : v;

      if (previous !== newValue) {
        params.set(key, String(newValue));
        navigate({ ...location, search: `?${params.toString()}` }, { replace, state: location.state });
      }
    };

    return [currentValue, dispatch];
  }, [location, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (!params.get(key)) value[1](defaultValue);
  }, []);

  return value;
};

export default useQuery;
