import { v4 as uuid } from 'uuid';

type LogLevel = 'debug' | 'log' | 'warn' | 'error';
type LogMessage = string | { [key: string]: any; };

interface LogOptions {
  source?: string; // Component/hook/context name
  description?: string; // Small description about performed operation
  requestId?: string;
  [key: string]: any; // For values like ApplicationId etc.
}

const DEVICE_ID_KEY = 'deviceId';

const messageToString = (message: LogMessage): string => {
  if (typeof message === 'string') return message;

  // Errors may not be iterable so we can't just JSON.Stringify them
  if (message instanceof Error) return message.toString();

  // Stringify all other objects
  return JSON.stringify(message);
};

/**
 * Custom logger class, which allows to log messages to LogDNA,
 * but only when `REACT_APP_LOGDNA_API_KEY` is provided.
 */
class CustomLogger {
  public deviceId: string;

  constructor() {
    this.setDeviceId();
  }

  private setDeviceId = () => {
    let deviceId = localStorage.getItem(DEVICE_ID_KEY);

    if (!deviceId) {
      deviceId = uuid();
      localStorage.setItem(DEVICE_ID_KEY, deviceId);
    }

    this.deviceId = deviceId;
  };

  private formatLogObject = (message: LogMessage): string => {
    if (typeof message === 'string') return message;

    return Object.keys(message)
      .filter((key) => !!message[key])
      .map((key) => `${key}=${messageToString(message[key])}`)
      .join(' | ');
  };

  private logFn = (message: LogMessage, logLevel: LogLevel, options: LogOptions) => {
    console[logLevel](message, 'Log options:', { deviceId: this.deviceId, ...options });
  };

  setUserId = () => {
    // eslint-disable-next-line curly
  };

  debug = (message: LogMessage, options: LogOptions = {}) => {
    this.logFn(message, 'debug', options);
  };

  log = (message: LogMessage, options: LogOptions = {}) => {
    this.logFn(message, 'log', options);
  };

  error = (message: LogMessage, options: LogOptions = {}) => {
    // Check if message has 'error' key (ex. HttpError)
    const error = typeof message === 'string' ? message : message.error || message;

    this.logFn(error, 'error', options);
  };

  warn = (message: LogMessage, options: LogOptions = {}) => {
    this.logFn(message, 'warn', options);
  };
}

const logger = new CustomLogger();

export const { deviceId } = logger;

export default logger;
