/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BodyUploadModelForMlOrvarscoreUploadPost {
  /** An enumeration. */
  country: Country;

  /** An enumeration. */
  product: Product;

  /** @format binary */
  regressor: File;

  /** @format binary */
  classifier: File;

  /** @format binary */
  isotonic: File;
  version: string;
}

export interface BodyUploadModelSmartFilterUploadModelPost {
  /** @format binary */
  file: File;
  bank_ids: number[] | string;

  /** An enumeration. */
  country: Country;

  /** An enumeration. */
  product: Product;
  version: string;
}

/**
 * An enumeration.
 */
export enum Country {
  SE = "SE",
  NO = "NO",
}

/**
 * An enumeration.
 */
export enum EmploymentTypeNO {
  Full = "full",
  SelfEmployed = "selfEmployed",
  Retired = "retired",
  TemporarilyDischarged = "temporarilyDischarged",
  Other = "other",
  Disabled = "disabled",
  Trial = "trial",
  Hourly = "hourly",
  Student = "student",
  NoWork = "noWork",
  StandIn = "standIn",
  Project = "project",
  NorwegianSickLeave = "norwegianSickLeave",
}

/**
 * An enumeration.
 */
export enum Feature {
  ApplicantCreditCheckScore = "applicant.credit_check.score",
  ApplicantIncome = "applicant.income",
  ApplicantCreditsCarLoanSum = "applicant.credits.car_loan_sum",
  ApplicantCreditsMortgageLoanSum = "applicant.credits.mortgage_loan_sum",
  ApplicantCreditsStudentLoanSum = "applicant.credits.student_loan_sum",
  ApplicantCreditsUnsecuredSum = "applicant.credits.unsecured_sum",
  ApplicantCreditsOtherSum = "applicant.credits.other_sum",
  ApplicantCreditsCount = "applicant.credits.count",
  ApplicantDependentChildren = "applicant.dependentChildren",
  ApplicantHousingCost = "applicant.housingCost",
  ApplicantCreditCheckInquiries = "applicant.credit_check.inquiries",
  ApplicantCreditCheckHasPaymentRemarks = "applicant.credit_check.has_payment_remarks",
  ApplicantEmployerType = "applicant.employer.type",
  ApplicantPropertyType = "applicant.propertyType",
  ApplicantMaritalStatus = "applicant.maritalStatus",
}

export interface FileIds {
  file_id: string;
  type: string;
}

/**
 * An enumeration.
 */
export enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

/**
 * An enumeration.
 */
export enum HousingTypeNO {
  RENTED = "RENTED",
  OWN_HOUSE = "OWN_HOUSE",
  PARENTS = "PARENTS",
  CONDOMINIUM = "CONDOMINIUM",
  OWN_APARTMENT = "OWN_APARTMENT",
}

/**
 * An enumeration.
 */
export enum LoanPurposeNO {
  Refinance = "refinance",
  HomeRemodelling = "homeRemodelling",
  HealthcareExpenses = "healthcareExpenses",
  DivorceProceeding = "divorceProceeding",
  HomeDownpayment = "homeDownpayment",
  Investment = "investment",
  Education = "education",
  Travel = "travel",
  Car = "car",
  Other = "other",
  DriversLicense = "driversLicense",
  Wedding = "wedding",
  Relocation = "relocation",
  Interior = "interior",
  Consumption = "consumption",
  DebtCollection = "debtCollection",
}

/**
 * An enumeration.
 */
export enum MLModelType {
  SMART_FILTER = "SMART_FILTER",
  ORVARSCORE = "ORVARSCORE",
}

/**
 * An enumeration.
 */
export enum MaritalStatus {
  Unmarried = "unmarried",
  Married = "married",
  Divorced = "divorced",
  WidowWidower = "widow/widower",
  Deceased = "deceased",
  ChildUnder18Years = "child under 18 years",
  PreviouslyMarried = "previously married",
  RegisteredPartnership = "registered partnership",
  Partner = "partner",
}

export interface OrvarscorePayload {
  customer_id: string;
  application_id: string;
  application_number: number;
  new_customer: number;
  has_co_applicant?: number;

  /** An enumeration. */
  loan_purpose?: LoanPurposeNO;
  requested_amount?: number;
  requested_payback_months?: number;
  number_of_invites?: number;
  number_of_approved?: number;
  number_of_rejections?: number;
  number_of_accepted?: number;
  number_of_disbursed?: number;
  accepted_creditor?: number;
  disbursed_creditor?: number;
  best_offer_interest_rate?: number;
  best_approved_amount?: number;
  disbursed_amount?: number;
  age?: number;
  children?: number;

  /** An enumeration. */
  employment_type?: EmploymentTypeNO;
  employed_since?: number;
  salary_monthly?: number;

  /** An enumeration. */
  housing_type?: HousingTypeNO;
  housing_cost?: number;

  /** An enumeration. */
  marital_status?: MaritalStatus;

  /** An enumeration. */
  gender: Gender;
  credit_score?: number;
  credit_risk?: number;
  total_number_of_payment_remarks: number;
  total_payment_remark_amount: number;
  number_of_mandatory_remarks: number;
  number_of_voluntary_remarks: number;
  mandatory_dept: number;
  voluntary_dept: number;
  blanco_applications_count: number;
  blanco_approvals: number;
  blanco_accepts: number;
  blanco_disbursed_count: number;
  blanco_disbursed_amount: number;
  blanco_max_disbursed_amount: number;
  qualified_for_insurance: boolean;
  active_insurance: number;
  active_insurance_months?: number;
  insurance_premium: number;
  loans_count?: number;
  credit_limit?: number;
  interest_bearing_balance?: number;
  non_interest_bearing_balance?: number;
  total_balance?: number;
  max_nominal_interest_rate?: number;
  min_nominal_interest_rate?: number;
  avg_nominal_interest_rate?: number;
  days_since_gjeldsregisteret_check?: number;
  has_high_risk_mortgage_creditor: number;
  high_risk_mortgage_creditor_count: number;
  days_since_ev_check?: number;
  interest_rate_improvement?: number;
  owns_property: number;
  property_greatest_value?: number;
  months_since_first_registered_date?: number;
  months_since_latest_registered_date?: number;
  version?: string;
}

export interface PredictionScore {
  score: number;
  version: string;
}

/**
 * An enumeration.
 */
export enum Product {
  Blanco = "blanco",
  Mortgage = "mortgage",
  CreditCard = "credit_card",
}

export interface SmartFilter {
  /** @format date-time */
  timestamp: string;
  version: string;
  file_ids: FileIds[];

  /** An enumeration. */
  country: Country;

  /** An enumeration. */
  product: Product;

  /** An enumeration. */
  model_type: MLModelType;
  model_specific_identifier: SmartFilterIdentifier;
  user_id: string;
}

export interface SmartFilterIdentifier {
  bank_ids: number[];
}

export interface SmartFilterPayload {
  bank_id?: number;
  version?: string;
  file_id?: string;
}

export interface UploadResponse {
  model_id: string;
}

export interface ValidationError {
  loc: (string | number)[];
  msg: string;
  type: string;
}

export namespace SmartFilter {
  /**
   * No description
   * @tags Smart Filter
   * @name SmartFilterScoreSmartFilterScorePost
   * @summary Smart Filter Score
   * @request POST:/smart-filter/score
   * @secure
   */
  export namespace SmartFilterScoreSmartFilterScorePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = SmartFilterPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PredictionScore;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name UploadModelSmartFilterUploadModelPost
   * @summary Upload Model
   * @request POST:/smart-filter/upload_model
   * @secure
   */
  export namespace UploadModelSmartFilterUploadModelPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUploadModelSmartFilterUploadModelPost;
    export type RequestHeaders = {};
    export type ResponseBody = UploadResponse;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name AvailableModelsSmartFilterModelsGet
   * @summary Available Models
   * @request GET:/smart-filter/models
   * @secure
   */
  export namespace AvailableModelsSmartFilterModelsGet {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SmartFilter[];
  }
  /**
   * No description
   * @tags Smart Filter
   * @name GetModelSmartFilterModelsFileIdGet
   * @summary Get Model
   * @request GET:/smart-filter/models/{file_id}
   * @secure
   */
  export namespace GetModelSmartFilterModelsFileIdGet {
    export type RequestParams = { fileId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SmartFilter;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name AvailableFeaturesSmartFilterFeaturesFileIdGet
   * @summary Available Features
   * @request GET:/smart-filter/features/{file_id}
   * @secure
   */
  export namespace AvailableFeaturesSmartFilterFeaturesFileIdGet {
    export type RequestParams = { fileId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, string>;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name AllKnownFeaturesSmartFilterFeaturesGet
   * @summary All Known Features
   * @request GET:/smart-filter/features
   * @secure
   */
  export namespace AllKnownFeaturesSmartFilterFeaturesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Feature[];
  }
}

export namespace Orvarscore {
  /**
   * No description
   * @tags Orvarscore
   * @name SmartFilterScoreOrvarscoreScorePost
   * @summary Smart Filter Score
   * @request POST:/orvarscore/score
   * @secure
   */
  export namespace SmartFilterScoreOrvarscoreScorePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = OrvarscorePayload;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @tags Orvarscore
   * @name UploadModelForMlOrvarscoreUploadPost
   * @summary Upload Model For Ml
   * @request POST:/orvarscore/upload
   * @secure
   */
  export namespace UploadModelForMlOrvarscoreUploadPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUploadModelForMlOrvarscoreUploadPost;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
}

export namespace Health {
  /**
   * No description
   * @name GetHealthHealthGet
   * @summary Get Health
   * @request GET:/health
   */
  export namespace GetHealthHealthGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends globalThis.Response {
  data: D;
  error: E;
  requestId: string;
}

export interface HttpErrorResponse<E> extends globalThis.Response {
  error: E;
  requestId: string;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  private generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    // We need to generate new RequestID for every request
    let requestId = requestParams.headers["X-Request-ID"];
    if (!requestId) {
      requestId = this.generateUUID();
      requestParams.headers["X-Request-ID"] = requestId;
    }

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      r.requestId = requestId;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data as HttpErrorResponse<E>;
      return data;
    });
  };
}

/**
 * @title ML-Engine
 * @version 0.1.0
 *
 *
 * Score a model on a dataset.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  smartFilter = {
    /**
     * No description
     *
     * @tags Smart Filter
     * @name SmartFilterScoreSmartFilterScorePost
     * @summary Smart Filter Score
     * @request POST:/smart-filter/score
     * @secure
     */
    smartFilterScoreSmartFilterScorePost: (data: SmartFilterPayload, params: RequestParams = {}) =>
      this.request<PredictionScore, HTTPValidationError>({
        path: `/smart-filter/score`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name UploadModelSmartFilterUploadModelPost
     * @summary Upload Model
     * @request POST:/smart-filter/upload_model
     * @secure
     */
    uploadModelSmartFilterUploadModelPost: (
      data: BodyUploadModelSmartFilterUploadModelPost,
      params: RequestParams = {},
    ) =>
      this.request<UploadResponse, HTTPValidationError>({
        path: `/smart-filter/upload_model`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name AvailableModelsSmartFilterModelsGet
     * @summary Available Models
     * @request GET:/smart-filter/models
     * @secure
     */
    availableModelsSmartFilterModelsGet: (query?: { page?: number; limit?: number }, params: RequestParams = {}) =>
      this.request<SmartFilter[], HTTPValidationError>({
        path: `/smart-filter/models`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name GetModelSmartFilterModelsFileIdGet
     * @summary Get Model
     * @request GET:/smart-filter/models/{file_id}
     * @secure
     */
    getModelSmartFilterModelsFileIdGet: (fileId: string, params: RequestParams = {}) =>
      this.request<SmartFilter, HTTPValidationError>({
        path: `/smart-filter/models/${fileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name AvailableFeaturesSmartFilterFeaturesFileIdGet
     * @summary Available Features
     * @request GET:/smart-filter/features/{file_id}
     * @secure
     */
    availableFeaturesSmartFilterFeaturesFileIdGet: (fileId: string, params: RequestParams = {}) =>
      this.request<Record<string, string>, HTTPValidationError>({
        path: `/smart-filter/features/${fileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name AllKnownFeaturesSmartFilterFeaturesGet
     * @summary All Known Features
     * @request GET:/smart-filter/features
     * @secure
     */
    allKnownFeaturesSmartFilterFeaturesGet: (params: RequestParams = {}) =>
      this.request<Feature[], any>({
        path: `/smart-filter/features`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  orvarscore = {
    /**
     * No description
     *
     * @tags Orvarscore
     * @name SmartFilterScoreOrvarscoreScorePost
     * @summary Smart Filter Score
     * @request POST:/orvarscore/score
     * @secure
     */
    smartFilterScoreOrvarscoreScorePost: (data: OrvarscorePayload, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/orvarscore/score`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orvarscore
     * @name UploadModelForMlOrvarscoreUploadPost
     * @summary Upload Model For Ml
     * @request POST:/orvarscore/upload
     * @secure
     */
    uploadModelForMlOrvarscoreUploadPost: (
      data: BodyUploadModelForMlOrvarscoreUploadPost,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/orvarscore/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @name GetHealthHealthGet
     * @summary Get Health
     * @request GET:/health
     */
    getHealthHealthGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/health`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
