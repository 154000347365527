import { ChangeEvent, Dispatch, FC, MouseEvent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { ApplicantRole, AppsmgrModelsStatusStatus as ApplicationStatus, Substatus as ApplicationSubstatus, MeetingWithHandlerName, Application as Mortgage, MortgageLoanType } from 'src/api/zrm/zrmApi';
import Card from 'src/components/Card';
import CustomerName from 'src/components/CustomerName';
import Label from 'src/components/Label';
import MortgageListFilters, { MortgageListFiltersType } from 'src/components/mortgage/filters/MortgageListFilters';
import Scrollbar from 'src/components/Scrollbar';
import CenteredTooltip from 'src/components/widgets/info/CenteredTooltip';
import useApi from 'src/hooks/useApi';
import useUtils from 'src/hooks/useUtils';
import { formatLongDateTime } from 'src/utils/formatDates';
import { parseDate } from 'src/utils/parseDate';

interface ApplicationListTableProps {
  applications: Mortgage[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  loading: boolean;
  setLimit: Dispatch<SetStateAction<number>>;
  limit: number;
  setFilters: Dispatch<SetStateAction<MortgageListFiltersType>>;
  filtersVisible: boolean;
  mineOnly?: boolean;
}

const getStatusLabel = ({ text, color }: { text: string, color: string }): JSX.Element => (
  <Label color={color as any}>
    {text}
  </Label>
);

export const ApplicationTable: FC<{ applications: Array<Mortgage> }> = ({ applications }) => {
  const { t } = useTranslation();
  const { formatCurrency, getCurrency } = useUtils();
  const { api } = useApi();

  const substatusMap = useMemo(() => (status: ApplicationSubstatus) => {
    switch (status) {
      case ApplicationSubstatus.DISBURSED:
        return {
          color: 'primary',
          text: t('Disbursed'),
        };
      case ApplicationSubstatus.ACTIVE:
        return {
          color: 'success',
          text: t('Active'),
        };
      case ApplicationSubstatus.ACCEPTED:
        return {
          color: 'success',
          text: t('Accepted'),
        };
      case ApplicationSubstatus.UNKNOWN:
        return {
          color: 'warning',
          text: t('Unknown'),
        };
      case ApplicationSubstatus.DRAFT:
        return {
          color: 'warning',
          text: t('Draft'),
        };
      case ApplicationSubstatus.OFFERED:
        return {
          color: 'secondary',
          text: t('Offered'),
        };
      default:
        return {
          color: 'warning',
          text: t(status),
        };
    }
  }, [t]);

  const statusMap = useMemo(() => (status: ApplicationStatus) => {
    switch (status) {
      case ApplicationStatus.DEACTIVATED:
        return {
          color: 'error',
          text: t('Deactivated'),
        };
      case ApplicationStatus.ACTIVE:
        return {
          color: 'success',
          text: t('Active'),
        };
      case ApplicationStatus.LEAD:
        return {
          color: 'warning',
          text: t('Lead'),
        };
      case ApplicationStatus.DRAFT:
        return {
          color: 'warning',
          text: t('Draft'),
        };
      default:
        return {
          color: 'warning',
          text: t(status),
        };
    }
  }, [t]);

  const loanPurposeLabels = useMemo(() => new Map([
    [MortgageLoanType.REFINANCE, t('Move')],
    [MortgageLoanType.PRE_APPROVAL, t('Pre-approval')],
    [MortgageLoanType.BUY, t('Buy')],
  ]), [t]);

  const [upcomingMeetings, setUpcomingMeetings] = useState<{ data: Array<MeetingWithHandlerName>, loading: boolean }>({ data: [], loading: false });

  useEffect(() => {
    if (!applications.length) return () => { };

    setUpcomingMeetings((p) => ({ ...p, loading: true }));
    const requestId = v4();

    (async () => {
      try {
        const resp = await api.meeting.getMeetingsMeetingGetPost({ application_ids: applications.map((x) => x.application_id), with_handler_name: true }, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId });
        setUpcomingMeetings({ data: resp.data, loading: false });
      } catch (e) {
        setUpcomingMeetings((p) => ({ ...p, loading: false }));
      }
    })();

    return () => { api.abortRequest(requestId); };
  }, [applications, api]);

  return (
    <Table component="div">
      <TableHead component="div">
        <TableRow component="div">
          <TableCell component="span">
            {t('Application ID')}
          </TableCell>
          <TableCell component="span">
            {t('Applicants')}
          </TableCell>
          <TableCell component="span">
            {t('Method')}
          </TableCell>
          <TableCell component="span">
            {t('Total')}
          </TableCell>
          <TableCell component="span">
            {t('Next meeting')}
          </TableCell>
          <TableCell component="span">
            {t('Substatus')}
          </TableCell>
          <TableCell component="span">
            {t('Status')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody component="div">
        {!applications?.length ? [] : applications.map((application: Mortgage) => {
          const upcomingMeeting = upcomingMeetings.data.filter((meeting) => meeting.application_id === application.application_id).sort((a, b) => -parseDate(a.meeting_start).diff(b.meeting_start))[0];

          const showMeetingWarning = application.status === ApplicationStatus.ACTIVE && upcomingMeeting && parseDate(moment.now()).diff(upcomingMeeting.meeting_start) > 0;

          return (
            <TableRow
              hover
              key={application.application_id}
              selected={false}
              component={RouterLink}
              to={`/mortgage/_application/${application.application_id}`}
              state={{ app: application }}
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <TableCell component="span">
                <Typography />
                {application.application_id}
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {formatLongDateTime(application.date_created)}
                </Typography>
              </TableCell>
              <TableCell component="span">
                <CustomerName
                  color="textPrimary"
                  variant="subtitle2"
                  customerId={application.applicants.find((a) => a.role === ApplicantRole.MAIN_APPLICANT)?.customer_id}
                />
                <CustomerName
                  color="textSecondary"
                  variant="body2"
                  customerId={application.applicants.find((a) => a.role === ApplicantRole.CO_APPLICANT)?.customer_id}
                />
              </TableCell>
              <TableCell component="span">
                {loanPurposeLabels.get(application.loan?.loan_purpose) || '---'}
              </TableCell>
              <TableCell component="span">
                {application.loan?.mortgage_loan_amount ? formatCurrency(application.loan?.mortgage_loan_amount, getCurrency(application.country), 0) : '-'}
              </TableCell>
              <TableCell component="span">
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                >
                  {showMeetingWarning && (
                    <Grid
                      item
                      sx={{ mx: 1, mt: 1 }}
                    >
                      <CenteredTooltip title={t('This application is ACTIVE and has last activity in past')}>
                        <Warning color="error" />
                      </CenteredTooltip>
                    </Grid>
                  )}
                  {upcomingMeeting && (
                    <Grid item>
                      {upcomingMeeting?.handler_name}
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {parseDate(upcomingMeeting.meeting_start).format('LLL')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </TableCell>
              <TableCell component="span">
                {getStatusLabel(substatusMap(application.substatus))}
              </TableCell>
              <TableCell component="span">
                {getStatusLabel(statusMap(application.status))}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const ApplicationListTable: FC<ApplicationListTableProps> = (props) => {
  const { applications, page, setPage, loading, limit, setLimit, setFilters, filtersVisible, mineOnly, ...other } = props;
  const { t } = useTranslation();

  const statusFilterOptions = useMemo(() => Object.keys(ApplicationStatus), []);

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  return (
    <>
      <Card
        {...other}
        loading={loading}
        title={t('Applications')}
      >
        {filtersVisible ? (
          <Box sx={{ marginBottom: 1, marginLeft: 1 }}>
            <MortgageListFilters
              handleFiltersChange={setFilters}
              statusOptions={statusFilterOptions}
              hasSubstatus
              showUnassignedFilter={!mineOnly}
            />
          </Box>
        ) : null}
        <Scrollbar>
          <Box sx={{ position: 'relative' }}>
            <ApplicationTable applications={applications || []} />
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={(applications?.length && applications?.length >= limit) ? -1 : (page * limit + applications.length)}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Card>
    </>
  );
};

export default ApplicationListTable;
