import { AuthUser } from 'src/types/auth';

import useAuth from './useAuth';

const useUser = () => {
  const { authUser } = useAuth();

  return authUser || {} as AuthUser;
};

export default useUser;
