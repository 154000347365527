import type { FC } from 'react';

import { experimentalStyled } from '@material-ui/core/styles';

import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('img')``;

const FridayLogo: FC<LogoProps & { height?: number, width?: number }> = (props) => (
  <LogoRoot
    height="100"
    width="100"
    src="/static/icons/fridayLogo.gif"
    alt="Zensum Friday"
    {...props}
  />
);

export default FridayLogo;
