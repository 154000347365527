import { Country } from 'src/api/zrm';

export const defaultTimezone = (country: Country) => {
  switch (country) {
    case Country.NO:
      return 'Europe/Oslo';
    case Country.SE:
    default:
      return 'Europe/Stockholm';
  }
};
