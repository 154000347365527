import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@material-ui/core';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Country, Product } from 'src/api/zrm';
import SettingsDrawer from 'src/components/SettingsDrawer';
import { Zoordinates as ZoordinatesComponent } from 'src/components/zoordinates';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import { useDialmakerDesktopUtils } from 'src/contexts/dialmakerDesktop/DialmakerDesktopUtilsContext';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import useUser from 'src/hooks/useUser';
import CogIcon from 'src/icons/Cog';
import { BankUser, ZensUser } from 'src/types/auth';
import logger, { deviceId } from 'src/utils/logger';

import TelavoxUserInfo from '../dialmakerDesktop/telavox/TelavoxUserInfo';

const separator = ' | ';

export enum AccountPopoverSize {
  SMALL = 20,
  LARGE = 32,
}

interface AccountPopoverProps {
  size?: AccountPopoverSize;
}

const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { size = AccountPopoverSize.LARGE } = props;

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { logout, platform: authenticationPlatform } = useAuth();
  const user = useUser() as ZensUser & BankUser;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState<{ open: boolean, settingsOpen: boolean }>({ open: false, settingsOpen: false });
  const { settings, saveSettings, setBankCountryProduct, country: selectedCountry, product: selectedProduct } = useSettings();
  const { isDesktopDialmakerApp } = useDialmakerDesktopUtils();

  const isBank = useMemo(() => authenticationPlatform === AuthenticationPlatform.JWT, [authenticationPlatform]);

  const bankCountryProductOptions = useMemo(() => {
    if (!isBank || !user?.banks) return null;

    return [...new Set(user.banks.map(({ country, product }) => [country, product].join(separator)))];
  }, [user, isBank]);

  const handleOpen = useMemo(() => (): void => {
    setState((p) => ({ ...p, open: true }));
  }, [setState]);

  const handleClose = useMemo(() => (): void => {
    setState((p) => ({ ...p, open: false }));
  }, [setState]);

  const handleLogout = useMemo(() => async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      logger.error(err, { source: 'AccountPopover', description: 'Trying to logout' });
      toast.error(t('Unable to logout.') as string);
    }
  }, [handleClose, logout]);

  const zoordinatesTranslation = useMemo(() => {
    switch (i18n.language) {
      case 'sv':
      case 'nb':
        return 'Zoordinater';
      case 'en':
      default:
        return 'Zoordinates';
    }
  }, [i18n.language]);

  const handleZoordinatesChange = useMemo(() => (event: ChangeEvent<any>) => {
    const z = event.target.value;
    saveSettings((previous) => {
      if (!previous?.zoordinates || previous.zoordinates !== z) return { ...previous, zoordinates: z };

      return previous;
    });
  }, [saveSettings]);

  const handleBankCountryProductChange = useMemo(() => (event: ChangeEvent<any>) => {
    const [c, p] = event.target.value.split(separator) as [Country, Product];
    setBankCountryProduct((current) => (current.product === p && current.country === c) ? current : ({ ...current, product: p, country: c }));
  }, [setBankCountryProduct]);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: size,
            width: size,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={state.open}
        PaperProps={{
          sx: { width: 360 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {user.name ?? `${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            Zensum
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            component={Box}
            onClick={() => setState((p) => ({ ...p, settingsOpen: true }))}
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {t('Additional Settings')}
                </Typography>
              )}
            />
          </MenuItem>
          {!isBank && (
          <>
            <ListItem
              component={Box}
            >
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {zoordinatesTranslation}
              </Typography>
            </ListItem>
            {user?.zoordinates?.length > 1 && (
            <ListItem
              component={Box}
            >
              <Select
                fullWidth
                label={zoordinatesTranslation}
                name="zoordinates"
                onChange={handleZoordinatesChange}
                value={settings.zoordinates || user?.zoordinates[0]}
                variant="outlined"
              >
                {user.zoordinates.map((o) => (
                  <MenuItem
                    key={o}
                    value={o}
                  >
                    <ZoordinatesComponent zoordinates={o} />
                  </MenuItem>
                ))}
              </Select>
            </ListItem>
            )}
            {user?.zoordinates?.length === 1 && (
            <ListItem>
              <ZoordinatesComponent zoordinates={user.zoordinates[0]} />
            </ListItem>
            )}
          </>
          )}
          {isBank && (
          <>
            <ListItem
              component={Box}
            >
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {t('Country and Product')}
              </Typography>
            </ListItem>
            {bankCountryProductOptions?.length > 1 && (
            <ListItem
              component={Box}
            >
              <Select
                fullWidth
                label={t('Country and Product')}
                name="bankCountryProduct"
                onChange={handleBankCountryProductChange}
                value={[selectedCountry, selectedProduct].join(separator)}
                variant="outlined"
              >
                {bankCountryProductOptions?.map((o) => (
                  <MenuItem
                    key={o}
                    value={o}
                  >
                    <Typography>
                      {t(o)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </ListItem>
            )}
            {bankCountryProductOptions?.length === 1 && (
            <ListItem>
              <Typography>
                {t(bankCountryProductOptions[0])}
              </Typography>
            </ListItem>
            )}
          </>
          )}
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography fontSize="small">{`DeviceId: ${deviceId}`}</Typography>
        </Box>
        {isDesktopDialmakerApp && (
          <Box
            sx={{ px: 2 }}
          >
            <TelavoxUserInfo closeCallback={handleClose} />
          </Box>
        )}
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {t('Logout')}
          </Button>
        </Box>
      </Popover>
      <SettingsDrawer
        open={state.settingsOpen}
        setOpen={(o) => setState((s) => ({ ...s, settingsOpen: typeof o === 'function' ? o(s.settingsOpen) : o }) as any)}
      />
    </>
  );
};

export default AccountPopover;
