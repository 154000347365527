import { FC, MouseEvent, useMemo } from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { Insurance } from 'src/api/zaker';
import CustomerName from 'src/components/CustomerName';
import Scrollbar from 'src/components/Scrollbar';
import useUtils from 'src/hooks/useUtils';

import { SubStatusLabel } from './labels';

const InsuranceTable: FC<{ insurances: Array<Insurance> }> = (props) => {
  const { insurances } = props;
  const { t } = useTranslation();
  const { formatCurrency } = useUtils();
  const navigate = useNavigate();
  const onClick = useMemo(() => (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, insurance: Insurance) => {
    const target = `/insurance/_/${insurance.insuranceId}`;

    if (e.ctrlKey || e.metaKey) { // ctrl or cmd click
      window.open(target);

      return undefined;
    }

    return navigate(target, { replace: false, state: { insurance } });
  }, [navigate]);

  return (
    <Scrollbar>
      <Box sx={{ position: 'relative' }}>
        <Table component="div">
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="span">
                {t('Insurance ID')}
              </TableCell>
              <TableCell component="span">
                {t('Customer')}
              </TableCell>
              <TableCell component="span">
                {t('Total')}
              </TableCell>
              <TableCell component="span">
                {t('Status')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {!!insurances?.length && insurances.map((insurance) => (
              <TableRow
                hover
                key={insurance.insuranceId}
                selected={false}
                onClick={(e) => onClick(e, insurance)}
                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                component="div"
              >
                <TableCell component="span">
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {insurance.insuranceId}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {format(moment.utc(insurance.signupDate || insurance.dateCreated).toDate(), 'dd MMM yyyy | HH:mm')}
                  </Typography>
                </TableCell>
                <TableCell component="span">
                  <CustomerName
                    color="textPrimary"
                    variant="subtitle2"
                    customerId={insurance.customerId}
                    defaultString={`${t('Loading')}...`}
                  />
                </TableCell>
                <TableCell component="span">
                  {formatCurrency(insurance.insuredAmount, undefined, 0)}
                </TableCell>
                <TableCell component="span">
                  <SubStatusLabel substatus={insurance.substatus} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Scrollbar>
  );
};

export default InsuranceTable;
