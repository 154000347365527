import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';
import { v4 } from 'uuid';

import { Employee, TelavoxExtension } from 'src/api/zrm';
import useFetchAllEmployees from 'src/hooks/api/useFetchAllEmployees';
import useApi from 'src/hooks/useApi';
import logger from 'src/utils/logger';

import { useDialmakerDesktopUtils } from './DialmakerDesktopUtilsContext';

interface DialmakerDesktopCacheContextProps {
  pauseOnNext: boolean;
  setPauseOnNext: Dispatch<SetStateAction<boolean>>;
  outcomesBoxHeight: number;
  setOutcomesBoxHeight: Dispatch<SetStateAction<number>>;
  employees: Employee[];
  telavoxUser: TelavoxExtension;
  refreshTelavoxUser: (showToast?: boolean, requestId?: string) => void;
  telavoxUserLoading: boolean;
}

const DialmakerDesktopCacheContext = createContext<DialmakerDesktopCacheContextProps>({
  pauseOnNext: false,
  setPauseOnNext: () => {},
  outcomesBoxHeight: 55,
  setOutcomesBoxHeight: () => {},
  employees: [],
  telavoxUser: null,
  refreshTelavoxUser: () => {},
  telavoxUserLoading: false,
});

export const DialmakerDesktopCacheProvider: FC = (props) => {
  const { children } = props;

  const { api } = useApi();
  const { employees } = useFetchAllEmployees();
  const { isDesktopDialmakerApp } = useDialmakerDesktopUtils();

  const [pauseOnNext, setPauseOnNext] = useState(false);
  const [outcomesBoxHeight, setOutcomesBoxHeight] = useState(55);

  const [telavoxUser, setTelavoxUser] = useState<TelavoxExtension>(null);
  const [isTelavoxUserLoading, setIsTelavoxUserLoading] = useState(false);

  const refreshTelavoxUser = useMemo(() => async (showToast = false, requestId = v4()) => {
    if (!isDesktopDialmakerApp) return;

    setIsTelavoxUserLoading(true);
    setTelavoxUser(null);

    try {
      const response = await api.larsMagnus.currentTelavoxUserLarsMagnusTelavoxUserGet(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setTelavoxUser(response.data);

      if (showToast) toast.success('Successfully updated Telavox Token');
    } catch (error) {
      if (error?.name === 'AbortError') return;

      logger.error(error, {
        source: 'DialmakerDesktopCacheContext',
        description: 'Refresh Telavox User',
        requestId,
      });

      if (showToast) toast.error(error?.error?.detail);
    }
    setIsTelavoxUserLoading(false);
  }, [api, isDesktopDialmakerApp]);

  useEffect(() => {
    const requestId = v4();

    refreshTelavoxUser(false, requestId);

    return () => { api.abortRequest(requestId); };
  }, [refreshTelavoxUser]);

  const value: DialmakerDesktopCacheContextProps = useMemo(() => ({
    pauseOnNext,
    setPauseOnNext,
    outcomesBoxHeight,
    setOutcomesBoxHeight,
    employees,
    telavoxUser,
    refreshTelavoxUser,
    telavoxUserLoading: isTelavoxUserLoading,
  }), [pauseOnNext, outcomesBoxHeight, employees, telavoxUser, refreshTelavoxUser, isTelavoxUserLoading]);

  return (
    <DialmakerDesktopCacheContext.Provider value={value}>
      {children}
    </DialmakerDesktopCacheContext.Provider>
  );
};

export const useDialmakerDesktopCache = (): DialmakerDesktopCacheContextProps => useContext(DialmakerDesktopCacheContext);
