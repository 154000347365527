import type { FC } from 'react';
import { createContext, useMemo } from 'react';

import { Income } from 'src/api/zrm/zrmApi';
import formatIncomes, { getUserFriendlyIncomeAmount, INCOME_AMOUNT_NO_KEY, INCOME_AMOUNT_SE_KEY } from 'src/utils/applicant/formatIncomes';
import { isSweden } from 'src/utils/country';

export interface ApplicantUtilsProps {
  formatIncomes: (incomes: Income[]) => Income[];
  incomesAmountKey: string;
  getUserFriendlyIncomeAmount: (value: number, income: Income) => number;
}

const ApplicantUtilsContext = createContext<ApplicantUtilsProps>({
  formatIncomes: () => [],
  incomesAmountKey: INCOME_AMOUNT_SE_KEY,
  getUserFriendlyIncomeAmount: () => null,
});

export const ApplicantUtilsProvider: FC = (props) => {
  const { children } = props;
  const isCountrySweden = isSweden();

  const utils: ApplicantUtilsProps = useMemo(() => ({
    formatIncomes: (incomes: Income[]) => formatIncomes(incomes, isCountrySweden),
    incomesAmountKey: isCountrySweden ? INCOME_AMOUNT_SE_KEY : INCOME_AMOUNT_NO_KEY,
    getUserFriendlyIncomeAmount: (value: number, income: Income) => getUserFriendlyIncomeAmount(value, income, isCountrySweden),
  }), [isCountrySweden, formatIncomes]);

  return (
    <ApplicantUtilsContext.Provider value={utils}>
      {children}
    </ApplicantUtilsContext.Provider>
  );
};

export default ApplicantUtilsContext;
