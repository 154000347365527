import { FC, useEffect, useMemo, useState } from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import type { HttpResponse, Name } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import { Stage } from 'src/types/stage';

const CustomerName: FC<{ pni?: string, customerId?: string, [key: string]: any }> = (props) => {
  const { customerId, pni, defaultString, ...other } = props;
  const { t } = useTranslation();
  const { api } = useApi();
  const [data, setData] = useState<{ name: Name, stage: Stage }>({ name: null, stage: null });

  useEffect(() => {
    if (!customerId && !pni) return () => {};

    setData((p) => p.stage !== Stage.pending ? ({ ...p, stage: Stage.pending }) : p);
    const requestId = uuid();
    (async () => {
      try {
        let resp: HttpResponse<Name>;

        if (customerId) resp = await api.customer.getNameCustomerNameCustomerIdGet(customerId, { cancelToken: requestId, headers: { 'X-Request-ID': requestId } });
        else resp = await api.customer.getNameByPniCustomerNameByPniPost({ pni }, { cancelToken: requestId, headers: { 'X-Request-ID': requestId } });

        setData((p) => ({ ...p, stage: Stage.fulfilled, name: resp.data }));
      } catch (e) {
        if (e?.name === 'AbortError') return;

        setData((p) => ({ ...p, stage: Stage.rejected, customer: null }));
      }
    })();

    return () => { api.abortRequest(requestId); };
  }, [customerId, api, setData, pni]);

  const name = useMemo(() => {
    if (!customerId && !pni) return '-';
    if (data.name?.name) return data.name.name;
    if (!data.name && (data.stage === null || data.stage === Stage.pending)) return `${t('Loading')}...`;
    if ((!data.name && data.stage === Stage.rejected) || !data.name?.name) return t('Name not found');

    return '-';
  }, [customerId, pni, data.name?.name, data.stage, t]);

  return (
    <Typography
      {...other}
    >
      {name}
    </Typography>
  );
};

export default CustomerName;
