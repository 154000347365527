import { useMemo, FC } from 'react';

import { experimentalStyled, useTheme } from '@material-ui/core/styles';

import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
  alwaysWhite?: boolean;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = (props) => {
  const { alwaysWhite, ...other } = props;

  const theme = useTheme();

  const fill = useMemo(() => (theme.palette.mode === 'light' && !alwaysWhite) ? 'black' : 'white', [theme.palette.mode, alwaysWhite]);

  return (
    <LogoRoot
      height="52"
      version="1.1"
      viewBox="0 0 152 52"
      width="140"
      {...other}
    >
      <title>Zensum</title>
      <svg
        id="Lager_1"
        data-name="Lager 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 280"
        fill={fill}
      >
        <path d="M1112.11,73.3c-18.18,0-44.4,11.54-57.34,36.71-5.94-19.93-30.42-36.71-56.64-36.71-18.19,0-41.61,10.14-52.8,33.57V78.54H916V262.11h29.37V154.77c0-37.07,25.52-53.15,50-53.15,21,0,34.61,14.69,34.61,52.8V262.11h29.37v-107c1-37.42,25.53-53.5,50-53.5,21,0,34.62,14.69,34.62,52.8V262.11H1174V154.42c0-65.74-28.67-81.12-61.89-81.12M853.9,78.54v99.3c0,54.2-32.52,61.19-50.7,61.19-21.68,0-38.47-14.68-38.47-52.79V78.54h-29v107.7c0,61.19,27.63,81.12,65,81.12,29.37,0,46.15-21.33,53.15-42.31v37.06h29.72V78.54ZM599.77,214.21H570.05c5.6,30.42,24.13,53.15,73.78,53.15,44.75,0,69.23-22.73,69.23-54.2,0-33.22-21-47.55-68.53-58.39-29.37-6.65-39.86-15-39.86-30.42,0-16.44,17.83-24.13,35-24.13,22.73,0,40.21,6.3,42.66,26.58h29.37C708.51,96,685.44,73.3,638.23,73.3c-35,0-62.58,21.68-62.58,49,0,37.06,14.33,48.25,72,61.19,28.68,6.29,36,16.78,36,31.47,0,18.53-18.88,25.17-39.16,25.17-25.53,0-40.56-9.09-44.76-25.87M484.18,73.3C454.46,73.3,437,95,430,116V78.54H400.61V262.11H430v-99.3c0-54.2,32.51-61.19,50.7-61.19,21.67,0,38.46,14.69,38.46,52.8V262.11h29.72V154.42c0-61.19-27.28-81.12-64.69-81.12M240.55,154.07c1.75-32.17,21.68-54.2,54.89-54.2S344,123,345.79,154.07Zm-29.72,16.08c0,45.46,25.17,97.21,84.61,97.21,38.46,0,72-23.78,78.32-53.15H340.9c-6.3,15.38-22.38,25.87-45.46,25.87-33.21,0-52.79-23.42-55.59-58.39H374.46c4.55-56.64-22.72-108.39-79-108.39-59.44,0-84.61,51.75-84.61,96.85M199.4,17.36H15.83v28H163L15.83,234.14v28H199.4v-28H52.89L199.4,45.33Z" />
      </svg>
    </LogoRoot>
  );
};

export default Logo;
