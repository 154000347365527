import { FC, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useDialmakerDesktopCache } from 'src/contexts/dialmakerDesktop/DialmakerDesktopCacheContext';
import useUser from 'src/hooks/useUser';
import { ZensUser } from 'src/types/auth';
import DialmakerDesktopColors from 'src/utils/dialmaker/styles/colors';

interface TelavoxUserInfoProps {
  closeCallback: () => void;
}

const styles = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.9rem',
  gap: 0.5,
  '& a': {
    color: DialmakerDesktopColors.link,
    '&:not(:hover)': {
      textDecoration: 'none',
    },
  },
};

const TelavoxUserInfo: FC<TelavoxUserInfoProps> = (props) => {
  const { closeCallback } = props;

  const { t } = useTranslation();
  const user = useUser() as ZensUser;
  const { telavoxUser } = useDialmakerDesktopCache();

  const hasTelavoxId = useMemo(() => !!user?.telavox_ids?.length, [user?.telavox_ids]);

  return (
    <Box>
      <Box sx={styles as any}>
        <Box>
          <Typography component="span">
            {t('ZRM-user')}
            :
            {' '}
          </Typography>
          <b>
            {user.name}
          </b>
        </Box>
        <Box>
          <Typography component="span">
            {t('Telavox-user')}
            :
            {' '}
          </Typography>
          <b>
            {telavoxUser?.name ?? t('Unknown')}
            {' '}
          </b>
          {!telavoxUser?.name && (
            <Typography
              component="span"
              sx={{ color: 'red', fontSize: '0.9rem' }}
            >
              {t('No Token set')}
            </Typography>
          )}
          {' '}
          -
          {' '}
          <RouterLink
            to="/dialmaker-desktop/telavox-details"
            onClick={closeCallback}
          >
            {telavoxUser?.name ? (
              <>{t('Change token')}</>
            ) : (
              <>{t('Set token')}</>
            )}
          </RouterLink>
        </Box>
        <Box>
          <Typography component="span">
            {t('Telavox-ID')}
            :
            {' '}
          </Typography>
          <b>
            {hasTelavoxId ? user.telavox_ids[0] : t('Unknown')}
            {' '}
          </b>
          {!hasTelavoxId && (
            <Typography
              component="span"
              sx={{ color: 'red', fontSize: '0.9rem' }}
            >
              {t('No Telavox ID set')}
            </Typography>
          )}
          {' '}
          -
          {' '}
          <Typography
            component="span"
            sx={{ fontSize: '0.9rem', fontStyle: 'italic' }}
          >
            {hasTelavoxId ? (
              <>{t('Change? Ask your team-lead')}</>
            ) : (
              <>{t('Ask your team-lead')}</>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TelavoxUserInfo;
