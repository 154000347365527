import { createContext, FC, useContext, useRef } from 'react';

import DataLoader from 'dataloader';

import { BankAccount, Email, ModelsValidatorServicePayloadPhoneNumber, PNI, ValidatorServiceResponseModel } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';

type ValidatorPayload = Email | ModelsValidatorServicePayloadPhoneNumber | PNI | BankAccount;

interface ValidatorContextProps {
  externalValidator: (payload: ValidatorPayload) => Promise<boolean>;
}

const ValidatorContext = createContext<ValidatorContextProps>({
  externalValidator: async () => false,
});

export const ValidatorContextProvider: FC = (props) => {
  const { children } = props;

  const { api } = useApi();

  const dataLoaderRef = useRef<DataLoader<ValidatorPayload, ValidatorServiceResponseModel>>(null);
  const externalValidatorRef = useRef<(payload: ValidatorPayload) => Promise<boolean>>(() => Promise.resolve(false));

  // useEffect(() => {
  //   if (!api) return;
  // TODO: figure out why this cannot be in a useEffect for BankID logins (my pages)!!
  dataLoaderRef.current = new DataLoader(
    (payloads) => Promise.all(
      payloads.map(async (payload: ValidatorPayload) => api.validator.validateSafelyValidatorSafePost(payload)
        .then(({ data }) => data)
        .catch((err) => dataLoaderRef.current.clear(payload) && Promise.reject(err))),
    ),
    {
      batch: false,
      cacheKeyFn: (payload) => JSON.stringify(Object.keys(payload).sort((a, b) => a > b ? 1 : -1).reduce((acc, key) => [...acc, key, payload[key]], [])),
    },
  );

  externalValidatorRef.current = async (payload: ValidatorPayload): Promise<boolean> => dataLoaderRef.current
    .load(payload)
    .then(({ valid }) => valid)
    .catch(() => false);
  // }, [api]);

  return (
    <ValidatorContext.Provider value={{
      externalValidator: externalValidatorRef.current,
    }}
    >
      {children}
    </ValidatorContext.Provider>
  );
};

export const useValidatorContext = (): ValidatorContextProps => useContext(ValidatorContext);
