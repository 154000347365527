import { createContext, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Country, CreditCardApplicationExtended, PageCreditCardApplicationExtended, Product } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useQuery from 'src/hooks/useQuery';
import logger from 'src/utils/logger';

export interface GetCreditCardApplicationsUtils {
  creditCardApplications: CreditCardApplicationExtended[],
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number,
  setLimit: Dispatch<SetStateAction<number>>,
  total: number,
  loading: boolean,
}

const GetCreditCardApplicationsContext = createContext<GetCreditCardApplicationsUtils>({
  creditCardApplications: [],
  page: 0,
  setPage: () => { },
  limit: 25,
  setLimit: () => { },
  total: null,
  loading: false,
});

export const GetCreditCardApplicationsProvider: FC = (props) => {
  const { children } = props;

  const { api } = useApi();
  const { t } = useTranslation();

  const [creditCardApplications, setCreditCardApplications] = useState<PageCreditCardApplicationExtended>({ items: [], total: 0, page: 0, size: 25 });
  const [page, setPage] = useQuery('page', 1);
  const [limit, setLimit] = useQuery('limit', 25);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestId = uuid();
    setLoading(true);

    (async () => {
      try {
        const response = await api.application.getRecentApplicationsApplicationCcNoGetRecentPost(
          { product: Product.CreditCard, country: Country.NO }, { page, size: limit }, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
        );
        unstable_batchedUpdates(() => {
          setCreditCardApplications(response.data);
          setLoading(false);
        });
      } catch (error) {
        if (error?.name === 'AbortError') return;

        setLoading(false);
        toast.error(t('Failed to fetch credit card applications').toString());
        logger.error(error, { source: 'GetCreditCardApplicationContext', description: 'Get credit card applications', requestId });
      }
    })();

    return () => api.abortRequest(requestId);
  }, [api, page, limit]);

  const value: GetCreditCardApplicationsUtils = useMemo(() => ({
    creditCardApplications: creditCardApplications.items,
    page,
    setPage,
    limit,
    setLimit,
    total: creditCardApplications.total,
    loading,
  }), [creditCardApplications, loading]);

  return (
    <GetCreditCardApplicationsContext.Provider value={value}>
      {children}
    </GetCreditCardApplicationsContext.Provider>
  );
};

export default GetCreditCardApplicationsContext;
