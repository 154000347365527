import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ChevronRightIcon from 'src/icons/ChevronRight';

interface BreadcrumbNavigationProps {
  excludeHeading?: boolean;
  currentPageName: string;
  previousPageName?: string;
  previousPageLink?: string;
}

const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = ({
  excludeHeading,
  currentPageName,
  previousPageName,
  previousPageLink,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item>
        {!excludeHeading && (
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {currentPageName}
          </Typography>
        )}

        <Breadcrumbs
          sx={{ marginTop: '0.5rem' }}
          separator={
            <ChevronRightIcon fontSize="small" />
          }
        >
          <Link
            color="textPrimary"
            variant="subtitle2"
            component={RouterLink}
            to="/"
          >
            {t('Home')}
          </Link>

          {(previousPageLink && previousPageName) && (
            <Link
              color="textPrimary"
              variant="subtitle2"
              component={RouterLink}
              to={previousPageLink || '/'}
            >
              {previousPageName}
            </Link>
          )}

          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {currentPageName}
          </Typography>

        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default BreadcrumbNavigation;
