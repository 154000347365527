import { Country, Currency } from 'src/api/zrm';

import { getLocale } from './getLocale';

export const defaultCurrency = (country: Country) => {
  switch (country) {
    case Country.SE:
      return Currency.SEK;
    case Country.NO:
      return Currency.NOK;
    default:
      return null;
  }
};

export const currencySuffix = (country: Country, currency: Currency) => (currency && new Intl
  .NumberFormat(getLocale(country), { style: 'currency', currency })
  .formatToParts().find(({ type }) => type === 'currency')?.value) || currency;
