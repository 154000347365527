import type { FC } from 'react';
import { useMemo } from 'react';

import {
  Avatar,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { Drafts } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CenteredTooltip from 'src/components/widgets/info/CenteredTooltip';
import useNotifications from 'src/hooks/useNotifications';
import { NotificationExtended } from 'src/types/notifications/notificationExtended';

interface NotificationSingleProps {
  notification: NotificationExtended;
  inToaster?: boolean;
  deleteNotificationFromState: (notificationId: string) => void;
}

const NotificationSingle: FC<NotificationSingleProps> = (props) => {
  const { notification, inToaster, deleteNotificationFromState } = props;
  const { t } = useTranslation();
  const notifications = useNotifications();

  if (!notifications) return null;

  const { markAsRead } = notifications;
  const navigate = useNavigate();

  const markAsReadAndHide = useMemo(() => () => {
    markAsRead([notification.notification_id]);
    deleteNotificationFromState(notification.notification_id);
  }, []);

  const markAsReadAndRedirect = useMemo(() => () => {
    markAsRead([notification.notification_id]);
    deleteNotificationFromState(notification.notification_id);
    navigate(notification.to);
  }, [navigate]);

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          {notification.icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={(
          <Link
            color="textPrimary"
            sx={{ cursor: 'pointer' }}
            variant="subtitle2"
            onClick={markAsReadAndRedirect}
          >
            {notification.title}
          </Link>
            )}
        secondary={notification.description ?? null}
      />
      {!inToaster ? (
        <ListItemSecondaryAction>
          <CenteredTooltip title={t('Mark as read')}>
            <IconButton
              edge="end"
              onClick={() => markAsReadAndHide()}
            >
              <Drafts />
            </IconButton>
          </CenteredTooltip>

        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
};

export default NotificationSingle;
