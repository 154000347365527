import { FC, ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { experimentalStyled } from '@material-ui/core/styles';

import { useDialmakerDesktopUtils } from 'src/contexts/dialmakerDesktop/DialmakerDesktopUtilsContext';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DesktopDashboardNavbar from './DesktopDashboardNavbar';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    }
  ),
);

const WebDashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: '64px',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px',
      },
    }
  ),
);

const DesktopDashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: '28px',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px',
      },
    }
  ),
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const DashboardLayoutWrapper: FC = ({ children }) => {
  const { isDesktopDialmakerApp } = useDialmakerDesktopUtils();

  return (
    <>
      {isDesktopDialmakerApp ? (
        <DesktopDashboardLayoutWrapper>
          {children}
        </DesktopDashboardLayoutWrapper>
      ) : (
        <WebDashboardLayoutWrapper>
          {children}
        </WebDashboardLayoutWrapper>
      )}
    </>
  );
};

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

  const { isDesktopDialmakerApp } = useDialmakerDesktopUtils();

  return (
    <DashboardLayoutRoot>
      {isDesktopDialmakerApp ? (
        <DesktopDashboardNavbar />
      ) : (
        <>
          <DashboardNavbar
            onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
          />
          <DashboardSidebar
            onMobileClose={(): void => setIsSidebarMobileOpen(false)}
            openMobile={isSidebarMobileOpen}
          />
        </>
      )}
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        showUserInfo
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
