/* eslint-disable no-case-declarations */
import { createContext, FC, useMemo } from 'react';

import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { NotificationEvent, ModelsNotificationsNotificationTypesNotificationType, Product } from 'src/api/zrm';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import useWSHelper from 'src/hooks/useWSHelper';
import BellIcon from 'src/icons/Bell';
import ChatAltIcon from 'src/icons/ChatAlt';
import MailIcon from 'src/icons/Mail';
import Notification from 'src/types/notifications/Notification';
import { NotificationExtended } from 'src/types/notifications/notificationExtended';
import logger from 'src/utils/logger';
import { parseApplicationId } from 'src/utils/parseApplicationId';

import { WSHelperProvider } from './WSHelperContext';

type NotificationAction = (notificationIds: string[]) => Promise<void>;

export interface NotificationsContextValue {
  getNotifications: (requestId?: string, page?: number, limit?: number, status?: NotificationEvent[], type?: ModelsNotificationsNotificationTypesNotificationType) => Promise<NotificationExtended[]>;
  markAsDeleted: NotificationAction;
  markAsRead: NotificationAction;
  markAsReceived: NotificationAction;
  notificationTypeMapping: { label: string, value: ModelsNotificationsNotificationTypesNotificationType }[];
  websocketUrl: () => Promise<string>;
  mapNotifications: (notifications: Notification[]) => NotificationExtended[];
  isAuthenticated: boolean,
  renewNotificationToken: () => Promise<void | string>;
}

const NotificationsContextDefaultValue: NotificationsContextValue = {
  getNotifications: () => Promise.resolve([]),
  markAsDeleted: () => Promise.resolve(),
  markAsRead: () => Promise.resolve(),
  markAsReceived: () => Promise.resolve(),
  notificationTypeMapping: [],
  websocketUrl: () => Promise.resolve(''),
  mapNotifications: () => [],
  isAuthenticated: false,
  renewNotificationToken: () => Promise.resolve(),
};

const NotificationsContext = createContext<NotificationsContextValue>(NotificationsContextDefaultValue);

const NotificationsProviderComp = ({ children }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { websocketUrl, renewWSToken, isAuthenticated: isAuthenticatedWS } = useWSHelper();

  const markNotifications = useMemo(() => (notificationIds: string[], action: NotificationEvent): Promise<void> => {
    const requestId = uuid();

    return api.notification.acknowledgeNotificationNotificationActionPost({
      notification_ids: notificationIds,
      action,
    }, { cancelToken: requestId, headers: { 'X-Request-ID': requestId } })
      .then(() => {}) // Needed because function returns Promise
      .catch((err) => {
        logger.error(err, { source: 'NotificationsContext', description: 'Acknowledge notification', requestId, action });
        const message = err?.error?.detail ?? `${t('Something went wrong with the notifications, consult tech about')} ${requestId}`;
        toast.error(message);
      });
  }, [api, toast, t]);

  const markAsDeleted = useMemo<NotificationAction>(() => (notificationIds: string[]): Promise<void> => markNotifications(notificationIds, NotificationEvent.DELETED), [markNotifications]);

  const markAsRead = useMemo<NotificationAction>(() => (notificationIds: string[]): Promise<void> => markNotifications(notificationIds, NotificationEvent.READ), [markNotifications]);

  const markAsReceived = useMemo<NotificationAction>(() => (notificationIds: string[]): Promise<void> => markNotifications(notificationIds, NotificationEvent.RECEIVED), [markNotifications]);

  const mapNotifications = useMemo(() => (notifications: Notification[]): NotificationExtended[] => notifications.map((n) => {
    // @ts-ignore
    const product: Product = n.data?.application_id ? parseApplicationId(n.data.application_id).product : null;
    let url: string = null;
    switch (n.type) {
      case ModelsNotificationsNotificationTypesNotificationType.APPLICATION_ASSIGNED:
        return {
          ...n,
          title: t('Assigned to application', { appId: n.data.application_id }),
          to: `/mortgage/_application/${n.data.application_id}`,
          icon: <BellIcon fontSize="small" />,
        };
      case ModelsNotificationsNotificationTypesNotificationType.BANK_MESSAGE_RECEIVED:
        if (product === Product.Mortgage) url = `/mortgage/_application/${n.data.application_id}`;
        else if (product === Product.CreditCard) url = `/credit-card-application/_/${n.data.application_id}`;
        else url = '/';

        return {
          ...n,
          title: `${t('Message from bank on')} ${n.data.application_id}`,
          to: url,
          icon: <ChatAltIcon fontSize="small" />,
        };

      case ModelsNotificationsNotificationTypesNotificationType.GENERAL_MESSAGE_RECEIVED:
        return {
          ...n,
          title: `${n.data.message}`,
          to: '',
          icon: <ChatAltIcon fontSize="small" />,
        };
      case ModelsNotificationsNotificationTypesNotificationType.INTERNAL_COMMENT_RECEIVED:
        if (product === Product.Mortgage) url = `/mortgage/_application/${n.data.application_id}`;
        else if (product === Product.CreditCard) url = `/credit-card-application/_/${n.data.application_id}`;
        else url = '/';

        return {
          ...n,
          title: t('Message from coworker on {{appId}}', { appId: n.data.application_id }),
          to: url,
          icon: <ChatAltIcon fontSize="small" />,
        };
      case ModelsNotificationsNotificationTypesNotificationType.OFFER_RECEIVED:
        if (product === Product.Mortgage) url = `/mortgage/_application/${n.data.application_id}`;
        else if (product === Product.CreditCard) url = `/credit-card-application/_/${n.data.application_id}`;
        else url = '/';

        return {
          ...n,
          title: t('Offer received on {{appId}}', { appId: n.data.application_id }),
          to: url,
          icon: <MailIcon fontSize="small" />,
        };
      case ModelsNotificationsNotificationTypesNotificationType.SMS_RECEIVED:
        if (product === Product.Mortgage) url = `/mortgage/_application/${n.data.application_id}`;
        else if (product === Product.CreditCard) url = `/credit-card-application/_/${n.data.application_id}`;
        else url = '/';

        return {
          ...n,
          title: t('Message from customer on {{appId}}', { appId: n.data.application_id }),
          to: url,
          icon: <MailIcon fontSize="small" />,
        };
      case ModelsNotificationsNotificationTypesNotificationType.LEAD_ASSIGNED:
        return {
          ...n,
          title: t('Assigned to Lead {{leadId}}', { leadId: n.data.lead_id }),
          to: `/mortgage/_lead/${n.data.lead_id}`,
          icon: <BellIcon fontSize="small" />,
        };
      case ModelsNotificationsNotificationTypesNotificationType.LEAD_COMMENT_RECEIVED:
        return {
          ...n,
          title: t('Message from coworker on {{leadId}}', { leadId: n.data.lead_id }),
          to: `/mortgage/_lead/${n.data.lead_id}`,
          icon: <BellIcon fontSize="small" />,
        };
      case ModelsNotificationsNotificationTypesNotificationType.APPLICATION_DISBURSED:
        if (product === Product.Mortgage) url = `/mortgage/_application/${n.data.application_id}`;
        else if (product === Product.CreditCard) url = `/credit-card-application/_/${n.data.application_id}`;
        else url = '/';

        return {
          ...n,
          title: t('Application {{appId}} disbursed', { appId: n.data.application_id }),
          to: url,
          icon: <BellIcon fontSize="small" />,
        };
      default:
        return {
          ...n,
          title: `${t('Message:')} ${n.data}`,
          to: '',
          icon: <BellIcon fontSize="small" />,
        };
    }
  }), []);

  const getNotifications = useMemo(() => (
    requestId?: string, page?: number, limit?: number, status?: NotificationEvent[], type?: ModelsNotificationsNotificationTypesNotificationType,
  ): Promise<NotificationExtended[]> => {
    if (!requestId) requestId = uuid();

    return api.notification.userNotificationsNotificationGet({ page, limit, status, notification_type: type }, { cancelToken: requestId, headers: { 'X-Request-ID': requestId } })
      .then((response) => {
        const allNotifications = response.data;
        const createdNotificationIds = allNotifications?.filter((notification) => notification.status === NotificationEvent.CREATED)
          .map((notification) => notification.notification_id);
        allNotifications?.forEach((notification) => {
          if (notification.status === NotificationEvent.CREATED) notification.status = NotificationEvent.RECEIVED;
        });

        if (createdNotificationIds?.length) markAsReceived(createdNotificationIds);

        return Promise.resolve<NotificationExtended[]>(mapNotifications(allNotifications));
      });
  }, [api, t]);

  const notificationTypeMapping = useMemo(() => [
    {
      label: t('--- Clear ---'),
      value: null,
    },
    {
      label: t('Application assigned'),
      value: ModelsNotificationsNotificationTypesNotificationType.APPLICATION_ASSIGNED,
    },
    {
      label: t('Bank message received'),
      value: ModelsNotificationsNotificationTypesNotificationType.BANK_MESSAGE_RECEIVED,
    },
    {
      label: t('General message received'),
      value: ModelsNotificationsNotificationTypesNotificationType.GENERAL_MESSAGE_RECEIVED,
    },
    {
      label: t('Internal comment received'),
      value: ModelsNotificationsNotificationTypesNotificationType.INTERNAL_COMMENT_RECEIVED,
    },
    {
      label: t('Offer received'),
      value: ModelsNotificationsNotificationTypesNotificationType.OFFER_RECEIVED,
    },
    {
      label: t('SMS received'),
      value: ModelsNotificationsNotificationTypesNotificationType.SMS_RECEIVED,
    },
  ], [t]);

  return (
    <NotificationsContext.Provider value={{
      notificationTypeMapping,
      getNotifications,
      mapNotifications,
      markAsDeleted,
      markAsRead,
      markAsReceived,
      websocketUrl: () => websocketUrl('/notification/'),
      isAuthenticated: isAuthenticatedWS,
      renewNotificationToken: renewWSToken,
    }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
export const NotificationsProvider: FC<any> = (props) => {
  const { platform } = useAuth();
  const [isBank, isCustomer] = useMemo(() => [platform === AuthenticationPlatform.JWT, platform === AuthenticationPlatform.BankIdNo], [platform]);

  // eslint-disable-next-line react/destructuring-assignment
  if (isBank || isCustomer) return <>{props.children}</>;

  return (
    <WSHelperProvider>
      <NotificationsProviderComp {...props} />
    </WSHelperProvider>
  );
};
