import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { MobileView, BrowserView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import Card from 'src/components/Card';
import { useIframeUtils } from 'src/contexts/IframeUtilsContext';
import { useCustomerLogin } from 'src/customerPages/context/login/CustomerLoginContext';

import LoginBoxLogoSweden from './common/LoginBoxLogoSweden';
import LoginBoxTermsOfUse from './common/LoginBoxTermsOfUse';

interface LoginBoxSeProps {
  redirectTo?: string;
}

const LoginBoxSe: FC<LoginBoxSeProps> = (props) => {
  const { redirectTo } = props;

  const { t } = useTranslation();
  const { heightBaseElementRef } = useIframeUtils();
  const { loginSe } = useCustomerLogin();

  return (
    <Card sx={{ p: 1 }}>
      <Grid
        container
        spacing={2}
        ref={heightBaseElementRef}
        sx={{ textAlign: 'center' }}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="h6">{t('Login with BankID to see your offers')}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <LoginBoxLogoSweden />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <MobileView>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 1 }}>
              <Button
                variant="contained"
                color="info"
                sx={{ width: '100%' }}
                size="large"
                onClick={() => loginSe(true)}
              >
                {t('Login with BankID app on this device')}
              </Button>
              <Button
                component={Link}
                to={{ pathname: '/qr-login', search: `?redirectTo=${redirectTo}` }}
                variant="outlined"
                color="info"
                sx={{ width: '100%' }}
                size="large"
              >
                {t('Login with BankID app on another device')}
              </Button>
            </Box>
          </MobileView>
          <BrowserView>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 1 }}>
              <Button
                component={Link}
                to={{ pathname: '/qr-login', search: `?redirectTo=${redirectTo}` }}
                variant="contained"
                color="info"
                sx={{ width: '400px' }}
                size="large"
              >
                {t('Login with BankID app on another device')}
              </Button>
              <Button
                variant="outlined"
                color="info"
                sx={{ width: '400px' }}
                size="large"
                onClick={() => loginSe(true)}
              >
                {t('Login with BankID app on this device')}
              </Button>
            </Box>
          </BrowserView>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 1 }}
        >
          <LoginBoxTermsOfUse />
        </Grid>
      </Grid>
    </Card>
  );
};

export default LoginBoxSe;
