import { FC, useMemo } from 'react';

import {
  Alert,
  Box,
  Button,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import useAuth from 'src/hooks/useAuth';
import useMounted from 'src/hooks/useMounted';

enum Stage {
  Login,
  OTP,
}

const LoginForm: FC = () => {
  const mounted = useMounted();
  const { login } = useAuth();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup
        .object({
          email: Yup
            .string()
            .email(t('Must be a valid email'))
            .max(255)
            .required(t('Email is required')),
          password: Yup
            .string()
            .max(255)
            .required(t('Password is required')),
        })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }): Promise<void> => {
        try {
          await login(values.email, values.password);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: t(err.data?.detail ?? 'Wrong username or password') });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && t(errors.email)}
            label={t('Email Address')}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && t(errors.password)}
            label={t('Password')}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t('Log In')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const OTPForm: FC = () => {
  const mounted = useMounted();
  const { verify2FACode } = useAuth();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        otp: '',
        submit: null,
      }}
      validationSchema={Yup
        .object({
          otp: Yup
            .string()
            .min(6)
            .max(8)
            .required(t('OTP is required')),
        })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }): Promise<void> => {
        try {
          await verify2FACode(values.otp.toString()); // values.otp is a number, backend expects a string

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);

          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            autoFocus
            error={Boolean(touched.otp && errors.otp)}
            fullWidth
            helperText={touched.otp && t(errors.otp)}
            label={t('OTP')}
            margin="normal"
            name="otp"
            onBlur={handleBlur}
            onChange={handleChange}
            type="number"
            value={values.otp}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t('Verify')}
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Alert severity="info">
              <Box>
                {t('Check your email for the OTP')}
              </Box>
            </Alert>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const LoginJWT: FC = () => {
  const { waitingForOTP } = useAuth();
  const stage = useMemo(() => waitingForOTP ? Stage.OTP : Stage.Login, [waitingForOTP]);

  return (
    <>
      {stage === Stage.Login && <LoginForm />}
      {stage === Stage.OTP && <OTPForm />}
      {/* <Box sx={{ mt: 2 }}>
        <Alert severity="info">
          <div>
            Great info box!
            {' '}
            <b>with some bold text</b>
          </div>
        </Alert>
      </Box> */}
    </>
  );
};

export default LoginJWT;
