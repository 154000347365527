import { Country, Product } from 'src/api/zrm';

export const parseApplicationId = (applicationId: string): {
  appId: number;
  country: Country;
  product: Product;
} => {
  if (!applicationId) return {
    appId: null,
    country: null,
    product: null,
  };

  // Picks all the numbers at the end of the string
  const appId = parseInt(applicationId.match(/\d*$/g)[0], 10);
  // Picks the first two letters before the first number
  const country = applicationId.match(/([A-Z]){2}(?=\d)/g)[0] as Country;
  // Picks the two letters before the last two letters
  const prod = applicationId.match(/([A-Z]){2}(?=([A-Z]){2})/g)[0];
  let product: Product = null;
  switch (prod) {
    case 'CC':
      product = Product.CreditCard;
      break;
    case 'MO':
      product = Product.Mortgage;
      break;
    case 'UL':
      product = Product.Blanco;
      break;
    default:
      return {
        appId: null,
        country: null,
        product: null,
      };
  }

  return {
    appId,
    country,
    product,
  };
};
