import { FC, useMemo, useState } from 'react';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Substatus } from 'src/api/zrm';
import upperCaseFirstLetter from 'src/utils/upperCaseFirstLetter';

export interface MortgageListFiltersType {
  statuses: any[]; // BankStatus or Status from zrmApi
  substatuses?: Substatus[];
  unassignedOnly?: boolean;
}

export const initialValues: MortgageListFiltersType = {
  statuses: [],
  substatuses: [],
  unassignedOnly: false,
};

interface MortgageListFiltersProps {
  handleFiltersChange: Function;
  statusOptions: string[];
  hasSubstatus?: boolean;
  showUnassignedFilter?: boolean;
}

const MortgageListFilters: FC<MortgageListFiltersProps> = (props) => {
  const { handleFiltersChange, statusOptions, hasSubstatus, showUnassignedFilter } = props;
  const { t } = useTranslation();

  const [statuses, setStatuses] = useState([]);
  const [substatuses, setSubstatuses] = useState([]);
  const [unassignedOnly, setUnassignedOnly] = useState(false);

  const parsedStatusOptions = useMemo(() => statusOptions.map((status) => ({ label: upperCaseFirstLetter(status), value: status })), [statusOptions]);
  const parsedSubstatusOptions = useMemo(() => {
    if (!hasSubstatus) return [];

    return Object.keys(Substatus).map((status) => ({ label: upperCaseFirstLetter(status), value: status }));
  }, [hasSubstatus]);

  const handleStatusesChange = useMemo(() => (event: any) => {
    setStatuses(event.target.value);
    handleFiltersChange({ statuses: event.target.value, substatuses, unassignedOnly });
  }, [substatuses, unassignedOnly]);

  const handleSubstatusesChange = useMemo(() => (event: any) => {
    setSubstatuses(event.target.value);
    handleFiltersChange({ statuses, substatuses: event.target.value, unassignedOnly });
  }, [statuses, unassignedOnly]);

  const handleUnassignedOnlyChange = useMemo(() => (event: any) => {
    setUnassignedOnly((p) => !p);
    handleFiltersChange({ statuses, substatuses, unassignedOnly: event.target.checked });
  }, [statuses, substatuses]);

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={12}
        md={3}
      >
        <TextField
          name="status"
          select
          fullWidth
          onChange={handleStatusesChange}
          value={statuses}
          label={t('Visible statuses')}
          placeholder={t('Select from list')}
          size="small"
          SelectProps={{
            multiple: true,
          }}
        >
          {parsedStatusOptions?.map((status) => (
            <MenuItem
              value={status.value}
              key={status.label}
            >
              {t(status.label)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {hasSubstatus && (
        <Grid
          item
          xs={12}
          md={3}
        >
          <TextField
            name="substatus"
            select
            fullWidth
            onChange={handleSubstatusesChange}
            value={substatuses}
            label={t('Visible substatuses')}
            placeholder={t('Select from list')}
            size="small"
            SelectProps={{
              multiple: true,
            }}
          >
            {parsedSubstatusOptions?.map((substatus) => (
              <MenuItem
                value={substatus.value}
                key={substatus.label}
              >
                {t(substatus.label)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      {showUnassignedFilter && (
        <Grid
          item
          xs={12}
          md={3}
          sx={{ mx: 1 }}
        >
          <FormControlLabel
            checked={unassignedOnly}
            color="primary"
            control={<Checkbox />}
            onChange={handleUnassignedOnlyChange}
            label={t('Show unassigned only')}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MortgageListFilters;
