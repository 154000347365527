import React from 'react';

import { isNorway } from 'src/utils/country';

const Norway: React.FC<{}> = (props) => {
  if (!isNorway()) return <></>;

  const { children } = props;

  return (
    <>
      { children }
    </>
  );
};

export default Norway;
