import { THEMES } from 'src/constants';

const extension = {
  ALL: {
    shape: {
      borderRadius: 4,
    },
    palette: {
      background: {
        // paper: '#ff2b36',
      },
    },
  },
  [THEMES.LIGHT]: {
  },
  [THEMES.DARK]: {

    shape: {
      borderRadius: 4,
    },
    palette: {
      background: {
        // paper: '#ff36ff',
      },
    },

  },
};

export default extension;
