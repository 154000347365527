import { Country, Currency } from 'src/api/zrm';

import { getLocale } from './getLocale';

export const formatCurrency = (amount: number, country: Country, currency: Currency, decimals?: number) => new Intl
  .NumberFormat(getLocale([Country.SE, Country.NO].includes(country) ? Country.SE : country), {
    style: 'currency',
    currency: [Currency.SEK, Currency.NOK].includes(currency) ? Currency.SEK : currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
  .format(amount);

export const formatNumber = (amount: number, country: Country, decimals?: number) => new Intl
  .NumberFormat(getLocale(country), {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
  .format(amount);

export const formatPercent = (amount: number | string, country: Country, decimals?: number) => {
  const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

  return new Intl
    .NumberFormat(getLocale(country), {
      style: 'percent',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .format(parsedAmount);
};

export const decimalSeparator = (country: Country) => new Intl
  .NumberFormat(getLocale(country), {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  })
  .formatToParts(1.1).find(({ type }) => type === 'decimal').value;
