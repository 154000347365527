import { FC } from 'react';

import { Box, Container, useTheme } from '@material-ui/core';

import Logo from 'src/components/Logo';

const LoginHeader: FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{
      width: '100%',
      pt: 1,
      backgroundColor: theme.palette.mode === 'light' ? 'white' : theme.palette.background.paper,
    }}
    >
      <Container
        maxWidth="lg"
        sx={{ color: 'white' }}
      >
        <Logo
          sx={{
            height: 40,
            width: 100,
          }}
        />
      </Container>
    </Box>
  );
};

export default LoginHeader;
