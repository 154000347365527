import './i18n';
import './utils/overwriteStorageIfNeeded';

import { FC, lazy, memo, Suspense, useEffect, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';

import Conversations from 'src/components/messaging/Conversations';
import SplashScreen from 'src/components/SplashScreen';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import { RoutesProvider } from 'src/contexts/Routes';
import useRoutesHook from 'src/hooks/useRoutes';
import useScrollReset from 'src/hooks/useScrollReset';

import { Country } from './api/zrm';
import LoadingScreen from './components/LoadingScreen';
import { CustomerZMSContextProvider } from './contexts/CustomerZMSContext';
import { ThemeSettingsProvider } from './contexts/ThemeSettingsContext';
import useAuth from './hooks/useAuth';
import { externalAuthPlatforms } from './utils/auth/authPlatforms';

const Routes: FC = memo(() => {
  const { routes } = useRoutesHook();
  const content = useRoutes(routes);

  return content;
});

const Loadable = (Component: FC<any>) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const NotificationsProvider = Loadable(lazy(() => import('./contexts/NotificationsProvider')));
const MessagingProvider = Loadable(lazy(() => import('./contexts/MessagingProvider')));

const App: FC = () => {
  const { completeState, platform } = useAuth();
  const authComplete = useMemo(() => completeState === 'complete' || [AuthenticationPlatform.JWT, AuthenticationPlatform.BankIdNo, AuthenticationPlatform.BankIdSe].includes(platform), [completeState, platform]);

  const isCustomer = externalAuthPlatforms.includes(platform);

  const country = platform === AuthenticationPlatform.BankIdNo ? Country.NO : Country.SE;

  useScrollReset();

  useEffect(() => {
    if (!isCustomer) {
      const hotjar = window.document.getElementById('hotjar');

      if (hotjar) hotjar.remove();
    } else if (window && document) {
      const script = document.createElement('script');
      script.async = true;
      script.id = 'hotjar';

      if (country === Country.NO) script.text = `/* <!-- Hotjar Tracking Code for Zensum NO --> */
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2455361,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
      else if (country === Country.SE) script.text = `<!-- Hotjar Tracking Code for Zensum SE -->
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2455350,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;

      const body = document.getElementsByTagName('body')[0];
      body.appendChild(script);
    }
  }, [country, isCustomer]);

  return (
    <ThemeSettingsProvider>
      <CssBaseline />
      {!authComplete && <SplashScreen />}
      {authComplete && <Toaster position="bottom-left" />}
      {authComplete && isCustomer && (
        <CustomerZMSContextProvider>
          <RoutesProvider>
            <Routes />
          </RoutesProvider>
        </CustomerZMSContextProvider>
      )}
      {authComplete && !isCustomer && (
        <MessagingProvider>
          <NotificationsProvider>
            <Toaster position="bottom-left" />
            <RoutesProvider>
              <Routes />
            </RoutesProvider>
          </NotificationsProvider>
          <Conversations />
        </MessagingProvider>
      )}
    </ThemeSettingsProvider>
  );
};

export default App;
