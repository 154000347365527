import { FC, ReactNode } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { HttpErrorResponse, HTTPValidationError } from 'src/api/zrm';
import { ServerErrorResponse } from 'src/types/ServerErrorResponse';

interface ErrorBoxProps {
  errorMessage?: string | ReactNode;
  serverErrorResponse?: ServerErrorResponse | HttpErrorResponse<HTTPValidationError>;
  severity?: 'error' | 'warning';
}

// TypeGuard to check response type
// eslint-disable-next-line arrow-body-style
const isHttpErrorResponse = (response: ServerErrorResponse | HttpErrorResponse<HTTPValidationError>): response is HttpErrorResponse<HTTPValidationError> => {
  return (response as HttpErrorResponse<HTTPValidationError>).requestId !== undefined;
};

const ErrorBoxComponent: FC<ErrorBoxProps> = (props) => {
  const { errorMessage, serverErrorResponse, severity = 'error' } = props;
  const { t } = useTranslation();

  let serverInfoMessage: string;
  let requestIdMessage: string;

  // eslint-disable-next-line curly
  if (serverErrorResponse) {
    if (isHttpErrorResponse(serverErrorResponse)) requestIdMessage = `RequestId:  ${serverErrorResponse.requestId}`;
    else {
      if (serverErrorResponse?.serverMessage) serverInfoMessage = `Server response: ${serverErrorResponse.serverMessage}`;
      if (serverErrorResponse?.serverRequestId) requestIdMessage = `RequestId:  ${serverErrorResponse.serverRequestId}`;
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Alert severity={severity}>
        <AlertTitle>{severity === 'error' ? t('Error') : t('Warning')}</AlertTitle>
        {errorMessage && <Box marginBottom={2}>{errorMessage}</Box>}
        {serverInfoMessage && <Box marginBottom={2}>{serverInfoMessage}</Box>}
        <Box>{requestIdMessage}</Box>
      </Alert>
    </Box>
  );
};

export default ErrorBoxComponent;
