import { FC, useMemo } from 'react';

import { Link, Typography, useTheme } from '@material-ui/core';
import { Trans } from 'react-i18next';

import { Country } from 'src/api/zrm';
import useSettings from 'src/hooks/useSettings';

const LoginBoxTermsOfUse: FC = () => {
  const theme = useTheme();
  const { country } = useSettings();

  const termsOfUseLink = useMemo(() => country === Country.NO ? 'https://www.zensum.no/brukervilkar' : 'https://www.zensum.se/integritetsguide/anvandaravtal', [country]);
  const privacyPolicyLink = useMemo(() => country === Country.NO ? 'https://www.zensum.no/personvern' : 'https://www.zensum.se/integritetsguide/integritetspolicy', [country]);

  return (
    <Typography
      color={theme.palette.grey[600]}
      variant="body2"
    >
      <Trans>
        By logging in, you accept and confirm Zensum
        <Link
          href={termsOfUseLink}
          target="_blank"
        >
          terms of use
        </Link>
        and
        <Link
          href={privacyPolicyLink}
          target="_blank"
        >
          privacy policy
        </Link>
        .
      </Trans>
    </Typography>
  );
};

export default LoginBoxTermsOfUse;
