import type { FC } from 'react';
import { createContext, lazy, Suspense, useEffect, useMemo } from 'react';
import { useNavigate, matchPath } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { AutoGraph, Check, CreditCard, Dashboard, HealthAndSafety, Home, Link, ListAlt, ManageSearch, PriorityHigh, Recommend, Search, SummarizeOutlined, Web } from '@material-ui/icons';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { InsuranceGroup } from 'src/api/zaker';
import { AllOptionalZoordinates, Product, RoleType, ZCountry, ZDepartment, Zoordinates, ZProduct } from 'src/api/zrm';
import AuthGuard, { DesiredAuthPage } from 'src/components/AuthGuard';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import CustomerPagesDashboard, { UnauthenticatedCustomerPagesDashboard } from 'src/customerPages/layout/CustomerPagesDashboard';
import useAuth from 'src/hooks/useAuth';
import useQuery from 'src/hooks/useQuery';
import useSettings from 'src/hooks/useSettings';
import useTheme from 'src/hooks/useTheme';
import useUser from 'src/hooks/useUser';
import Calendar from 'src/icons/Calendar';
import MailIcon from 'src/icons/Mail';
import Money from 'src/icons/Money';
import PlusIcon from 'src/icons/Plus';
import UsersIcon from 'src/icons/Users';
import { ZensUser } from 'src/types/auth';
import { externalAuthPlatforms } from 'src/utils/auth/authPlatforms';
import { isCustomerPageContext } from 'src/utils/isCustomerPageContext';
import { countryToZCountry, parseZoordinates, productToZProduct } from 'src/utils/zoordinates';

import { useDialmakerDesktopUtils } from './dialmakerDesktop/DialmakerDesktopUtilsContext';

const Loadable = (Component: FC<any>) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Logout = Loadable(lazy(() => import('../pages/authentication/Logout')));
const GuestGuard = Loadable(lazy(() => import('../components/GuestGuard')));

// App Pages
// const Overview = Loadable(lazy(() => import('../pages/Overview')));

// Mortgage
const MortgageDetails = Loadable(lazy(() => import('../pages/mortgage/AMortgage')));
const MyMortgageList = Loadable(lazy(() => import('../pages/mortgage/MyMortgageList')));
const AllMortgageList = Loadable(lazy(() => import('../pages/mortgage/AllMortgageList')));
const LeadList = Loadable(lazy(() => import('../pages/leads/LeadList')));
const MineLeadList = Loadable(lazy(() => import('../pages/leads/MineLeadList')));
const LeadView = Loadable(lazy(() => import('../pages/leads/Lead')));
const NewLeadView = Loadable(lazy(() => import('../pages/leads/NewLead')));
const FormPrefList = Loadable(lazy(() => import('../pages/leads/formPrefs/FormPrefList')));
const FormPrefView = Loadable(lazy(() => import('../pages/leads/formPrefs/FormPref')));
const NewFormPrefView = Loadable(lazy(() => import('../pages/leads/formPrefs/NewFormPref')));
const NewMortgage = Loadable(lazy(() => import('../pages/mortgage/NewMortgage')));
const MortgagesMeetingsCalendar = Loadable(lazy(() => import('../pages/mortgage/MortgagesMeetingsCalendar')));

// Mortgage Bank
const BankMortgageList = Loadable(lazy(() => import('../pages/mortgage_bank/MortgageList')));
const BankMortgageView = Loadable(lazy(() => import('../pages/mortgage_bank/BankMortgage')));

// Zaker
const InsuranceList = Loadable(lazy(() => import('../pages/insurance/InsuranceList')));
const NewInsurance = Loadable(lazy(() => import('../pages/insurance/CreateInsurance')));
const InsuranceView = Loadable(lazy(() => import('../pages/insurance/Insurance')));
const InsuranceAdminSalesReport = Loadable(lazy(() => import('../pages/insurance/AdminSalesReportView')));

const ZakerWinbackable = Loadable(lazy(() => import('../pages/insurancesWinbackable/WinbackList')));

// Quick Create
const QuickCreate = Loadable(lazy(() => import('../pages/quickCreate/QuickCreate')));

//  Ponytail
const MyStats = Loadable(lazy(() => import('../pages/ponytail/MyStats')));
const SummarizedView = Loadable(lazy(() => import('../pages/ponytail/SummarizedView')));

// Partner Users
const PartnerUser = Loadable(lazy(() => import('../pages/internalPartner/EditUser')));
const NewPartnerUser = Loadable(lazy(() => import('../pages/internalPartner/NewUser')));
const AllPartnerUsersList = Loadable(lazy(() => import('../pages/internalPartner/AllUsers')));
const SetPartnerPassword = Loadable(lazy(() => import('../pages/internalPartner/SetPassword')));

// Internal Users
const InternalUser = Loadable(lazy(() => import('../pages/internalUser/EditUser')));
const NewInternalUser = Loadable(lazy(() => import('../pages/internalUser/NewUser')));
const AllInternalUsersList = Loadable(lazy(() => import('../pages/internalUser/AllUsers')));
const TelavoxMismatch = Loadable(lazy(() => import('../pages/internalUser/TelavoxMismatch')));

// Templates
const UploadAttachments = Loadable(lazy(() => import('../pages/emailTemplates/UploadAttachments')));
const EmailTemplatesView = Loadable(lazy(() => import('../pages/emailTemplates/EmailTemplatesView')));
const EditTemplate = Loadable(lazy(() => import('../pages/emailTemplates/EditTemplate')));
const NewTemplate = Loadable(lazy(() => import('../pages/emailTemplates/NewTemplate')));
const SmsTemplatesView = Loadable(lazy(() => import('../pages/smsTemplates/SmsTemplatesView')));
const NewSmsTemplate = Loadable(lazy(() => import('../pages/smsTemplates/NewSmsTemplate')));
const EditSmsTemplate = Loadable(lazy(() => import('../pages/smsTemplates/EditSmsTemplate')));

// Customers
const CustomerList = Loadable(lazy(() => import('../pages/customers/CustomerList')));
const CustomerView = Loadable(lazy(() => import('../pages/customers/CustomerView')));

// Error pages

const NotFound = Loadable(lazy(() => import('../pages/NotFound')));
const ServerError = Loadable(lazy(() => import('../pages/ServerError')));

// Notifications
const NotificationPage = Loadable(lazy(() => import('../pages/notifications/NotificationPage')));

// Searcher
const Searcher = Loadable(lazy(() => import('../pages/searcher')));

// Searcher
const NewSmartFilter = Loadable(lazy(() => import('../pages/smartFilters/NewSmartFilter')));
const NewOrvarscore = Loadable(lazy(() => import('../pages/orvarscore/NewOrvarscore')));
const SmartFilterView = Loadable(lazy(() => import('../pages/smartFilters/SmartFilterView')));
const SmartFilterList = Loadable(lazy(() => import('../pages/smartFilters/SmartFilterList')));

// Affiliates
const CreateAffiliate = Loadable(lazy(() => import('../pages/affiliates/CreateAffiliate')));
const AffiliatesList = Loadable(lazy(() => import('../pages/affiliates/AffiliatesList')));
const EditAffiliate = Loadable(lazy(() => import('../pages/affiliates/EditAffiliate')));
const AffiliateNetworks = Loadable(lazy(() => import('../pages/affiliates/AffiliateNetworks')));

// Submissions
const SuccessSubmissionList = Loadable(lazy(() => import('../pages/submissions/SuccessSumbissionList')));
const FailedSubmissionList = Loadable(lazy(() => import('../pages/submissions/FailedSubmissionList')));
const SubmissionDetailsPage = Loadable(lazy(() => import('../pages/submissions/SubmissionDetailsPage')));

// KDLT
const PepCheckEventView = Loadable(lazy(() => import('../pages/kdlt/pepCheckEvent/PepCheckEventView')));

// RQ JOBS
const RqStatusList = Loadable(lazy(() => import('../pages/rqJobStatus/RqStatusList')));
const RqDetailed = Loadable(lazy(() => import('../pages/rqJobStatus/RqDetailed')));

// TODO: Remove after new dialmaker deploy
// Dial Maker Campaigns
const TMCampaign = Loadable(lazy(() => import('../pages/dial-maker/TMCampaign')));
const NewTMCampaign = Loadable(lazy(() => import('../pages/dial-maker/NewTMCampaign')));
const TMCampaigns = Loadable(lazy(() => import('../pages/dial-maker/TMCampaigns')));

// DialMaker New
const DialmakerCampaign = Loadable(lazy(() => import('../pages/dialmaker/Campaign')));
const DialmakerNewCampaign = Loadable(lazy(() => import('../pages/dialmaker/NewCampaign')));
const DialmakerCampaigns = Loadable(lazy(() => import('../pages/dialmaker/Campaigns')));
const DialmakerAdminLeadsSearch = Loadable(lazy(() => import('../pages/dialmaker/adminLeadsSearch/AdminLeadsSearch')));
const DialmakerCampaignsStats = Loadable(lazy(() => import('../pages/dialmaker/stats/perCampaign/CampaignsStats')));
const DialmakerBlocksPage = Loadable(lazy(() => import('../pages/dialmaker/BlocksPage')));
const DialmakerUserCampaignStats = Loadable(lazy(() => import('../pages/dialmaker/stats/perUser/UserCampaignsStats')));

// Shortened URLs
const ShortenedUrlsList = Loadable(lazy(() => import('../pages/shortenedUrls/ShortenedUrlsListPage')));
const NewShortenedUrl = Loadable(lazy(() => import('../pages/shortenedUrls/NewShortenedUrlPage')));
const ShortenedUrlDetailsPage = Loadable(lazy(() => import('../pages/shortenedUrls/ShortenedUrlDetailsPage')));

// Customer Pages
const MainPage = Loadable(lazy(() => import('src/customerPages/pages/mainPage/MainPage')));

const CustomerLoanInsurancePage = Loadable(lazy(() => import('src/customerPages/pages/insurance/LoanInsurancePage')));
const CustomerLoanInsuranceTermsPage = Loadable(lazy(() => import('src/customerPages/pages/insurance/LoanInsuranceTermsPage')));
const CustomerLoanInsuranceSuccessPage = Loadable(lazy(() => import('src/customerPages/pages/insurance/LoanInsuranceSuccessPage')));
const CustomerUnauthorizedLoanInsurancePage = Loadable(lazy(() => import('src/customerPages/pages/insurance/UnauthorizedLoanInsurancePage')));

const CustomerCreditCardPage = Loadable(lazy(() => import('src/customerPages/pages/creditCard/CreditCardPage')));
const CustomerGRPage = Loadable(lazy(() => import('src/customerPages/pages/gjeldsregisteret/GRPage')));
const LoginRedirectPage = Loadable(lazy(() => import('src/customerPages/pages/auth/LoginRedirectPage')));
const CustomerLogout = Loadable(lazy(() => import('src/customerPages/pages/auth/CustomerLogout')));

const CustomerLoginPageSeQrCode = Loadable(lazy(() => import('src/customerPages/pages/auth/CustomerLoginPageSeQrCode')));
const CustomerBlancoOffersPage = Loadable(lazy(() => import('src/customerPages/pages/blanco/BlancoOffersPage')));
const CustomerBlancoOfferDetailsPage = Loadable(lazy(() => import('src/customerPages/pages/blanco/BlancoOfferDetailsPage')));
const CustomerBlancoOfferAcceptPage = Loadable(lazy(() => import('src/customerPages/pages/blanco/BlancoAcceptOfferPage')));
const CustomerBlancoOfferNextStepsPage = Loadable(lazy(() => import('src/customerPages/pages/blanco/BlancoNextStepsPage')));

// Credit Card Page
const CreditCardApplicationsListPage = Loadable(lazy(() => import('src/pages/creditCardPage/CreditCardApplicationsListPage')));
const SingleCreditCardApplicationPage = Loadable(lazy(() => import('src/pages/creditCardPage/SingleCreditCardApplicationPage')));
const ViewCreditCardApplication = Loadable(lazy(() => import('src/pages/creditCardPage/ViewCreditCardApplicationPage')));

// Dialmaker
const CampaignsPage = Loadable(lazy(() => import('src/pages/dialmakerDesktop/CampaignsPage')));
const CustomerProfilePage = Loadable(lazy(() => import('src/pages/dialmakerDesktop/CustomerProfilePage')));
const SearchLeadsPage = Loadable(lazy(() => import('src/pages/dialmakerDesktop/SearchLeadsPage')));
const CallbacksListPage = Loadable(lazy(() => import('src/pages/dialmakerDesktop/CallbacksListPage')));
const PersonalCallbacksLeadPage = Loadable(lazy(() => import('src/pages/dialmakerDesktop/PersonalCallbacksLeadPage')));
const SearchedLeadViewPage = Loadable(lazy(() => import('src/pages/dialmakerDesktop/SearchedLeadViewPage')));
const TelavoxDetailsPage = Loadable(lazy(() => import('src/pages/dialmakerDesktop/TelavoxDetailsPage')));

// Anomaly Detection
const AnomalyDetectionPage = Loadable(lazy(() => import('src/pages/anomalyDetection/AnomalyDetectionPage')));

// ZMS
const EditZMS = Loadable(lazy(() => import('src/pages/zms/EditZMS')));
const ZMSConfigsView = Loadable(lazy(() => import('src/pages/zms/ZMSConfigsList')));
// import routes from 'src/'

const FormsPlayground = Loadable(lazy(() => import('../pages/FormsPlayground')));

const Redirect: FC<{ to: string }> = (props) => {
  const { to } = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    navigate(to, { replace: true, state });
  }, [to]);

  return (
    <Typography>
      You will be redirected to
      {' '}
      {to}
    </Typography>
  );
};

const RedirectID: FC = (props) => {
  const location = useLocation();
  let newPath = location.pathname.replace(/\/_/g, '/').replace(/\/\//g, '/');

  if (location.search) newPath += `?${location.search}`;

  return (
    <Redirect
      to={newPath}
      {...props}
    />
  );
};

interface BaseItem {
  path?: string;
  hidden?: boolean;
  element?: JSX.Element;
  zoordinates?: Array<AllOptionalZoordinates>;
  roles?: Array<Array<string>>;
  product?: Product;
  children?: Array<BaseItem>;
  title?: string;
  isBank?: boolean;
  icon?: JSX.Element;
  shared?: boolean;
  public?: boolean;
  zoordinatesOrRolesAreEnough?: boolean;
  customerPage?: boolean;
  dialmakerDesktop?: boolean;
  theme? : string;
}

const allSections: Array<BaseItem> = [
  {
    hidden: true,
    path: 'authentication',
    public: true,
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
        public: true,
      },
      {
        path: 'login-unguarded',
        element: <Login />,
        public: true,
      },
    ],
  },
  {
    customerPage: true,
    theme: 'customerPages',
    hidden: true,
    path: '/welcome-back',
    public: true,
    element: (
      <LoginRedirectPage />
    ),
  },
  {
    // customerPage: true,
    theme: 'customerPages',
    hidden: true,
    path: '/customer-pages',
    public: true,
    element: (
      <AuthGuard
        authenticationPlatform={AuthenticationPlatform.BankIdNo}
        desiredPage={DesiredAuthPage.External}
      >
        <CustomerPagesDashboard />
      </AuthGuard>
    ),
    children: [
      {
        path: '/credit-card',
        theme: 'customerPages',
        element: <CustomerCreditCardPage />,
      },
    ],
  },
  {
    customerPage: true,
    theme: 'customerPages',
    path: '/',
    public: true,
    element: (
      <AuthGuard
        desiredPage={DesiredAuthPage.External}
      >
        <CustomerPagesDashboard />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        customerPage: true,
        theme: 'customerPages',
        zoordinates: [{ Country: ZCountry.NO }],
        children: [
          {
            path: '/',
            title: 'Home',
            theme: 'customerPages',
            element: <MainPage />,
            icon: <Home />,
          },
          {
            title: 'Your Credit Card Offers',
            path: 'credit-cards',
            theme: 'customerPages',
            element: <CustomerCreditCardPage />,
            icon: <CreditCard />,
          },
          {
            path: 'blanco',
            title: 'Your Offers',
            theme: 'customerPages',
            element: <CustomerBlancoOffersPage />,
            icon: <Money />,
          },
          {
            path: '/offer/:offerId',
            theme: 'customerPages',
            hidden: true,
            element: <CustomerBlancoOfferDetailsPage />,
          },
          {
            path: '/offer/:offerId/accept',
            theme: 'customerPages',
            hidden: true,
            element: <CustomerBlancoOfferAcceptPage />,
          },
          {
            path: '/next-steps',
            theme: 'customerPages',
            hidden: true,
            element: <CustomerBlancoOfferNextStepsPage />,
          },
          {
            title: 'Safety Insurance',
            path: 'insurance',
            element: <CustomerLoanInsurancePage insuranceGroup={InsuranceGroup.SAFETY_INSURANCE} />,
            icon: <HealthAndSafety />,
          },
          // {
          //   title: 'Accident Insurance',
          //   path: 'accident_insurance',
          //   element: <CustomerLoanInsurancePage insuranceGroup={InsuranceGroup.ACCIDENT_INSURANCE} />,
          //   icon: <HealthAndSafety />,
          // },
          {
            path: '/insurance/terms',
            hidden: true,
            element: <CustomerLoanInsuranceTermsPage />,
          },
          {
            path: '/insurance/success',
            hidden: true,
            element: <CustomerLoanInsuranceSuccessPage />,
          },
          {
            title: 'Gjeldsregisteret',
            theme: 'customerPages',
            path: 'gjeld',
            element: <CustomerGRPage />,
            icon: <ReceiptIcon fontSize="small" />,
          },
        ],
      },
      {
        path: '',
        customerPage: true,
        zoordinates: [{ Country: ZCountry.SE }],
        children: [
          {
            path: '/',
            title: 'Home',
            theme: 'customerPages',
            element: <MainPage />,
            icon: <Home />,
          },
          {
            path: 'blanco',
            title: 'Your Offers',
            theme: 'customerPages',
            element: <CustomerBlancoOffersPage />,
            icon: <Money />,
          },
          {
            path: '/offer/:offerId',
            hidden: true,
            theme: 'customerPages',
            element: <CustomerBlancoOfferDetailsPage />,
          },
          {
            path: '/offer/:offerId/accept',
            hidden: true,
            theme: 'customerPages',
            element: <CustomerBlancoOfferAcceptPage />,
          },
          {
            path: '/next-steps',
            hidden: true,
            theme: 'customerPages',
            element: <CustomerBlancoOfferNextStepsPage />,
          },
          {
            title: 'Safety Insurance',
            path: 'insurance',
            element: <CustomerLoanInsurancePage insuranceGroup={InsuranceGroup.SAFETY_INSURANCE} />,
            icon: <HealthAndSafety />,
          },
          {
            title: 'Accident Insurance',
            path: 'accident_insurance',
            element: <CustomerLoanInsurancePage insuranceGroup={InsuranceGroup.ACCIDENT_INSURANCE} />,
            icon: <HealthAndSafety />,
          },
          {
            path: '/insurance/terms',
            hidden: true,
            element: <CustomerLoanInsuranceTermsPage />,
          },
          {
            path: '/insurance/success',
            hidden: true,
            element: <CustomerLoanInsuranceSuccessPage />,
          },
        ],
      },
    ],
  },
  {
    customerPage: true,
    path: '',
    public: true,
    element: (
      <UnauthenticatedCustomerPagesDashboard />
    ),
    children: [
      {
        title: 'Insurance',
        path: '/insurances/new',
        element: <CustomerUnauthorizedLoanInsurancePage />,
        icon: <HealthAndSafety />,
      },
      {
        path: '/insurances/terms',
        hidden: true,
        element: <CustomerLoanInsuranceTermsPage />,
      },
      {
        path: '/insurances/success',
        hidden: true,
        element: <CustomerLoanInsuranceSuccessPage />,
      },
    ],
  },
  {
    customerPage: true,
    hidden: true,
    path: '/qr-login',
    theme: 'customerPages',
    public: true,
    element: (
      <CustomerLoginPageSeQrCode />
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard authenticationPlatform={AuthenticationPlatform.Auth0}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'searcher',
        element: <Searcher />,
        hidden: true,
      },
      { // TODO create ZRM overview and uncomment
        path: '',
        element: <Redirect to="/mortgage/my_applications" />,
        zoordinates: [{ Product: productToZProduct(Product.Mortgage), Department: ZDepartment.KH }],
      },
      { // TODO create ZRM overview and uncomment
        path: '',
        element: <Redirect to="/mortgage/applications" />,
        zoordinates: [{ Product: productToZProduct(Product.Mortgage) }],
      },
      {
        path: '',
        element: <Redirect to="/quick-create/_new" />,
        zoordinates: [{ Product: productToZProduct(Product.Blanco), Department: ZDepartment.HL }],
      },
      { // TODO create ZRM overview and uncomment
        path: '',
        element: <Redirect to="/ponytail/my-stats" />,
        zoordinates: [{ Product: productToZProduct(Product.Blanco) }],
      },
      {
        path: '',
        element: <Redirect to="/bank" />,
        isBank: true,
      },
      {
        path: '',
        element: <Redirect to="/dialmaker-desktop/customer-card" />,
        dialmakerDesktop: true,
      },
      {
        title: 'Mortgage',
        zoordinates: [{ Product: productToZProduct(Product.Mortgage) }],
        children: [
          {
            title: 'New App',
            path: '/mortgage/application/new',
            icon: <PlusIcon fontSize="small" />,
            element: <NewMortgage />,
          },
          {
            title: 'Recent Apps',
            path: '/mortgage/applications',
            icon: <ReceiptIcon fontSize="small" />,
            element: <AllMortgageList />,
          },
          {
            title: 'My Apps',
            path: '/mortgage/my_applications',
            zoordinates: [{ Department: ZDepartment.KH }],
            icon: <UsersIcon fontSize="small" />,
            element: <MyMortgageList />,
          },
          {
            title: 'Leads',
            path: '/mortgage/leads',
            icon: <ReceiptIcon fontSize="small" />,
            element: <LeadList />,
          },
          {
            title: 'My Leads',
            path: '/mortgage/my-leads',
            icon: <UsersIcon fontSize="small" />,
            element: <MineLeadList />,
          },
          {
            title: 'My Upcoming Meetings',
            path: '/mortgage/meetings/calendar',
            zoordinates: [{ Department: ZDepartment.KH }],
            icon: <Calendar fontSize="small" />,
            element: <MortgagesMeetingsCalendar />,
          },
          {
            title: 'All Upcoming Meetings',
            path: '/mortgage/meetings/calendar-all',
            roles: [[RoleType.MortgageAdmin]],
            icon: <Calendar fontSize="small" />,
            element: <MortgagesMeetingsCalendar admin />,
          },
          {
            path: '/mortgage/_application/:applicationId',
            hidden: true,
            element: <RedirectID />,
          },
          {
            path: '/mortgage/application/:applicationId',
            hidden: true,
            element: <MortgageDetails />,
          },
          {
            path: '/mortgage/lead/:leadId',
            hidden: true,
            element: <LeadView />,
          },
          {
            path: '/mortgage/_lead/:leadId',
            hidden: true,
            element: <RedirectID />,
          },
          {
            path: '/mortgage/lead/new',
            hidden: true,
            element: <NewLeadView />,
          },
        ],
      },
      {
        title: 'Online Admin',
        children: [
          {
            title: 'New Form Preference',
            icon: <PlusIcon fontSize="small" />,
            path: '/form-pref/new',
            element: <NewFormPrefView />,
            roles: [[RoleType.OnlineAdmin]],
          },
          {
            title: 'Form Preferences List',
            path: '/form-prefs-list',
            icon: <ReceiptIcon fontSize="small" />,
            element: <FormPrefList />,
            roles: [[RoleType.OnlineAdmin]],
          },
          {
            path: '/form-pref/:formPrefId',
            hidden: true,
            element: <FormPrefView />,
            roles: [[RoleType.OnlineAdmin]],
          },
          {
            title: 'ZMS',
            path: '/zms/list',
            icon: <ReceiptIcon fontSize="small" />,
            element: <ZMSConfigsView />,
            roles: [[RoleType.OnlineAdmin], [RoleType.Developer]],
          },
          {
            path: '/zms/:country',
            hidden: true,
            element: <EditZMS />,
            roles: [[RoleType.OnlineAdmin], [RoleType.Developer]],
          },
        ],
      },
      {
        title: 'Quick Create',
        zoordinates: [{ Product: productToZProduct(Product.Blanco) }],
        children: [
          {
            title: 'Quick Create',
            path: '/quick-create/new',
            icon: <PlusIcon fontSize="small" />,
            element: <QuickCreate />,
            zoordinates: [{ Product: ZProduct.UL }],
          },
          {
            path: '/quick-create/_new',
            hidden: true,
            element: <RedirectID />,
          },
          // {
          //   title: 'Blanco Leads',
          //   path: '/blanco/leads',
          //   icon: <ReceiptIcon fontSize="small" />,
          //   element: <LeadList />,
          // },
        ],
      },
      {},
      {
        title: 'Insurance',
        zoordinates: [{ Department: ZDepartment.KH }],
        roles: [[RoleType.ZakerInsuranceCreate]],
        zoordinatesOrRolesAreEnough: true,
        children: [
          {
            title: 'Create Safety Insurance',
            path: '/insurance/tf/new',
            icon: <PlusIcon fontSize="small" />,
            roles: [[RoleType.ZakerInsuranceCreate]],
            zoordinates: [{ Department: ZDepartment.KH }],
            element: <NewInsurance insuranceGroup={InsuranceGroup.SAFETY_INSURANCE} />,
          },
          {
            title: 'Create Accident Insurance',
            path: '/insurance/accident/new',
            icon: <PlusIcon fontSize="small" />,
            element: <NewInsurance insuranceGroup={InsuranceGroup.ACCIDENT_INSURANCE} />,
            zoordinates: [{ Country: ZCountry.SE }],
          },
          {
            title: 'My insurances',
            path: '/insurance/my',
            icon: <ReceiptIcon fontSize="small" />,
            element: <InsuranceList />,
          },
          {
            path: '/insurance/:insuranceId',
            element: <InsuranceView />,
            hidden: true,
          },
          {
            path: 'insurance/_/:insuranceId',
            element: <RedirectID />,
            hidden: true,
          },
          {
            title: 'Admin Sales Report',
            path: '/insurance/admin-sales-report',
            icon: <ReceiptIcon fontSize="small" />,
            roles: [[RoleType.ZensumSakerReadSalesStats]],
            element: <InsuranceAdminSalesReport />,
          },
          {
            title: 'Winbackable',
            path: '/insurance/winbackable',
            icon: <Recommend fontSize="small" />,
            roles: [[RoleType.ZakerInsuranceCreate]],
            element: <ZakerWinbackable />,
          },
        ],
      },
      {
        title: 'ML Engine',
        roles: [[RoleType.DataAdmin]],
        children: [
          {
            title: 'New Smart Filter',
            path: '/ml-engine/smart-filter/new',
            icon: <PlusIcon fontSize="small" />,
            element: <NewSmartFilter />,
          },
          {
            title: 'New Orvarscore models',
            path: '/ml-engine/orvarscore/new',
            icon: <PlusIcon fontSize="small" />,
            element: <NewOrvarscore />,
          },
          {
            path: '/ml-engine/orvarscore/new/_/',
            element: <RedirectID />,
            hidden: true,
          },
          {
            title: 'Smart Filters',
            path: '/ml-engine/smart-filters',
            icon: <ReceiptIcon fontSize="small" />,
            element: <SmartFilterList />,
          },
          {
            path: '/ml-engine/smart-filter/:model_id',
            element: <SmartFilterView />,
            hidden: true,
          },
          {
            path: '/ml-engine/smart-filter/_/:model_id',
            element: <RedirectID />,
            hidden: true,
          },
        ],
      },
      {
        title: 'Dial Maker',
        roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
        children: [
          {
            title: 'New TM Campaign',
            path: '/dial-maker/campaign/new',
            icon: <PlusIcon fontSize="small" />,
            element: <NewTMCampaign />,
            roles: [[RoleType.DialmakerAdmin]],
          },
          {
            title: 'TM Campaign',
            path: '/dial-maker/campaign/:campaignId',
            icon: <PlusIcon fontSize="small" />,
            element: <TMCampaign />,
            hidden: true,
            roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
          },
          {
            path: 'dial-maker/campaign/_/:campaignId',
            element: <RedirectID />,
            hidden: true,
          },
          {
            title: 'TM Campaigns',
            path: '/dial-maker/campaigns',
            icon: <ReceiptIcon fontSize="small" />,
            element: <TMCampaigns />,
            roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
          },
        ],
      },
      {
        title: 'New DialMaker',
        roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
        children: [
          {
            title: 'New Campaign',
            path: '/dialmaker/campaign/new',
            icon: <PlusIcon fontSize="small" />,
            element: <DialmakerNewCampaign />,
            roles: [[RoleType.DialmakerAdmin]],
          },
          {
            title: 'Campaign',
            path: '/dialmaker/campaign/:campaignId',
            icon: <PlusIcon fontSize="small" />,
            element: <DialmakerCampaign />,
            hidden: true,
            roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
          },
          {
            path: 'dialmaker/campaign/_/:campaignId',
            element: <RedirectID />,
            hidden: true,
          },
          {
            title: 'Campaigns',
            path: '/dialmaker/campaigns',
            icon: <ReceiptIcon fontSize="small" />,
            element: <DialmakerCampaigns />,
            roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
          },
          {
            title: 'Leads Search',
            path: '/dialmaker/admin-leads-search',
            icon: <ManageSearch fontSize="small" />,
            element: <DialmakerAdminLeadsSearch />,
            roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
          },
          {
            title: 'Campaigns Stats',
            path: '/dialmaker/campaigns/stats',
            icon: <SummarizeOutlined fontSize="small" />,
            element: <DialmakerCampaignsStats />,
            roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
          },
          {
            title: 'Per User Stats',
            path: '/dialmaker/user/stats',
            icon: <SummarizeOutlined fontSize="small" />,
            element: <DialmakerUserCampaignStats />,
            roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
          },
          {
            title: 'Block Phone',
            path: '/dialmaker/block',
            icon: <PriorityHigh fontSize="small" />,
            element: <DialmakerBlocksPage />,
            roles: [[RoleType.DialmakerAdmin], [RoleType.TeamLead]],
          },
        ],
      },
      {
        title: 'Mailmaker',
        roles: [[RoleType.DialmakerAdmin]],
        children: [
          {
            title: 'New Campaign',
            path: '/mailmaker/campaign/new',
            icon: <PlusIcon fontSize="small" />,
            element: <DialmakerNewCampaign />,
            roles: [[RoleType.DialmakerAdmin]],
          },
          {
            title: 'Campaign',
            path: '/mailmaker/campaign/:campaignId',
            icon: <PlusIcon fontSize="small" />,
            element: <DialmakerCampaign />,
            hidden: true,
            roles: [[RoleType.DialmakerAdmin]],
          },
          {
            path: '/mailmaker/campaign/_/:campaignId',
            element: <RedirectID />,
            hidden: true,
          },
          {
            title: 'Campaigns',
            path: '/mailmaker/campaigns',
            icon: <ReceiptIcon fontSize="small" />,
            element: <DialmakerCampaigns />,
            roles: [[RoleType.DialmakerAdmin]],
          },
          {
            title: 'Campaigns Stats',
            path: '/mailmaker/campaigns/stats',
            icon: <SummarizeOutlined fontSize="small" />,
            element: <DialmakerCampaignsStats />,
            roles: [[RoleType.DialmakerAdmin]],
          },
        ],
      },
      {
        title: 'Affiliate',
        roles: [[RoleType.AffiliateAdmin]],
        children: [
          {
            title: 'New Affiliate',
            path: '/affiliate/new',
            icon: <PlusIcon fontSize="small" />,
            element: <CreateAffiliate />,
          },
          {
            title: 'Affiliates Overview',
            path: '/affiliate/list',
            icon: <ReceiptIcon fontSize="small" />,
            element: <AffiliatesList />,
          },
          {
            title: 'Affiliate Networks',
            path: '/affiliate/networks',
            icon: <ReceiptIcon fontSize="small" />,
            element: <AffiliateNetworks />,
          },
          {
            title: 'Affiliate Networks',
            path: '/affiliate/networks/:affiliateNetworkId/publishers/:affiliateId',
            element: <AffiliateNetworks />,
            hidden: true,
          },
          {
            title: 'Affiliate Networks',
            path: '/affiliate/networks/:affiliateId',
            element: <AffiliateNetworks />,
            hidden: true,
          },
          {
            title: 'Affiliates Edit',
            path: '/affiliate/:affiliateId',
            element: <EditAffiliate />,
            hidden: true,
          },
          {
            path: 'affiliate/_/:affiliateId',
            element: <RedirectID />,
            hidden: true,
          },
        ],
      },
      {
        title: 'Submissions',
        zoordinates: [{ Department: ZDepartment.KH }],
        children: [
          {
            title: 'Success Submissions',
            path: '/submissions/',
            icon: <Check fontSize="small" />,
            element: <SuccessSubmissionList />,
          },
          {
            title: 'Failed Submissions',
            path: '/submissions/failed',
            icon: <PriorityHigh fontSize="small" />,
            element: <FailedSubmissionList />,
          },
          {
            hidden: true,
            path: '/submissions/details/:submissionId',
            element: <SubmissionDetailsPage />,
          },
          {
            hidden: true,
            path: '/submissions/details/_/:submissionId',
            element: <RedirectID />,
          },
        ],
      },
      {
        title: 'Shortened URLs',
        zoordinates: [],
        children: [
          {
            title: 'New Shortened URL',
            path: '/shortened-urls/new',
            icon: <PlusIcon fontSize="small" />,
            element: <NewShortenedUrl />,
          },
          {
            title: 'Shortened URLs List',
            path: '/shortened-urls/',
            icon: <Link fontSize="small" />,
            element: <ShortenedUrlsList />,
          },
          {
            hidden: true,
            path: '/shortened-urls/details/:shortenedUrlKey',
            element: <ShortenedUrlDetailsPage />,
          },
          {
            hidden: true,
            path: '/shortened-urls/details/_/:shortenedUrlKey',
            element: <RedirectID />,
          },
        ],
      },
      {
        title: 'Credit Cards',
        zoordinates: [{ Product: productToZProduct(Product.CreditCard), Department: ZDepartment.KH }],
        children: [
          {
            title: 'New Credit Card Application',
            path: '/credit-card-application/create',
            icon: <PlusIcon fontSize="small" />,
            element: <SingleCreditCardApplicationPage />,
          },
          {
            title: 'Credit Card Applications',
            path: '/credit-card-applications',
            icon: <CreditCard fontSize="small" />,
            element: <CreditCardApplicationsListPage />,
          },
          {
            path: '/credit-card-application/:creditCardApplicationId',
            hidden: true,
            element: <ViewCreditCardApplication />,
          },
          {
            path: '/credit-card-application/_/:creditCardApplicationId',
            hidden: true,
            element: <RedirectID />,
          },
        ],
      },
      {
        title: 'Individual Overview',
        children: [
          {
            title: 'Individual Overview',
            path: '/ponytail/my-stats',
            icon: <Dashboard fontSize="small" />,
            element: <MyStats />,
          },
          {
            title: 'Summarized Overview',
            path: '/ponytail/summarized',
            icon: <Dashboard fontSize="small" />,
            roles: [[RoleType.READ_BLANCO_SALES_STATS], [RoleType.READ_MORTGAGE_SALES_STATS]],
            element: <SummarizedView />,
          },
        ],
      },
      {
        title: 'RQ Jobs',
        roles: [[RoleType.Developer]],
        children: [
          {
            title: 'RQ Job Overview',
            path: '/rq/list',
            icon: <Dashboard fontSize="small" />,
            element: <RqStatusList />,
          },
          {
            title: 'RQ Job Detail',
            path: '/rq/detail/:jobId',
            hidden: true,
            element: <RqDetailed />,
          },
        ],
      },
      {
        title: 'Anomaly Detection',
        roles: [[RoleType.Admin], [RoleType.Developer], [RoleType.DataAdmin], [RoleType.InsuranceAdmin], [RoleType.OnlineAdmin], [RoleType.DialmakerAdmin]],
        children: [
          {
            title: 'Anomaly Detection List',
            path: '/anomaly-detection',
            icon: <AutoGraph fontSize="small" />,
            element: <AnomalyDetectionPage />,
          },
        ],
      },
      {
        title: 'Forms playground',
        roles: [[RoleType.Admin], [RoleType.Developer], [RoleType.DataAdmin], [RoleType.InsuranceAdmin], [RoleType.OnlineAdmin], [RoleType.DialmakerAdmin]],
        children: [
          {
            title: 'Forms playground',
            path: '/forms-playground',
            icon: <Web fontSize="small" />,
            element: <FormsPlayground />,
          },
        ],
      },
      {
        title: 'Management',
        children: [
          {
            title: 'Internal Users',
            roles: [[RoleType.EmployeeAdmin]],
            icon: <UsersIcon fontSize="small" />,
            children: [
              {
                title: 'All',
                path: '/internal-users',
                icon: <UsersIcon fontSize="small" />,
                element: <AllInternalUsersList />,
              },
              {
                title: 'Telavox Mismatch',
                path: '/telavox-mismatch',
                icon: <UsersIcon fontSize="small" />,
                element: <TelavoxMismatch />,
              },
              {
                title: 'New User',
                path: '/internal-users/new',
                icon: <PlusIcon fontSize="small" />,
                element: <NewInternalUser />,
              },
              {
                path: '/internal-users/:user_id',
                hidden: true,
                element: <InternalUser />,
              },
              {
                path: '/internal-users/_/:user_id',
                hidden: true,
                element: <RedirectID />,
              },
            ],
          },
          {
            roles: [[RoleType.PartnerAdmin]],
            title: 'Partner Users',
            icon: <UsersIcon fontSize="small" />,
            children: [
              {
                title: 'All Partner User',
                path: '/partner/users',
                icon: <UsersIcon fontSize="small" />,
                element: <AllPartnerUsersList />,
              },
              {
                title: 'New Partner User',
                path: '/partner/user/new',
                icon: <PlusIcon fontSize="small" />,
                element: <NewPartnerUser />,
              },
              {
                hidden: true,
                path: '/partner/user/:user_id',
                element: <PartnerUser />,
              },
              {
                hidden: true,
                path: '/partner/_user/:user_id',
                element: <RedirectID />,
              },
            ],
          },
          {
            title: 'Customers',
            roles: [
              [RoleType.KdltAdmin], [RoleType.Developer], [RoleType.Admin], [RoleType.ImpersonateCustomer],
              [RoleType.MbAdmin], [RoleType.DataAdmin], [RoleType.AffiliateAdmin], [RoleType.DialmakerAdmin],
              [RoleType.MortgageAdmin],
            ],
            icon: <UsersIcon fontSize="small" />,
            hidden: true,
            children: [
              {
                title: 'All Customers',
                path: '/customers',
                icon: <UsersIcon fontSize="small" />,
                element: <CustomerList />,
              },
              {
                hidden: true,
                element: <CustomerView />,
                path: '/customers/:customer_id',
              },
              {
                hidden: true,
                element: <RedirectID />,
                path: '/_customers/:customer_id',
              },
            ],
          },
        ],
      },
      {
        title: 'Templates',
        children: [
          {
            title: 'Email',
            roles: [[RoleType.TemplateAdmin]],
            icon: <MailIcon fontSize="small" />,
            children: [
              {
                title: 'Email Template List',
                path: '/email/templates',
                icon: <ReceiptIcon fontSize="small" />,
                element: <EmailTemplatesView />,
              },
              {
                title: 'Add Template',
                path: '/email/templates/new',
                icon: <PlusIcon fontSize="small" />,
                element: <NewTemplate />,
              },
              {
                title: 'Add attachment',
                path: '/email/attachments/new',
                icon: <PlusIcon fontSize="small" />,
                element: <UploadAttachments />,
              },
              {
                path: '/email/templates/:country/:product/:event',
                hidden: true,
                element: <EditTemplate />,
              },
              {
                path: '/email/_attachments',
                hidden: true,
                element: <RedirectID />,
              },
              {
                path: '/email/templates/_new',
                hidden: true,
                element: <RedirectID />,
              },
            ],
          },
          {
            title: 'SMS',
            roles: [[RoleType.TemplateAdmin]],
            icon: <MailIcon fontSize="small" />,
            children: [
              {
                title: 'SMS Template List',
                path: '/sms/templates',
                icon: <ReceiptIcon fontSize="small" />,
                element: <SmsTemplatesView />,
              },
              {
                title: 'Add Template',
                path: '/sms/templates/new',
                icon: <PlusIcon fontSize="small" />,
                element: <NewSmsTemplate />,
              },
              {
                path: '/sms/templates/:country/:product/:event',
                hidden: true,
                element: <EditSmsTemplate />,
              },
              {
                path: '/sms/templates/_new',
                hidden: true,
                element: <RedirectID />,
              },
            ],
          },
        ],
      },
      {
        title: 'KDLT',
        hidden: true,
        children: [
          {
            path: '/kdlt/pep-check-event/:eventId',
            roles: [[RoleType.KdltAdmin]],
            element: <PepCheckEventView />,
          },
        ],
      },
      {
        path: 'notifications',
        hidden: true,
        element: <NotificationPage />,
      },
      {
        path: '*',
        element: (
          <>
            <NotFound />
            <DashboardLayout />
          </>
        ),
        hidden: true,
      },
      {
        hidden: true,
        path: '500',
        element: (
          <>
            <ServerError />
            <DashboardLayout />
          </>
        ),
      },
    ],
  },
  {
    path: '/',
    dialmakerDesktop: true,
    title: 'Dialmaker Desktop',
    element: (
      <AuthGuard authenticationPlatform={AuthenticationPlatform.Auth0}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Redirect to="/dialmaker-desktop/customer-card" />,
        dialmakerDesktop: true,
      },
      {
        title: 'Dialmaker',
        dialmakerDesktop: true,
        children: [
          {
            title: 'Customer Card',
            path: '/dialmaker-desktop/customer-card',
            icon: <Dashboard fontSize="small" />,
            element: <CustomerProfilePage />,
            dialmakerDesktop: true,
          },
          {
            title: 'Lead Details',
            path: '/dialmaker-desktop/lead/:leadId',
            icon: <Dashboard fontSize="small" />,
            element: <SearchedLeadViewPage />,
            dialmakerDesktop: true,
          },
          {
            title: 'Campaigns',
            path: '/dialmaker-desktop/campaigns',
            icon: <ReceiptIcon fontSize="small" />,
            element: <CampaignsPage />,
            dialmakerDesktop: true,
          },
          {
            title: 'Search',
            path: '/dialmaker-desktop/search',
            icon: <Search fontSize="small" />,
            element: <SearchLeadsPage />,
            dialmakerDesktop: true,
            hidden: true,
          },
          {
            title: 'Callbacks',
            path: '/dialmaker-desktop/callbacks',
            icon: <ListAlt fontSize="small" />,
            element: <CallbacksListPage />,
            dialmakerDesktop: true,
          },
          {
            title: 'Personal Callback Details',
            path: '/dialmaker-desktop/callback/:leadId',
            icon: <Dashboard fontSize="small" />,
            element: <PersonalCallbacksLeadPage />,
            dialmakerDesktop: true,
          },
          {
            title: 'Telavox Details',
            path: '/dialmaker-desktop/telavox-details',
            icon: <Dashboard fontSize="small" />,
            element: <TelavoxDetailsPage />,
            dialmakerDesktop: true,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    isBank: true,
    product: Product.Mortgage,
    element: (
      <AuthGuard authenticationPlatform={AuthenticationPlatform.JWT}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        title: 'Mortgage',
        isBank: true,
        children: [
          {
            title: 'All Applications',
            icon: <ReceiptIcon fontSize="small" />,
            path: '/bank',
            element: <BankMortgageList />,
            isBank: true,
          },
          {
            hidden: true,
            path: '/bank/mortgage/application/:applicationId',
            element: <BankMortgageView />,
            isBank: true,
          },
          {
            hidden: true,
            path: '/bank/mortgage/_application/:applicationId',
            element: <RedirectID />,
            isBank: true,
          },
        ],
      },
    ],
  },
  {
    path: '/logout',
    element: <Logout />,
    shared: true,
    hidden: true,
  },
  {
    path: '/logout',
    element: <CustomerLogout />,
    hidden: true,
    customerPage: true,
  },
  {
    path: '/set-partner-password',
    public: true,
    element: <SetPartnerPassword />,
    hidden: true,
  },
  {
    hidden: true,
    path: '500',
    shared: true,
    element: <ServerError />,
  },
];

const hasZoordinates = (zoordinates: Zoordinates, requiredZoordinates?: Array<AllOptionalZoordinates>): boolean => !requiredZoordinates?.length || !!(zoordinates && requiredZoordinates.find((z) => Object.keys(z).every((zk) => zoordinates[zk] === z[zk])));
const hasRoles = (roles: Array<string>, requiredRoles?: Array<Array<string>>): boolean => roles.includes('admin') || !requiredRoles?.length || !!(roles && requiredRoles.find((ps) => ps.every((p) => roles.includes(p))));

export interface Routes {
  routes: Array<BaseItem>;
}

const RoutesContext = createContext<Routes>({
  routes: [],
});

const filterItem = (s: BaseItem, isBank: boolean, user: ZensUser, isDesktopDialmakerApp: boolean, zoordinates?: Zoordinates, product?: Product) => {
  if (s.public) return true;
  if (!user) return false;
  if (isBank || s.shared) if (s.isBank && (!s.product || s?.product === product)) return true;
  if (!s.isBank && isBank) return false;
  if (s.dialmakerDesktop && !isDesktopDialmakerApp) return false;
  if (!s.dialmakerDesktop && isDesktopDialmakerApp) return false;
  if (!s.isBank || s.shared) if (hasZoordinates(zoordinates, s.zoordinates) && hasRoles(user.roles ?? [], s.roles)) return true;
  if ((!s.isBank || s.shared) && s.zoordinatesOrRolesAreEnough) if (hasZoordinates(zoordinates, s.zoordinates) || hasRoles(user.roles ?? [], s.roles)) return true;

  return false;
};

// Since CC my pages and ZRM are in the same project and same deploy unit. We need to filter out the routes based on the hostname.
// If the customer is on my pages URL, we only want to show the routes that are customerPage: true
const filterRoutesBasedOnHost = (s: BaseItem): boolean => isCustomerPageContext() ? s.customerPage : !s.customerPage;

export const RoutesProvider: FC = (props) => {
  const { children: childComponent } = props;
  const user = useUser();
  const theme = useTheme();
  const { settings: { zoordinates: zoordinatesString }, product, country } = useSettings();
  const { platform } = useAuth();
  const { t } = useTranslation();
  const [success] = useQuery('success', '');
  const { isDesktopDialmakerApp } = useDialmakerDesktopUtils();

  const zoordinates = useMemo((): Zoordinates => {
    if (externalAuthPlatforms.includes(platform)) return { Country: countryToZCountry(country), Product: productToZProduct(product), Department: null, EmploymentType: null, Office: null };

    return parseZoordinates(zoordinatesString);
  }, [zoordinatesString, platform]);

  const sections = useMemo(() => {
    const isBank = platform === AuthenticationPlatform.JWT;
    const isCustomer = externalAuthPlatforms.includes(platform);
    const filteredSections: Array<BaseItem> = allSections
      .filter(filterRoutesBasedOnHost)
      .map((x) => ({ ...x, title: t(x.title) }))
      .filter((s) => filterItem(s, isBank, user as ZensUser, isDesktopDialmakerApp, zoordinates, product))
      .map(({ ...s }) => ({
        ...s,
        children: s.children?.filter((c) => filterItem(c, isBank, user as ZensUser, isDesktopDialmakerApp, zoordinates, product))
          ?.map((i) => ({
            ...i,
            title: t(i.title),
            children: i.children?.map((x) => ({ ...x, title: t(x.title) }))?.filter((c) => filterItem(c, isBank, user as ZensUser, isDesktopDialmakerApp, zoordinates, product)),
          })),
      }));

    const hasUser = user && Object.keys(user).length;
    const desiredPlatform = isCustomer ? DesiredAuthPage.External : DesiredAuthPage.Internal;

    const hasFailed = success === 'false';
    filteredSections.push({
      path: '*',
      hidden: true,
      shared: true,
      element: (
        <AuthGuard
          authenticationPlatform={platform}
          desiredPage={(!hasFailed && !hasUser) ? desiredPlatform : undefined}
        >
          <NotFound />
        </AuthGuard>
      ),
      public: true,
      customerPage: isCustomer,
    });

    return filteredSections;
  }, [zoordinates, platform, user, t, success]);

  const findInRoutes = (list: Array<BaseItem>, locationPath: string) => list?.reduce((acc, section) => {
    if (acc) return acc;

    let res = null;

    if (section?.children) res = findInRoutes(section.children, locationPath);
    if (res) return res;

    if (section?.path && matchPath({ path: section?.path }, locationPath)) return section;

    return null;
  }, null) ?? null;

  const location = useLocation();
  const pathTheme = useMemo(() => {
    const routeUsed = findInRoutes(sections, location.pathname);

    return routeUsed?.theme || '';
  }, [location, sections]);

  useEffect(() => {
    theme.switchTheme(pathTheme);
  }, [pathTheme]);

  return (
    <RoutesContext.Provider value={{ routes: sections }}>
      {childComponent}
    </RoutesContext.Provider>
  );
};

export const RoutesConsumer = RoutesContext.Consumer;

export default RoutesContext;
