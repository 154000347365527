import { FC, ReactNode, useMemo } from 'react';
import { matchPath } from 'react-router-dom';

import { List, ListProps, ListSubheader } from '@material-ui/core';

import NavItem from './NavItem';

const NavPropsToSkip = ['zoordinatesOrRolesAreEnough'];

interface Item {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title?: string;
  hidden?: boolean;
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth,
      }),
      [],
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: JSX.Element[];
  pathname: string;
  item: Item;
  depth: number;
}): Array<JSX.Element> => {
  if (item.hidden) return acc;

  const key = `${item.title}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true,
  }, pathname) : false;

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false,
    }, pathname) : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>,
    );
  } else acc.push(
    <NavItem
      active={exactMatch}
      depth={depth}
      icon={item.icon}
      info={item.info}
      key={key}
      path={item.path}
      title={item.title}
    />,
  );

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const {
    items,
    pathname,
    title,
    ...other
  } = props;

  if (!items?.length) return null;

  // `other` props are passed to the `List` component, so lets remove not needed ones
  const otherProps = useMemo(() => {
    const temp = other;

    NavPropsToSkip.forEach((prop) => {
      delete temp[prop];
    });

    return temp;
  }, [other]);

  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </ListSubheader>
      )}
      {...otherProps}
    >
      {renderNavItems({
        items: items.filter((item) => !item.hidden),
        pathname,
      })}
    </List>
  );
};

export default NavSection;
