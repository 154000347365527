export const getByPath = (obj: object | Array<object>, key: string, defaultValue?: any): object | Array<object> => {
  if (!obj || !key) return undefined;
  if (Array.isArray(obj)) return obj.map((item) => getByPath(item, key));

  const keys = key.split('.');

  if (!keys.length) return undefined; // no first key
  if (keys.length > 1) return getByPath(obj[keys[0]], keys.slice(1).join('.'));

  return obj[keys[0]] ?? defaultValue;
};

const pickOne = (obj: object | Array<object>, key: string): object | Array<object> => {
  if (!obj) return obj;
  if (Array.isArray(obj)) return obj.map((item) => pickOne(item, key));

  const splitKeys = key.split('.');
  const targetValue = obj[splitKeys[0]];

  if (targetValue === undefined) return undefined;

  return {
    [splitKeys[0]]: splitKeys.length > 1 ? pickOne(targetValue, splitKeys.slice(1).join('.')) : targetValue,
  };
};

export const merge = (obj: object | Array<object>, ...parts: Array<object | Array<object>>) => {
  const part = parts.shift();

  if (!part) return obj;
  if (typeof part !== 'object' && part) {
    obj = part;

    return obj;
  }

  if (Array.isArray(part)) {
    obj = new Array(Math.max((obj as Array<any>).length || 0, part.length)).fill(undefined).map((_, i) => merge(obj[i], part[i]));

    return merge(obj, ...parts);
  }

  Object.keys(part).forEach((key) => {
    if (typeof part[key] === 'object' && part[key]) if (obj[key] === undefined) Object.assign(obj, {
      [key]: Array.isArray(part[key]) ? [] : {},
    });

    obj[key] = merge(obj[key], part[key]);
  });

  return merge(obj, ...parts);
};

export const pick = (obj: object | Array<object>, keys: string[]): object | Array<object> => {
  if (!obj) return undefined;
  if (Array.isArray(obj)) return obj.map((v) => pick(v, keys));

  const values = keys
    .filter((key) => getByPath(obj, key) !== undefined)
    .map((key) => pickOne(obj, key));

  return merge(values[0], ...values.splice(1));
};
