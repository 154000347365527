import type { FC } from 'react';
import { memo } from 'react';

import { Box, Grid, useTheme } from '@material-ui/core';

import useMessaging from 'src/hooks/useMessaging';

import Conversation from './Conversation';

const Conversations: FC = memo(() => {
  const { conversations = [] } = useMessaging();
  const theme = useTheme();
  const borderRadius = '4px 4px 0 0';

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        zIndex: theme.zIndex.drawer,
        right: 0,
      }}
    >
      <Grid
        container
        direction="row-reverse"
        spacing={1}
      >
        {conversations.map((conversation) => (
          <Grid item>
            <Box
              sx={{
                width: 400,
                zIndex: theme.zIndex.drawer,
                backgroundColor: theme.palette.background.paper,
                borderRadius,
              }}
            >
              <Conversation
                key={conversation.customer_id}
                width={400}
                borderRadius={borderRadius}
                {...conversation}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

export default Conversations;
