import moment from 'moment-timezone';

export const parseDate = (date?: string | Date | number, ...args) => {
  /*
  parses
    2024-11-07T09:15:00+00:00
    2024-11-07T09:15:00
    2024-11-07T09:15:00Z
    and return one that is parsed corretly and assumed to be UTC
  */
  if (!date) return moment();

  const d = moment(date, ...args);
  const tz = d.tz();
  const isUTC = !d.isUtcOffset(); // if there is a timezone but not Z

  const needFixing = !tz && isUTC;

  if (needFixing && typeof date === 'string') {
    if (!date.includes('T')) return moment(date, ...args);
    if (date.endsWith('Z') || date.endsWith('+00:00')) return moment(date, ...args);
  }
  if (needFixing && typeof date === 'string') {
    const d2 = moment(`${date}Z`, ...args);

    if (d2.isValid()) return d2;

    return d;
  }
  // Date object
  if (needFixing && typeof date === 'object') {
    const d2 = moment(`${date.toISOString()}Z`, ...args);

    if (d2.isValid()) return d2;

    return moment(date, ...args);
  }

  return d;
};
