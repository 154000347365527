import { ChangeEvent, createRef, Dispatch, FC, MouseEvent, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { HelpOutline, Refresh, Warning } from '@material-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { JotformLeadSource, Lead, MeetingWithHandlerName, ReprocessResult, RoleType } from 'src/api/zrm/zrmApi';
import Card from 'src/components/Card';
import CustomerName from 'src/components/CustomerName';
import Scrollbar from 'src/components/Scrollbar';
import HandledLabel from 'src/components/shared/lists/HandledLabel';
import useApi from 'src/hooks/useApi';
import useUser from 'src/hooks/useUser';
import { ZensUser } from 'src/types/auth';
import { formatLongDateTime } from 'src/utils/formatDates';
import { parseDate } from 'src/utils/parseDate';

import LeadsReprocessDialog from './LeadsReprocessDialog';
import CenteredTooltip from '../widgets/info/CenteredTooltip';

interface LeadListTableProps {
  leads: Lead[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  loading: boolean;
  setLimit: Dispatch<SetStateAction<number>>;
  limit: number;
  setIncludeHandled: Dispatch<SetStateAction<number>>;
  includeHandled: number;
  setIncludeFailed: Dispatch<SetStateAction<number>>;
  includeFailed: number;
  setReprocessResponse: Dispatch<SetStateAction<ReprocessResult[]>>;
  unassignedOnly: number;
  setUnassignedOnly: Dispatch<SetStateAction<number>>;
  mineOnly: boolean;
}

interface LeadTableProps {
  leads: Lead[];
  selected?: string[];
  setSelected?: Dispatch<SetStateAction<string[]>>;
  showReprocessDialog?: (leadIds: string[]) => void;
  forceHideReprocess?: boolean;
}

export const LeadTable: FC<LeadTableProps> = (props) => {
  const { leads, selected = [], setSelected, showReprocessDialog, forceHideReprocess } = props;

  const { t } = useTranslation();
  const user = useUser() as ZensUser;
  const { api } = useApi();

  const showReprocess = useMemo(() => user.roles.includes(RoleType.OnlineAdmin) && !forceHideReprocess, [user?.roles]);

  const selectableLeadIdsOnly = useMemo(() => leads?.filter((lead) => lead.reprocessable && !lead.handled).map((lead) => lead.lead_id), [leads]);

  const numSelected = useMemo(() => Array.from(selected).filter((value) => value[1]).length, [selected]);
  const rowCount = useMemo(() => selectableLeadIdsOnly.length, [selectableLeadIdsOnly]);

  const handleSelectAllClick = useMemo(() => (event) => {
    if (event.target.checked) {
      const newSelected = [...selectableLeadIdsOnly];
      setSelected(newSelected);

      return;
    }

    setSelected([]);
  }, [selectableLeadIdsOnly]);

  const handleSelect = useMemo(() => (event, leadId: string) => {
    event.preventDefault();

    if (!selectableLeadIdsOnly.includes(leadId)) return;

    const selectedIndex = selected.indexOf(leadId);
    let newSelected = [];

    if (selectedIndex === -1) newSelected = newSelected.concat(selected, leadId);
    else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
    else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
    else if (selectedIndex > 0) newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );

    setSelected(newSelected);
  }, [selectableLeadIdsOnly, selected]);

  const isSelected = useMemo(() => (leadId: string) => selected.indexOf(leadId) !== -1, [selected]);

  const [upcomingMeetings, setUpcomingMeetings] = useState<{ data: Array<MeetingWithHandlerName>, loading: boolean }>({ data: [], loading: false });

  useEffect(() => {
    if (!leads.length) return () => {};

    setUpcomingMeetings((p) => ({ ...p, loading: true }));
    const requestId = uuid();

    (async () => {
      try {
        const resp = await api.meeting.getMeetingsMeetingGetPost({ application_ids: leads.map((x) => x.lead_id), with_handler_name: true }, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId });
        setUpcomingMeetings({ data: resp.data, loading: false });
      } catch (e) {
        setUpcomingMeetings((p) => ({ ...p, loading: false }));
      }
    })();

    return () => { api.abortRequest(requestId); };
  }, [leads, api]);

  return (
    <Table component="div">
      <TableHead component="div">
        <TableRow component="div">
          {showReprocess && (
            <TableCell
              padding="checkbox"
              component="span"
            >
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
              />
            </TableCell>
          )}
          <TableCell component="span">
            {t('Lead ID')}
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {t('Created At')}
            </Typography>
          </TableCell>
          <TableCell component="span">
            {t('Source')}
          </TableCell>
          <TableCell component="span">
            {t('Applicants')}
          </TableCell>
          <TableCell component="span">
            {t('Next meeting')}
          </TableCell>
          <TableCell component="span">
            {t('Handled')}
          </TableCell>
          <TableCell
            component="span"
            sx={{ maxWidth: 300 }}
          >
            <Box
              component="span"
              sx={{ display: 'inline-flex', justifyContent: 'center' }}
            >
              {t('Reprocess')}
              <CenteredTooltip
                title={t('Reprocess data provided by customer in Online Form. Usefull when form submission has changed or some processing errors have occured. Available only for admins.')}
                sx={{ ml: 1 }}
              >
                <HelpOutline color="action" />
              </CenteredTooltip>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody component="div">
        {leads?.map((lead: Lead) => {
          const isRowSelected = isSelected(lead.lead_id);
          const canBeSelected = selectableLeadIdsOnly.includes(lead.lead_id);
          const leadError = (lead.create_source as JotformLeadSource)?.error;
          const upcomingMeeting = upcomingMeetings.data.filter((meeting) => meeting.application_id === lead.lead_id).sort((a, b) => -parseDate(a.meeting_start).diff(b.meeting_start))[0];

          const showMeetingWarning = !lead.handled && upcomingMeeting && parseDate(moment.now()).diff(upcomingMeeting.meeting_start) > 0;

          return (
            <TableRow
              hover
              key={lead.lead_id}
              selected={isRowSelected}
              sx={{
                textDecoration: 'none',
              }}
              component={Link}
              to={`/mortgage/_lead/${lead.lead_id}`}
              state={{ lead }}
            >
              {showReprocess && (
                <TableCell
                  padding="checkbox"
                  component="span"
                  onClick={(e) => handleSelect(e, lead.lead_id)}
                >
                  {canBeSelected && (
                    <Checkbox
                      color="primary"
                      checked={isRowSelected || false}
                    />
                  )}
                </TableCell>
              )}
              <TableCell component="span">
                {lead.lead_id}
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {formatLongDateTime(lead.created_at)}
                </Typography>
              </TableCell>
              <TableCell component="span">
                {lead.source || lead.create_source?.type}
              </TableCell>
              <TableCell component="span">
                <CustomerName
                  key={lead.data?.applicant?.pni}
                  color="textPrimary"
                  variant="subtitle2"
                  pni={lead.data?.applicant?.pni}
                />
                {lead?.data?.co_applicant && (
                  <CustomerName
                    key={lead.data?.co_applicant.pni}
                    color="textPrimary"
                    variant="subtitle2"
                    pni={lead.data?.co_applicant.pni}
                  />
                )}
              </TableCell>
              <TableCell component="span">
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                >
                  {showMeetingWarning && (
                    <Grid
                      item
                      sx={{ mx: 1, mt: 1 }}
                    >
                      <CenteredTooltip title={t('This lead is not handled and has last activity in the past')}>
                        <Warning color="error" />
                      </CenteredTooltip>
                    </Grid>
                  )}
                  {upcomingMeeting && (
                    <Grid item>
                      {upcomingMeeting?.handler_name}
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {parseDate(upcomingMeeting.meeting_start).format('LLL')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </TableCell>
              <TableCell component="span">
                <HandledLabel handled={lead.handled} />
              </TableCell>
              <TableCell
                component="span"
                sx={{ p: 0 }}
                onClick={(e) => { e.preventDefault(); }}
              >
                <Typography
                  component="span"
                  sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', cursor: 'default', p: 2, width: 100 }}
                >
                  {!!leadError && (
                    <CenteredTooltip
                      title={leadError}
                    >
                      <Warning
                        color="error"
                        sx={{ mx: 1 }}
                      />
                    </CenteredTooltip>
                  )}
                  {showReprocess && lead.reprocessable && !lead.handled && (
                    <CenteredTooltip title="Reprocess lead">
                      <IconButton
                        onClick={() => showReprocessDialog([lead.lead_id])}
                        sx={{ p: 1 }}
                      >
                        <Refresh />
                      </IconButton>
                    </CenteredTooltip>
                  )}
                  {!leadError && !(showReprocess && lead.reprocessable && !lead.handled) && (
                    <Typography>---</Typography>
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const LeadListTable: FC<LeadListTableProps> = (props) => {
  const { leads, page, setPage, loading, limit, setLimit, includeHandled, setIncludeHandled, includeFailed, setIncludeFailed, setReprocessResponse, mineOnly, unassignedOnly, setUnassignedOnly, ...other } = props;
  const { t } = useTranslation();
  const user = useUser() as ZensUser;

  const [selected, setSelected] = useState<string[]>([]);

  const handlePageChange = useMemo(() => (_event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setSelected([]);
  }, [setPage]);

  const handleLimitChange = useMemo(() => (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
    setSelected([]);
  }, [setLimit]);

  const toggleIncludeHandled = useMemo(() => () => {
    setIncludeHandled((p) => Number(!p));
    setSelected([]);
    setPage(0);
  }, [setIncludeHandled]);

  const toggleIncludeFailed = useMemo(() => () => {
    setIncludeFailed((p) => Number(!p));
    setPage(0);
    setSelected([]);
  }, [setIncludeFailed]);

  const toggleUnassignedOnly = useMemo(() => () => {
    setUnassignedOnly((p) => Number(!p));
    setPage(0);
    setSelected([]);
  }, [setUnassignedOnly]);

  const reprocessDialogRef = createRef<any>();

  const showReprocessDialog = useCallback((leadIds: string[]) => {
    reprocessDialogRef.current.open(leadIds);
  }, [reprocessDialogRef, reprocessDialogRef?.current]);

  return (
    <>
      <Card
        {...other}
        loading={loading}
        title={t('Leads')}
      >
        <Grid
          container
          spacing={1}
          sx={{ mb: 1 }}
        >
          {!mineOnly && (
            <Grid
              item
              xs={12}
              md={3}
              xl={2}
            >
              <FormControlLabel
                checked={!!includeFailed}
                color="primary"
                control={<Checkbox />}
                onChange={toggleIncludeFailed}
                label={t('Include Failed Leads')}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={3}
            xl={2}
          >
            <FormControlLabel
              checked={!!includeHandled}
              color="primary"
              control={<Checkbox />}
              onChange={toggleIncludeHandled}
              label={t('Include Handled Leads')}
            />
          </Grid>
          {!mineOnly && (
            <Grid
              item
              xs={12}
              md={3}
              xl={2}
            >
              <FormControlLabel
                checked={!!unassignedOnly}
                color="primary"
                control={<Checkbox />}
                onChange={toggleUnassignedOnly}
                label={t('Show unassigned only')}
              />
            </Grid>
          )}
        </Grid>
        {user.roles.includes(RoleType.OnlineAdmin) && (
          <Grid
            container
            spacing={1}
            sx={{ mb: 1 }}
          >
            <Grid
              item
              xs={12}
            >
              <Typography fontWeight={500}>
                {t('Table actions')}
                :
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                disabled={!selected.length}
                onClick={() => showReprocessDialog(selected)}
                startIcon={<Refresh />}
              >
                <Typography component="span">{t('Reprocess selected')}</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
        <Scrollbar>
          <Box sx={{ position: 'relative' }}>
            <LeadTable
              leads={leads || []}
              selected={selected}
              setSelected={setSelected}
              showReprocessDialog={showReprocessDialog}
            />
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={(leads?.length && leads?.length >= limit) ? -1 : (page * limit + leads.length)}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Card>
      <LeadsReprocessDialog
        ref={reprocessDialogRef}
        setReprocessResponse={setReprocessResponse}
      />
    </>
  );
};

export default LeadListTable;
