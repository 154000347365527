import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AppState, CreditCardApplicationExtended } from 'src/api/zrm';
import Card from 'src/components/Card';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import { useGetCreditCardApplicationUtils } from 'src/hooks/creditCardHooks/useGetCreditCardApplicationsUtils';
import { parseDate } from 'src/utils/parseDate';

const getStatusLabel = ({ text, color }: { text: string, color: string }): JSX.Element => (
  <Label color={color as any}>
    {text}
  </Label>
);

interface CreditCardTableProps {
  getSearchedApplications?: CreditCardApplicationExtended[];
  isBeingSearched?: boolean;
}

const CreditCardTable: FC<CreditCardTableProps> = (props) => {
  const { getSearchedApplications, isBeingSearched } = props;
  const { t } = useTranslation();

  const { creditCardApplications, page, setPage, limit, setLimit, total, loading } = useGetCreditCardApplicationUtils();

  const handlePageChange = (event: any | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: any): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const substatusMap = useMemo(() => (status: AppState) => {
    switch (status) {
      case AppState.DISBURSED:
        return {
          color: 'success',
          text: t('Disbursed'),
        };
      case AppState.COMPLETE:
        return {
          color: 'info',
          text: t('Complete'),
        };
      case AppState.ACCEPTED:
        return {
          color: 'success',
          text: t('Accepted'),
        };
      case AppState.DRAFT:
        return {
          color: 'warning',
          text: t('Draft'),
        };
      case AppState.OFFERED:
        return {
          color: 'success',
          text: t('Offered'),
        };
      default:
        return {
          color: 'warning',
          text: t(status),
        };
    }
  }, [t]);

  const statusMap = useMemo(() => (status: boolean) => {
    if (status) return {
      color: 'success',
      text: t('Active'),
    };

    return {
      color: 'error',
      text: t('Deactivated'),
    };
  }, [t]);

  return (
    <Card
      title={t('Credit card applications')}
      loading={loading}
    >
      <Scrollbar>
        <Table component="div">
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="span">
                {t('Application ID')}
              </TableCell>

              <TableCell component="span">
                {t('Name')}
              </TableCell>

              <TableCell component="span">
                {t('Created at')}
              </TableCell>

              <TableCell component="span">
                {t('Status')}
              </TableCell>

              <TableCell component="span">
                {t('Substatus')}
              </TableCell>

            </TableRow>
          </TableHead>

          <TableBody component="div">
            {((isBeingSearched && getSearchedApplications) || creditCardApplications)?.map((creditCardApplication) => (
              <TableRow
                hover
                key={creditCardApplication.application_id}
                sx={{ textDecoration: 'none' }}
                component={Link}
                to={`/credit-card-application/_/${creditCardApplication.application_id}`}
              >
                <TableCell component="span">
                  {creditCardApplication.application_id}
                </TableCell>

                <TableCell component="span">
                  {creditCardApplication?.applicant?.fullname}
                </TableCell>

                <TableCell component="span">
                  {parseDate(creditCardApplication?.created_at).format('LLL') || '-'}
                </TableCell>

                <TableCell component="span">
                  {getStatusLabel(substatusMap(creditCardApplication.state))}
                </TableCell>

                <TableCell component="span">
                  {getStatusLabel(statusMap(creditCardApplication.active))}
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>

      {!isBeingSearched && (
        <Box sx={{ float: 'right', marginY: '1rem' }}>
          <TablePagination
            component="div"
            count={total}
            onPageChange={(e, np) => handlePageChange(e, np + 1)}
            onRowsPerPageChange={handleLimitChange}
            page={page - 1}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </Box>
      )}
    </Card>
  );
};

export default CreditCardTable;
