import { FC } from 'react';

import { useTheme } from '@material-ui/core';

const LoginBoxLogoNorway: FC = () => {
  const theme = useTheme();

  return (
    <img
      src={theme.palette.mode === 'light' ? '/static/BankID-main-logo-purple.svg' : '/static/BankID-main-logo-white.svg'}
      height="30"
      width="auto"
      alt="BankID"
    />
  );
};

export default LoginBoxLogoNorway;
