import { FC, useMemo, useState } from 'react';

import { ApplicantNO, ApplicantSE } from 'src/api/zrm';
import useSettings from 'src/hooks/useSettings';

import CustomerDataContext, { CustomerDataProps } from './CustomerDataContext';

export const UnauthenticatedCustomerDataProvider: FC = (props) => {
  const { children } = props;
  const { country } = useSettings();

  const [customerData, setCustomerData] = useState<ApplicantNO | ApplicantSE>(null);

  const value: CustomerDataProps = useMemo(() => {
    const hasGRConsent = false;
    const hasOldGRConsent = false;
    const hasRecentGRData = false;
    const lastExternallyVerifiedCredits: Date = null;
    const externallyVerifiedCredits: ApplicantNO['externally_verified_credits'] = [];

    return ({
      getData: () => {},
      dataLoading: false,
      dataLoaded: !!customerData,
      data: customerData,
      lastExternallyVerifiedCredits,
      externallyVerifiedCredits,
      hasRecentGRData,
      hasGRConsent,
      hasOldGRConsent,
      fetchGRData: () => {},
      grDataLoading: false,
      consentGR: () => {},
      consentGRLoading: false,
      checkGRConsent: () => {},
      grDataFade: false,
      idCheck: () => {},
      setData: setCustomerData,
    });
  }, [customerData, country]);

  return (
    <CustomerDataContext.Provider value={value}>
      {children}
    </CustomerDataContext.Provider>
  );
};
