export default class MemoryStorage {
  private storage: {};

  constructor() {
    this.storage = {};
  }

  getItem(key) {
    // eslint-disable-next-line no-prototype-builtins
    return this.storage.hasOwnProperty(key) ? this.storage[key] : null;
  }

  setItem(key, value) {
    return this.storage[key] = String(value);
  }

  removeItem(key) {
    return delete this.storage[key];
  }

  clear() {
    return this.storage = {};
  }
}
