import { FC } from 'react';
import { Link } from 'react-router-dom';

import { AppBar, Box, Container, IconButton, Toolbar, experimentalStyled } from '@material-ui/core';

import Logo from 'src/components/Logo';
import MenuIcon from 'src/icons/Menu';

interface CustomerPagesNavbarProps {
  openSidebar: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {

      backgroundColor: theme.palette.background.paper,
      color: theme.palette.mode === 'light' ? '#000' : theme.palette.text.primary,
      borderBottom: `2px solid ${theme.palette.mode === 'light' ? '#888C92' : '#eee'}`,
      boxShadow: 'none',
      zIndex: theme.zIndex.drawer + 100,
    }
  ),
);

const CustomerPagesNavbar: FC<CustomerPagesNavbarProps> = (props) => {
  const { openSidebar } = props;

  return (
    <DashboardNavbarRoot position="static">
      <Container
        maxWidth="lg"
        sx={{
          px: { xs: 1 },
        }}
      >
        <Toolbar
          sx={{
            minHeight: 64,
            flexDirection: 'row',
            px: { xs: 0 },
          }}
        >
          <Link to="/">
            <Logo
              sx={{
                height: 40,
                width: 100,
              }}
            />
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            onClick={openSidebar}
            sx={{ px: { xs: 1, lg: 0 } }}
            color="inherit"
          >
            <MenuIcon
              fontSize="small"
            />
          </IconButton>
        </Toolbar>
      </Container>
    </DashboardNavbarRoot>
  );
};

export default CustomerPagesNavbar;
