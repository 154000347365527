import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { isBrowser } from 'react-device-detect';
import { v4 } from 'uuid';

import { HTTPValidationError, Product, RoutersBankIdLoginPayloadBankIDResp } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useSettings from 'src/hooks/useSettings';
import logger from 'src/utils/logger';

export interface CustomerLoginContextProps {
  loginNo: () => void;
  loginSe: (sameDevice: boolean) => void;
  qrCode?: string;
  qrCodeError?: HTTPValidationError;
  verified?: boolean;
}

const CustomerLoginContext = createContext<CustomerLoginContextProps>(null);

interface CustomerLoginProviderProps {
  redirectTo?: string;
}

export const CustomerLoginProvider: FC<CustomerLoginProviderProps> = (props) => {
  const { redirectTo, children } = props;

  const { api } = useApi();
  const { country } = useSettings();

  const [seLoginResponse, setSeLoginResponse] = useState<RoutersBankIdLoginPayloadBankIDResp>(null);
  const [seQrCodeResponse, setSeQrCodeResponse] = useState<{ qr_code_content: string; verified: boolean }>(null);
  const [seQrCodeError, setSeQrCodeError] = useState<HTTPValidationError>(null);

  const loginNo = useMemo(() => async () => {
    const requestId = v4();
    const url = new URL(window.location.href);
    url.searchParams.delete('login-redirect');

    const failUrl = `${url.origin}/welcome-back`;

    try {
      logger.log({ country, callback_url: url.href, callback_fail_url: failUrl, product: Product.CreditCard, url }, { source: 'CustomerLoginProvider', description: 'Login with BankID data for Norway', requestId });

      const resp = await api.bankId.loginBankIdAuthPost(
        { country, callback_url: url.href, callback_fail_url: failUrl, product: Product.CreditCard },
        { headers: { Authorization: null, 'X-Request-ID': requestId }, cancelToken: requestId },
      );

      window.location.replace(resp.data.login_url);
    } catch (e) {
      if (e?.name === 'AbortError') return;

      logger.error(e, { source: 'CustomerLoginProvider', description: 'Login with BankID Norway', requestId });
    }
  }, [api, country]);

  const loginSe = useMemo(() => async (sameDevice = false) => {
    unstable_batchedUpdates(() => {
      setSeLoginResponse(null);
      setSeQrCodeResponse(null);
      setSeQrCodeError(null);
    });
    const requestId = v4();
    const url = new URL(window.location.href);
    url.searchParams.delete('login-redirect');

    const failUrl = `${url.origin}/welcome-back`;

    const callbackUrl = redirectTo ? `${url.origin}${redirectTo}` : url.origin;

    try {
      logger.log({ country, callback_url: url.href, callback_fail_url: failUrl, product: Product.Blanco, url }, { source: 'CustomerLoginProvider', description: 'Login with BankID data for Sweden', requestId });

      const resp = await api.bankId.loginBankIdAuthPost(
        { country, callback_url: callbackUrl, callback_fail_url: failUrl, product: Product.Blanco },
        { headers: { Authorization: null, 'X-Request-ID': requestId }, cancelToken: requestId },
      );

      if (sameDevice) {
        let autostart = resp.data.autostart_uri;

        if (isBrowser) autostart = resp.data.desktop_autostart_uri;

        window.location.replace(autostart);
      } else unstable_batchedUpdates(() => {
        setSeLoginResponse(resp.data);
        setSeQrCodeResponse({ qr_code_content: resp.data.qr_code_content, verified: false });
      });
    } catch (e) {
      if (e?.name === 'AbortError') return;

      logger.error(e, { source: 'CustomerLoginProvider', description: 'Login with BankID Sweden', requestId });
    }
  }, [api, country, isBrowser, redirectTo]);

  const refreshQrCode = useMemo(() => async () => {
    const requestId = v4();

    try {
      const resp = await api.bankId.qrCodeBankIdQrCodeGet(
        { ticket_id: seLoginResponse.ticket_id },
        { headers: { Authorization: null, 'X-Request-ID': requestId }, cancelToken: requestId },
      );

      setSeQrCodeResponse(resp.data);
    } catch (e) {
      if (e?.name === 'AbortError') return;

      setSeQrCodeError(e.error);
      logger.error(e, { source: 'CustomerLoginProvider', description: 'Refresh QR Code', requestId });
    }
  }, [api, seLoginResponse]);

  const redirectDesktopUser = useMemo(() => async () => {
    window.location.replace(`${process.env.REACT_APP_ZRM_URL}/bank-id/redirect?ticket_id=${seLoginResponse.ticket_id}`);
  }, [api, seLoginResponse]);

  useEffect(() => {
    if (seQrCodeResponse?.verified) {
      redirectDesktopUser();

      return () => {};
    }

    if (!seQrCodeResponse?.qr_code_content || seQrCodeError) return () => {};

    const t = setTimeout(() => {
      refreshQrCode();
    }, 1000);

    return () => clearTimeout(t);
  }, [seQrCodeResponse]);

  const value: CustomerLoginContextProps = useMemo(() => ({
    loginNo,
    loginSe,
    qrCode: seQrCodeResponse?.qr_code_content,
    qrCodeError: seQrCodeError,
    verified: seQrCodeResponse?.verified,
  }), [loginNo, loginSe, seQrCodeResponse?.qr_code_content, seQrCodeError, seQrCodeResponse?.verified]);

  return (
    <CustomerLoginContext.Provider value={value}>
      {children}
    </CustomerLoginContext.Provider>
  );
};

export const useCustomerLogin = (): CustomerLoginContextProps => useContext(CustomerLoginContext);

export default CustomerLoginContext;
