import { useMemo, FC } from 'react';

import {
  Backdrop,
  CircularProgress,
  useTheme,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

import FridayLogo from 'src/components/FridayLogo';
import Logo from 'src/components/Logo';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import useAuth from 'src/hooks/useAuth';

interface LoadingOverlayProps {
  zIndex?: number
}

const LoadingOverlay: FC<LoadingOverlayProps> = (props) => {
  const { zIndex } = props;

  const theme = useTheme();
  const { platform } = useAuth();

  const isZrmUser = useMemo(() => platform === AuthenticationPlatform.Auth0, [platform]);

  return (
    <Backdrop
      open
      sx={{
        position: 'absolute',
        backgroundColor: alpha(theme.palette.mode === 'light' ? '#9e9e9e' : '#212121', 0.5),
        zIndex,
      }}
    >
      {(new Date().getDay() === 5 && new Date().getHours() >= 15 && isZrmUser) ? <FridayLogo /> : (
        <Logo
          sx={{
            height: 120,
            width: 120,
          }}
        />
      )}
      <h2> &nbsp;·&nbsp; </h2>
      <CircularProgress />
    </Backdrop>
  );
};

LoadingOverlay.defaultProps = {
  zIndex: 999,
};

export default LoadingOverlay;
