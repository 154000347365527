import type { FC } from 'react';
import { useRef, useState } from 'react';

import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import CenteredTooltip from 'src/components/widgets/info/CenteredTooltip';

const languageOptions = {
  en: {
    icon: '/static/icons/uk_flag.svg',
    label: 'English',
  },
  sv: {
    icon: '/static/icons/se_flag.svg',
    label: 'Svenska',
  },
  nb: {
    icon: '/static/icons/no_flag.svg',
    label: 'Norsk',
  },
};

export enum LanguagePopoverSize {
  SMALL = 16,
  LARGE = 20,
}

interface LanguagePopoverProps {
  size?: LanguagePopoverSize;
  arrowColor?: string;
}

const LanguagePopover: FC<LanguagePopoverProps> = (props) => {
  const { size = LanguagePopoverSize.LARGE, arrowColor = 'white' } = props;

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: string): void => {
    i18n.changeLanguage(language);
    moment.locale(language);
    document.documentElement.setAttribute('lang', language);
    setOpen(false);
  };

  const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <CenteredTooltip title={t('Change language')}>
        <Button
          onClick={handleOpen}
          ref={anchorRef}
          sx={{ p: 0, minWidth: 32 }}
        >
          <Box
            sx={{
              display: 'flex',
              height: size,
              width: size,
              '& img': {
                width: '100%',
              },
            }}
          >
            <img
              alt={selectedOption?.label}
              aria-label={selectedOption?.label}
              src={selectedOption?.icon}
              width={size}
              height={size}
            />
          </Box>
          <ArrowDropDown htmlColor={arrowColor} />
        </Button>
      </CenteredTooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  height: 20,
                  width: 20,
                  '& img': {
                    width: '100%',
                  },
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                  aria-label={languageOptions[language].label}
                  width={20}
                  height={20}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {languageOptions[language].label}
                </Typography>
              )}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
