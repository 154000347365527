import { ChangeEvent, FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { BankBaseApp } from 'src/api/zrm';
import { ApplicantRole, BankStatus } from 'src/api/zrm/zrmApi';
import Card from 'src/components/Card';
import MortgageListFilters, { initialValues as initialFiltersValue, MortgageListFiltersType } from 'src/components/mortgage/filters/MortgageListFilters';
import Scrollbar from 'src/components/Scrollbar';
import useApi from 'src/hooks/useApi';
import useQuery from 'src/hooks/useQuery';
import useSettings from 'src/hooks/useSettings';
import useUtils from 'src/hooks/useUtils';
import BankStatusLabel from 'src/pages/mortgage_bank/utils/BankStatusLabel';
import { Stage } from 'src/types/stage';
import BreadcrumbNavigation from 'src/utils/BreadcrumbNavigation';
import logger from 'src/utils/logger';

export const ApplicationTable: FC<{ applications: Array<BankBaseApp> }> = (props) => {
  const { applications } = props;
  const { t } = useTranslation();
  const { formatCurrency, getCurrency } = useUtils();

  return (
    <Table
      component="div"
    >
      <TableHead component="div">
        <TableRow component="div">
          <TableCell component="span">
            {t('Application ID')}
          </TableCell>
          <TableCell component="span">
            {t('Applicants')}
          </TableCell>
          <TableCell component="span">
            {t('Total')}
          </TableCell>
          <TableCell component="span">
            {t('Status')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody component="div">
        {applications?.map((application) => (
          <TableRow
            hover
            key={application.application_id}
            selected={false}
            to={`/bank/mortgage/_application/${application.application_id}`}
            component={RouterLink}
            sx={{ textDecoration: 'none' }}
          >
            <TableCell component="span">
              <Typography />
              {application.application_id}
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {format(moment.utc(application.date_created).toDate(), 'dd MMM yyyy · HH:mm')}
              </Typography>
            </TableCell>
            <TableCell component="span">
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {application.applicants.reduce((c, a) => a.role === ApplicantRole.MAIN_APPLICANT ? `${a.customer.first_name} ${a.customer.last_name}` : c, null)}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {application.applicants.reduce((c, a) => a.role === ApplicantRole.CO_APPLICANT ? `${a.customer.first_name} ${a.customer.last_name}` : c, null)}
              </Typography>
            </TableCell>
            <TableCell component="span">
              {application.loan?.mortgage_loan_amount ? formatCurrency(application.loan?.mortgage_loan_amount, getCurrency(application.country), 0) : '-'}
            </TableCell>
            <TableCell component="span">
              <BankStatusLabel status={application.bank_status} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const ApplicationList: FC = () => {
  const { settings, product, country } = useSettings();
  const { t } = useTranslation();
  const [page, setPage] = useQuery('page', 0);
  const [limit, setLimit] = useQuery('limit', 25);
  const { api } = useApi();

  const [data, setData] = useState<{ applications: Array<BankBaseApp>, stage: Stage }>({ applications: null, stage: null });
  const [filters, setFilters] = useState<MortgageListFiltersType>(initialFiltersValue);

  const loading = useMemo(() => data.stage === Stage.pending, [data.stage]);
  const fetchError = useMemo(() => data.stage === Stage.rejected, [data.stage]);
  const statusFilterOptions = useMemo(() => Object.keys(BankStatus), []);

  const handlePageChange = useMemo(() => (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  }, [setPage]);

  const handleLimitChange = useMemo(() => (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  }, [setLimit]);

  useEffect(() => {
    const requestId = uuid();
    setData((p) => ({ ...p, stage: Stage.pending }));
    (async () => {
      try {
        const resp = await api.bank.getApplicationsBankApplicationsPost(
          { limit, page, product, country, bank_statuses: filters.statuses },
          { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
        );
        setData((p) => ({ ...p, stage: Stage.fulfilled, applications: resp.data }));
      } catch (e) {
        if (e?.name === 'AbortError') return;

        logger.error(e, { source: 'MortgageList - Bank', description: 'Get applications', requestId });
        setData((p) => ({ ...p, applications: null, stage: Stage.rejected }));
      }
    })();

    return () => { api.abortRequest(requestId); };
  }, [limit, page, api, product, country, filters?.statuses]);

  return (
    <>
      <Helmet>
        <title>{t('Mortgage List')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <BreadcrumbNavigation
                currentPageName={t('Mortgages')}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, position: 'relative', overflow: 'hidden' }}>
            <Card
              loading={loading}
              title={t('Applications')}
            >
              <Box sx={{ marginBottom: 3 }}>
                <MortgageListFilters
                  handleFiltersChange={setFilters}
                  statusOptions={statusFilterOptions}
                />
              </Box>
              <Scrollbar>
                <Box sx={{ position: 'relative' }}>
                  <ApplicationTable applications={data.applications} />
                </Box>
              </Scrollbar>
              <TablePagination
                component="div"
                count={(data.applications?.length && data.applications?.length < limit) ? (page * limit + data.applications?.length) : -1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 50]}
              />
            </Card>
          </Box>
          {fetchError && (
            <Box sx={{ mt: 3 }}>
              <Alert severity="error">
                <AlertTitle>{t('Error')}</AlertTitle>
                {t('Failed to fetch applications.')}
              </Alert>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default ApplicationList;
