import { FC, KeyboardEvent, useEffect, useMemo } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Product, RoleType, ZDepartment } from 'src/api/zrm';
import CreditCardTable from 'src/components/creditCardApplication/CreditCardTable';
import CustomerTable from 'src/components/customers/CustomerTable';
import CustomerSearchBoxSelector from 'src/components/customers/search/CustomerSearchBoxSelector';
import InsuranceTable from 'src/components/insurance/InsuranceTable';
import { LeadTable } from 'src/components/leads/LeadListTable';
import { ApplicationTable } from 'src/components/mortgage/ApplicationListTable';
import Scrollbar from 'src/components/Scrollbar';
import { WhatToSearch } from 'src/contexts/SearchContext';
import useSearcher from 'src/hooks/useSearcher';
import useSettings from 'src/hooks/useSettings';
import useUser from 'src/hooks/useUser';
import SearchIcon from 'src/icons/Search';
import XIcon from 'src/icons/X';
import UserTable from 'src/pages/internalUser/UserTable';
import { ApplicationTable as BankApplicationTable } from 'src/pages/mortgage_bank/MortgageList';
import { ZensUser } from 'src/types/auth';
import { Stage } from 'src/types/stage';
import { parseZoordinates, productToZProduct } from 'src/utils/zoordinates';

enum RolesCondition {
  ALL, ANY
}

const ContentSearch: FC = () => {
  const { value, setValue, open, setOpen, result, whatToSearch, setWhatToSearch, execute, isBank, kdltSearchBy, changeKdltSearchKey } = useSearcher();
  const { t } = useTranslation();
  const location = useLocation();
  const { roles } = useUser() as ZensUser;
  const { settings } = useSettings();

  const tabs = useMemo(() => {
    const zoordinates = settings?.zoordinates ? parseZoordinates(settings.zoordinates) : null;
    const allTabs = [
      {
        name: t('Mortgages'),
        key: WhatToSearch.mortgages,
        zoordinates: [{ Product: productToZProduct(Product.Mortgage) }],
      },
      {
        name: t('Mortgages'),
        key: WhatToSearch.bank_mortgages,
        bank: true },
      {
        name: t('Leads'),
        key: WhatToSearch.leads,
        zoordinates: [{ Product: productToZProduct(Product.Mortgage) }],
      },
      {
        name: t('Credit Card Applications'),
        key: WhatToSearch.creditCardApplications,
        zoordinates: [{ Product: productToZProduct(Product.CreditCard) }],
      },
      {
        name: t('Insurances'),
        key: WhatToSearch.insurances,
        zoordinates: [{ Department: ZDepartment.KH }],
      },
      {
        name: t('Internal Users'),
        key: WhatToSearch.internal_users,
        roles: [RoleType.EmployeeAdmin],
        rolesNeeded: RolesCondition.ALL,
      },
      {
        name: t('Customers'),
        key: WhatToSearch.customers,
        roles: [
          RoleType.KdltAdmin, RoleType.MortgageAdmin, RoleType.ImpersonateCustomer, RoleType.Admin,
          RoleType.Developer, RoleType.MbAdmin, RoleType.DataAdmin, RoleType.AffiliateAdmin,
          RoleType.DialmakerAdmin,
        ],
        rolesNeeded: RolesCondition.ANY,
      },
    ];

    return allTabs.filter((tab) => {
      if (tab.bank) return isBank;
      if (tab.zoordinates && !zoordinates) return false;
      if (tab.zoordinates && !tab.zoordinates.some((z) => {
        if (z.Product && zoordinates.Product !== z.Product) return false;
        if (z.Department && zoordinates.Department !== z.Department) return false;
        // @ts-ignore
        if (z.Country && zoordinates.Country !== z.Country) return false;

        return true;
      })) return false;
      if (tab.roles && !roles) return false;
      if (tab.roles && roles.includes(RoleType.Admin)) return true;
      if (tab.roles && tab.rolesNeeded === RolesCondition.ANY && !tab.roles.some((role) => roles?.includes(role))) return false;
      if (tab.roles && tab.rolesNeeded === RolesCondition.ALL && !tab.roles.every((role) => roles?.includes(role))) return false;

      return true;
    });
  }, [t, settings?.zoordinates, roles, isBank]);

  useEffect(() => {
    if (tabs.length) setWhatToSearch((p) => tabs.map((tab) => tab?.key).includes(p) ? p : tabs[0].key);
  }, [tabs]);

  const handleOpen = (): void => {
    setOpen((p) => p || true);
  };

  const handleClose = (): void => {
    setOpen((p) => p ? false : p);
  };

  useEffect(() => {
    handleClose();
  }, [location]);

  const handleKeyUp = useMemo(() => (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code?.toUpperCase() === 'ENTER') execute();
  }, [execute]);

  return (
    <>
      <Tooltip title={t('Search')}>
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {open && (
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: '100%' },
        }}
        variant="temporary"
      >
        <Box sx={{ mt: 1, mr: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={handleClose}>
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Tabs
          indicatorColor="primary"
          centered
          scrollButtons="auto"
          textColor="primary"
          value={whatToSearch}
          onChange={(_e, v: WhatToSearch) => setWhatToSearch((p) => p !== v ? v : p)}
        >
          {tabs
            .map((tab) => (
              <Tab
                key={tab.key}
                label={tab.name}
                value={tab.key}
              />
            ))}
        </Tabs>
        <Divider />
        <Box sx={{ pt: 1 }}>
          {tabs.length ? (
            <Container maxWidth="lg">
              <Grid
                container
                sx={{ justifyContent: 'center' }}
              >
                <Grid
                  item
                  xs={10}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event): void => setValue(event.target.value)}
                    onKeyUp={handleKeyUp}
                    placeholder={`${t('Search')}...`}
                    value={value}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Button
                    color="primary"
                    onClick={execute}
                    size="large"
                    sx={{ ml: 2 }}
                    variant="contained"
                  >
                    {t('Search')}
                  </Button>
                </Grid>
                {whatToSearch === WhatToSearch.customers && (
                  <>
                    <Grid
                      item
                      xs={10}
                    >
                      <CustomerSearchBoxSelector
                        value={kdltSearchBy}
                        handleChange={changeKdltSearchKey}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                    />
                  </>
                )}
              </Grid>
              <Box sx={{ mt: 1 }}>
                <Scrollbar options={{ suppressScrollX: true }}>
                  {
                    result.stage === Stage.pending
                      ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )
                      : null
                  }
                  {result.stage === Stage.fulfilled && (
                  <>
                    {isBank && (
                      <>
                        {whatToSearch === WhatToSearch.bank_mortgages && (
                        <BankApplicationTable applications={result.results[whatToSearch]} />
                        )}
                      </>
                    )}
                    {!isBank && (
                      <>
                        {whatToSearch === WhatToSearch.mortgages && (
                        <ApplicationTable applications={result.results[whatToSearch]} />
                        )}
                        {whatToSearch === WhatToSearch.internal_users && (
                          <UserTable users={result.results[whatToSearch]} />
                        )}
                        {whatToSearch === WhatToSearch.insurances && (
                          <InsuranceTable insurances={result.results[whatToSearch]} />
                        )}
                        {whatToSearch === WhatToSearch.leads && (
                          <LeadTable
                            leads={result.results[whatToSearch]}
                            forceHideReprocess
                          />
                        )}
                        {whatToSearch === WhatToSearch.customers && (
                          <CustomerTable customers={result.results[whatToSearch]} />
                        )}
                        {whatToSearch === WhatToSearch.creditCardApplications && (
                        <CreditCardTable
                          isBeingSearched
                          getSearchedApplications={result.results[whatToSearch]}
                        />
                        )}
                      </>
                    )}
                  </>
                  )}
                </Scrollbar>
              </Box>
            </Container>
          ) : (
            <Typography sx={{ textAlign: 'center' }}>{t('You have no permissions needed to use any search option')}</Typography>
          )}
        </Box>
      </Drawer>
      )}
    </>
  );
};

export default ContentSearch;
