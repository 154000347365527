import { createTheme } from '@material-ui/core/styles';

import type { ThemeConfig } from 'src/types/themeConfig';
import { merge } from 'src/utils/lodashKiller';

import { composeTheme } from './themes/base';
import customerPages from './themes/customerPages';

import type { Theme } from '@material-ui/core';

declare module '@material-ui/core/Button' {
  interface ButtonPropsColorOverrides {
    success: true;
    error: true;
    warning: true;
    info: true;
    action: true;
  }
}

declare module '@material-ui/core/AppBar' {
  interface AppBarPropsColorOverrides {
    success: true;
    error: true;
    warning: true;
    info: true;
    action: true;
  }
}

declare module '@material-ui/core/Chip' {
  interface ChipPropsColorOverrides {
    success: true;
    error: true;
    warning: true;
    info: true;
    action: true;
  }
}

export const createCustomTheme = (config: ThemeConfig = {}, themeExtension: string = ''): Theme => {
  let theme = createTheme(
    composeTheme(config),
  );

  let themeExtensionObject = null;
  switch (themeExtension) {
    case 'customerPages':
      themeExtensionObject = customerPages;
      break;
    default:
      break;
  }

  if (themeExtensionObject) {
    theme = merge(theme, themeExtensionObject.ALL);

    if ((config?.theme) && themeExtensionObject[config.theme]) theme = merge(theme, customerPages[config.theme]);
  }

  return theme;
};
