import { Dispatch, forwardRef, SetStateAction, useImperativeHandle, useMemo, useState } from 'react';

import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { HttpErrorResponse, HTTPValidationError, ReprocessResult, ReprocessStatus } from 'src/api/zrm';
import ErrorBoxComponent from 'src/components/ErrorBox';
import useApi from 'src/hooks/useApi';
import logger from 'src/utils/logger';

import Label from '../Label';

interface LeadsReprocessDialogProps {
  setReprocessResponse: Dispatch<SetStateAction<ReprocessResult[]>>;
}

interface LeadsReprocessDialogData {
  open: (ids: string[]) => void;
}

const LeadsReprocessDialog = forwardRef<LeadsReprocessDialogData, LeadsReprocessDialogProps>((props, ref) => {
  const { setReprocessResponse } = props;

  const { t } = useTranslation();
  const { api } = useApi();

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leadIds, setLeadIds] = useState<string[]>([]);

  const [successResponse, setSuccessResponse] = useState<ReprocessResult[]>(null);
  const [error, setError] = useState<HttpErrorResponse<HTTPValidationError>>(null);

  useImperativeHandle(ref, () => ({
    open: (ids: string[]) => {
      setOpened(true);
      setLeadIds(ids);
      setSuccessResponse(null);
    },
  }), []);

  const close = useMemo(() => () => {
    setOpened(false);
    setLeadIds([]);
    setSuccessResponse(null);
  }, []);

  const reprocessLeads = useMemo(() => async () => {
    const requestId = uuid();
    setLoading(true);
    setError(null);

    try {
      const resp = await api.lead.reprocessSubmissionsLeadReprocessSubmissionsPost(
        { lead_ids: leadIds }, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
      );

      setSuccessResponse(resp.data);
      setReprocessResponse(resp.data);
    } catch (e) {
      logger.error(e, { source: 'LeadHelpersContext', description: 'Reprocess lead', requestId, leadIds });
      setError(e);
    }

    setLoading(false);
  }, [leadIds]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={opened}
      onClose={close}
    >
      <DialogTitle>
        {t('Reprocess Leads')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', alignItems: loading ? 'center' : 'flex-start', minHeight: 60 }}>
        {loading && (
          <CircularProgress />
        )}
        {!loading && error && (
          <ErrorBoxComponent
            errorMessage={`${t('Problem with reprocessing lead')}: ${error.error?.detail?.toString()}`}
            serverErrorResponse={error}
          />
        )}
        {!loading && !error && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {successResponse ? (
              <>
                <Alert
                  severity="info"
                  sx={{ mb: 2, width: '100%' }}
                >
                  <AlertTitle>{t('Reprocess finished')}</AlertTitle>
                  {t('Check data below to see if reprocessing went successfully')}
                </Alert>
                {successResponse.map((reprocessInfo) => (
                  <ListItem sx={{ p: 0.5, alignItems: 'flex-start' }}>
                    <ListItemIcon sx={{ mr: 1, mt: 0.25 }}>
                      <FiberManualRecord fontSize="small" />
                    </ListItemIcon>
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                        <Typography>
                          {t('Lead')}
                          {' '}
                          {reprocessInfo.lead_id}
                        </Typography>
                        <Label color={reprocessInfo.status === ReprocessStatus.Success ? 'primary' : 'error'}>
                          <Typography fontSize="0.5rem">{reprocessInfo.status}</Typography>
                        </Label>
                      </Box>
                      {reprocessInfo.error && (
                        <Alert
                          severity="error"
                          sx={{ my: 1 }}
                        >
                          {reprocessInfo.error}
                        </Alert>
                      )}
                    </Box>

                  </ListItem>
                ))}
              </>
            ) : (
              <>
                <Typography fontWeight={500}>
                  {t('List of selected leads to reprocess')}
                  :
                </Typography>
                <List>
                  {leadIds.map((leadId) => (
                    <ListItem sx={{ p: 0.5 }}>
                      <ListItemIcon sx={{ mr: 1 }}>
                        <FiberManualRecord fontSize="small" />
                      </ListItemIcon>
                      <Typography>
                        {t('Lead')}
                        {' '}
                        {leadId}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: !successResponse ? 'space-between' : 'flex-end', paddingX: 2 }}>
        {!successResponse && (<Button onClick={reprocessLeads}>{t('Reprocess leads')}</Button>)}
        <Button onClick={close}>{t('Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
});

export default LeadsReprocessDialog;
