import type { FC } from 'react';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { BankStatus } from 'src/api/zrm/zrmApi';
import Label, { LabelColor } from 'src/components/Label';

const BankStatusLabel: FC<{ status: BankStatus }> = (props) => {
  const { status } = props;
  const { t } = useTranslation();

  const labelProps = useMemo((): { color: LabelColor, text: string } => {
    switch (status) {
      case BankStatus.NEW:
        return {
          color: 'warning',
          text: t('New'),
        };
      case BankStatus.ONGOING:
        return {
          color: 'info',
          text: t('Ongoing'),
        };
      case BankStatus.ACCEPTED:
        return {
          color: 'success',
          text: t('Accepted'),
        };
      case BankStatus.DECLINED:
        return {
          color: 'error',
          text: t('Declined'),
        };
      case BankStatus.DISBURSED:
        return {
          color: 'primary',
          text: t('Disbursed'),
        };
      default:
        return {
          color: 'warning',
          text: t(status),
        };
    }
  }, [status, t]);

  if (!status) return null;

  return (
    <Label color={labelProps.color}>
      {labelProps.text}
    </Label>
  );
};
export default BankStatusLabel;
