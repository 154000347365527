import { Income } from 'src/api/zrm';

export const INCOME_AMOUNT_SE_KEY = 'amount';
export const INCOME_AMOUNT_NO_KEY = 'yearly_amount';

const formatIncomes = (incomes: Income[], isSweden: boolean): Income[] => {
  if (isSweden) return incomes?.map((income) => ({ ...income, yearly_amount: 0 }));

  return incomes?.map((income) => {
    if (!income.yearly_amount) return income;

    return { ...income, amount: 0 };
  });
};

// Support to existing incomes in NO (they have amount, but not yearly_amount set)
export const getUserFriendlyIncomeAmount = (value: number, income: Income, isCountrySweden: boolean): number => isCountrySweden ? value : value || income.amount * 12;

export default formatIncomes;
