import { FC, memo } from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Label from 'src/components/Label';

interface HandledLabelProps {
  handled: boolean;
}

const HandledLabel: FC<HandledLabelProps> = (props) => {
  const { handled } = props;

  const { t } = useTranslation();

  return (
    <Label
      color={handled ? 'success' : 'info'}
    >
      <Typography fontSize="0.7rem">
        {t(handled ? 'Handled ' : 'Not handled yet')}
      </Typography>
    </Label>
  );
};

export default memo(HandledLabel);
