import { FC, useEffect, useMemo } from 'react';

import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ErrorBoxComponent from 'src/components/ErrorBox';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import { useIframeUtils } from 'src/contexts/IframeUtilsContext';
import LoginBoxNo from 'src/customerPages/components/auth/LoginBoxNo';
import { useCustomerLogin } from 'src/customerPages/context/login/CustomerLoginContext';
import PageHeightOffsets from 'src/customerPages/utils/iframe/pageHeightOffsets';
import useAuth from 'src/hooks/useAuth';
import useQuery from 'src/hooks/useQuery';
import { externalAuthPlatforms } from 'src/utils/auth/authPlatforms';

import LoginHeader from '../../layout/auth/LoginHeader';

const CustomerLoginPageNo: FC<{ autoLogin: boolean }> = (props) => {
  const { autoLogin = false } = props;
  const { t } = useTranslation();
  const { platform, completeState, changePlatform, setImpersonationToken } = useAuth();
  const { heightOffset } = useIframeUtils();
  const { loginNo } = useCustomerLogin();

  const [queryToken] = useQuery('access_token', '');

  if (autoLogin) loginNo();

  useEffect(() => {
    if (queryToken) setImpersonationToken(queryToken);
  }, [queryToken, setImpersonationToken]);

  useEffect(() => {
    if (!externalAuthPlatforms.includes(platform)) changePlatform(AuthenticationPlatform.BankIdNo);
  }, []);

  useEffect(() => {
    heightOffset.current = PageHeightOffsets.login;
  }, []);

  const isGr = useMemo(() => window?.location?.pathname === '/gjeld', [window?.location?.pathname]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', height: '100%', p: 0 }}
    >
      <LoginHeader />
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', p: 0 }}
      >
        <Box sx={{ width: '500px', maxWidth: '90%' }}>
          <LoginBoxNo isGr={isGr} />
          {completeState === 'timeout' && (
            <ErrorBoxComponent
              severity="warning"
              errorMessage={t('You were logged out. Please log in again.')}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerLoginPageNo;
