import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Config, ValueListItem } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { externalAuthPlatforms } from 'src/utils/auth/authPlatforms';
import logger from 'src/utils/logger';

export interface SingleValueListItem {
  value: string;
  label: string;
}

type OmitConfig = Omit<Config, 'opening_hours' | 'customer_support_phone' | 'customer_support_sms'>;

interface CountryConfig extends OmitConfig {
  opening_hours: SingleValueListItem[];
  customer_support_phone: SingleValueListItem;
  customer_support_sms: SingleValueListItem;
}

export interface CustomerZMSContextProps {
  countryConfig: CountryConfig;
  loading: boolean;
  getZmsValueByKey: (key: keyof CountryConfig) => string | SingleValueListItem | SingleValueListItem[];
}

const CustomerZMSContext = createContext<CustomerZMSContextProps>({ countryConfig: {} as any, loading: false, getZmsValueByKey: () => '' });

const CustomerZMSContextProvider: FC = (props) => {
  const { children } = props;
  const { country } = useSettings();
  const { api } = useApi();
  const { isAuthenticated, platform } = useAuth();
  const { i18n } = useTranslation();

  const [zmsConfig, setZmsConfig] = useState<Config>(null);
  const [loading, setLoading] = useState(false);

  const getCountryData = useMemo(() => (data: ValueListItem) => {
    if (!data) return { value: '', label: '' };

    switch (i18n.language) {
      case 'sv':
        return { value: data.value_sv, label: data.label_sv };
      case 'nb':
        return { value: data.value_nb, label: data.label_nb };
      default:
        return { value: data.value_en, label: data.label_en };
    }
  }, [i18n.language]);

  const countryConfig = useMemo((): CountryConfig => ({
    ...zmsConfig,
    opening_hours: zmsConfig?.opening_hours.map((h) => getCountryData(h)),
    customer_support_phone: getCountryData(zmsConfig?.customer_support_phone),
    customer_support_sms: getCountryData(zmsConfig?.customer_support_sms),
  }), [zmsConfig, getCountryData]);

  const getZmsValueByKey = useMemo(() => (key: keyof CountryConfig) => countryConfig[key], [countryConfig]);

  useEffect(() => {
    if (!country || !externalAuthPlatforms.includes(platform)) return () => {};

    const requestId = uuid();
    setLoading(true);

    (async () => {
      try {
        const resp = await api.zms.getZmsConfigZmsCountryGet(
          country, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
        );

        setZmsConfig(resp.data.config);
      } catch (error) {
        if (error?.name === 'AbortError') return;

        logger.error(error, {
          source: 'CustomerZMSContext',
          description: 'Get ZMS data',
          requestId,
        });
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      api.abortRequest(requestId);
    };
  }, [isAuthenticated, country, api, platform]);

  return (
    <CustomerZMSContext.Provider
      value={{
        countryConfig, getZmsValueByKey, loading,
      }}
    >
      {children}
    </CustomerZMSContext.Provider>
  );
};

const useCustomerZMSContext = () => useContext(CustomerZMSContext);

export { CustomerZMSContextProvider, useCustomerZMSContext };
