import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Container, Grid } from '@material-ui/core';

import DashboardSidebar from 'src/components/dashboard/DashboardSidebar';
import { CustomerDataProvider } from 'src/customerPages/context/customerData/CustomerDataContext';
import { CustomerInsuranceDataProvider } from 'src/customerPages/context/insurance/CustomerInsuranceContext';
import CustomerPagesFooterImpersonate from 'src/customerPages/layout/common/CustomerPagesFooterImpersonate';
import CustomerPagesNavbar from 'src/customerPages/layout/common/CustomerPagesNavbar';
import useUser from 'src/hooks/useUser';
import { CustomerUser } from 'src/types/auth';

import CustomerPagesFooter from './common/CustomerPagesFooter';
import MarketingConsentBox from '../components/consents/MarketingConsentBox';
import { UnauthenticatedCustomerDataProvider } from '../context/customerData/UnauthorizedCustomerDataContext';
import { UnauthenticatedCustomerInsuranceDataProvider } from '../context/insurance/UnauthorizedCustomerInsuranceContext';
import { MarketingConsentContextProvider } from '../context/marketing/MarketingConsentContext';

const Dashboard: FC = () => {
  const { impersonate } = useUser() as CustomerUser;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Grid
      container
      sx={{ px: 0, '*:focus-visible': { outline: '-webkit-focus-ring-color auto 1px' }, minHeight: '100vh', flexDirection: 'column' }}
    >
      <Grid
        item
        xs={12}
      >
        <CustomerPagesNavbar openSidebar={() => setIsSidebarOpen(true)} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ flex: 1 }}
      >
        <Container
          maxWidth="lg"
          sx={{ px: { xs: 1, md: 1, lg: 1 }, mb: 2, minHeight: '100%' }}
        >
          <DashboardSidebar
            onMobileClose={(): void => setIsSidebarOpen(false)}
            openMobile={isSidebarOpen}
            mobileOnly
          />
          <Outlet />
        </Container>
      </Grid>
      {impersonate && (
        <Grid
          item
          xs={12}
        >
          <CustomerPagesFooterImpersonate />
        </Grid>
      )}
      {!impersonate && (
        <Grid
          item
          xs={12}
        >
          <CustomerPagesFooter />
        </Grid>
      )}
      <MarketingConsentBox />
    </Grid>
  );
};

const CustomerPagesDashboard: FC = () => (
  <CustomerDataProvider>
    <CustomerInsuranceDataProvider>
      <MarketingConsentContextProvider>
        <Dashboard />
      </MarketingConsentContextProvider>
    </CustomerInsuranceDataProvider>
  </CustomerDataProvider>
);

export const UnauthenticatedCustomerPagesDashboard: FC = () => (
  <UnauthenticatedCustomerDataProvider>
    <UnauthenticatedCustomerInsuranceDataProvider>
      <Dashboard />
    </UnauthenticatedCustomerInsuranceDataProvider>
  </UnauthenticatedCustomerDataProvider>
);

export default CustomerPagesDashboard;
