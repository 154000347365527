import { Country } from 'src/api/zrm';

enum DialmakerUrlType {
  MORTGAGE = 'mortagge',
  BLANCO = 'blanco',
  CREDIT_CARD = 'credit_card',
  INSURANCE = 'insurance',
}

const urls = {
  [DialmakerUrlType.MORTGAGE]: [
    {
      country: [Country.SE, Country.NO],
      url: `${process.env.REACT_APP_ZRM_FRONTEND_URL}/mortgage/application`,
    },
  ],
  [DialmakerUrlType.BLANCO]: [
    {
      country: [Country.SE],
      url: `${process.env.REACT_APP_FREJA_URL_SE}/apps`,
    }, {
      country: [Country.NO],
      url: `${process.env.REACT_APP_FREJA_URL_NO}/apps`,
    },
  ],
  [DialmakerUrlType.CREDIT_CARD]: [
    {
      country: [Country.SE, Country.NO],
      url: `${process.env.REACT_APP_ZRM_FRONTEND_URL}/credit-card-application`,
    },
  ],
  [DialmakerUrlType.INSURANCE]: [
    {
      country: [Country.SE, Country.NO],
      url: `${process.env.REACT_APP_ZRM_FRONTEND_URL}/insurance`,
    },
  ],
};

const parseId = (id: string, urlType: DialmakerUrlType) => {
  if (urlType === DialmakerUrlType.BLANCO) return id.slice(4).replace(/^0+/, '');

  return id;
};

const dialmakerGetAppUrl = (appId: string, urlType: DialmakerUrlType, country: Country) => {
  const baseUrl = urls[urlType].find((u) => u.country.includes(country))?.url;
  const url = `${baseUrl}/${parseId(appId, urlType)}`;

  return url;
};

export { DialmakerUrlType };
export default dialmakerGetAppUrl;
