import { ChangeEvent, createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { RoutersApplicationApplicationPayloadSearchPayload, RoutersBankPayloadsSearchPayload } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import { Stage } from 'src/types/stage';

import { AuthenticationPlatform } from './AuthContext';

export enum WhatToSearch {
  mortgages,
  bank_mortgages,
  internal_users,
  insurances,
  leads,
  customers,
  creditCardApplications
}

interface SearchValue {
  whatToSearch: WhatToSearch;
  setWhatToSearch: Dispatch<SetStateAction<WhatToSearch>>;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  kdltSearchBy: string;
  changeKdltSearchKey: (e: ChangeEvent<any>) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  result: { results: { [key in keyof typeof WhatToSearch]: Array<any> }, stage: Stage };
  execute: () => void;
  isBank: boolean;
}

const SearchContext = createContext<SearchValue>(null);

export const SearchProvider = ({ children }) => {
  const [value, setValue] = useState('');
  const [whatToSearch, setWhatToSearch] = useState<WhatToSearch>(WhatToSearch.mortgages);

  const { t } = useTranslation();
  const { api, zakerApi, kdltApi } = useApi();
  const { platform } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const isBank = useMemo(() => platform === AuthenticationPlatform.JWT, [platform]);
  const [result, setResult] = useState<{ results: { [key in keyof typeof WhatToSearch]: Array<any> }, stage: Stage }>({ results: Object.keys(WhatToSearch).reduce((acc, k) => ({ ...acc, [WhatToSearch[k]]: [] }), {} as any), stage: null });
  const [kdltSearchBy, setKdltSearchBy] = useState('pni');

  const changeKdltSearchKey = useMemo(() => (e: ChangeEvent<any>) => {
    setKdltSearchBy(e.target.value);
  }, []);

  const executeSearch = useMemo(() => async (
    whatToSearchDomain: WhatToSearch,
    payload: RoutersBankPayloadsSearchPayload | RoutersApplicationApplicationPayloadSearchPayload,
    kdltSearchByKey: string,
    requestId?: string,
  ) => {
    setResult((p) => ({ ...p, stage: Stage.pending }));

    if (!requestId) requestId = uuid();

    const meta = { cancelToken: requestId, headers: { 'X-Request-ID': requestId } };
    try {
      switch (whatToSearchDomain) {
        case WhatToSearch.bank_mortgages:
          return await api.bank.searchApplicationsBankSearchPost(payload, undefined, meta).then((resp) => {
            setResult((p) => ({ ...p, stage: Stage.fulfilled, results: { ...p.results, [whatToSearchDomain]: resp.data } }));
          });
        case WhatToSearch.mortgages:
          return await api.application.searchApplicationsApplicationSearchPost(payload, undefined, meta).then((resp) => {
            setResult((p) => ({ ...p, stage: Stage.fulfilled, results: { ...p.results, [whatToSearchDomain]: resp.data } }));
          });
        case WhatToSearch.internal_users:
          return await api.employee.searchEmployeeSearchPost(payload, meta).then((resp) => {
            setResult((p) => ({ ...p, stage: Stage.fulfilled, results: { ...p.results, [whatToSearchDomain]: resp.data } }));
          });
        case WhatToSearch.insurances:
          return await zakerApi.api.searchForInsurancesApiInsurancesGet({ q: payload.string }, meta).then((resp) => {
            setResult((p) => ({ ...p, stage: Stage.fulfilled, results: { ...p.results, [whatToSearchDomain]: resp.data?.hits ?? [] } }));
          });
        case WhatToSearch.leads:
          return await api.lead.searchLeadSearchPost({ string: payload.string }, meta).then((resp) => {
            setResult((p) => ({ ...p, stage: Stage.fulfilled, results: { ...p.results, [whatToSearchDomain]: resp.data ?? [] } }));
          });
        case WhatToSearch.customers:
          return await kdltApi.apiGetCustomer({ [kdltSearchByKey]: payload.string, reason: 'Search customer in ZRM content search' }, meta).then((resp) => {
            setResult((p) => ({ ...p, stage: Stage.fulfilled, results: { ...p.results, [whatToSearchDomain]: resp.data.customers ?? [] } }));
          });
        case WhatToSearch.creditCardApplications:
          return await api.application.searchApplicationsApplicationCcNoSearchApplicationsPost({ text: payload.string }, meta).then((resp) => {
            setResult((p) => ({ ...p, stage: Stage.fulfilled, results: { ...p.results, [whatToSearchDomain]: resp.data ?? [] } }));
          });
        default:
          setResult((p) => ({ ...p, stage: Stage.fulfilled, results: { ...p.results, [whatToSearchDomain]: [] } }));
          toast.error(t('Search for {{whatToSearchDomain}} is not implemented', { whatToSearchDomain }) as string);
      }
    } catch (e) {
      if (e?.name === 'AbortError') {
        setResult((p) => ({ ...p, stage: null }));

        return Promise.resolve();
      }

      toast.error(t('Something went wrong with search') as string);
      setResult((p) => ({ ...p, stage: Stage.rejected }));
    }

    return Promise.resolve();
  }, [api, t, zakerApi, kdltApi]);

  useEffect(() => {
    if (!open || !value) return () => { };

    const requestId = uuid();
    const timeout = setTimeout(async () => {
      await executeSearch(whatToSearch, { string: value }, kdltSearchBy, requestId);
    }, 300);

    return () => {
      try {
        clearTimeout(timeout);
        // eslint-disable-next-line no-empty
      } catch (_e) { }
      api.abortRequest(requestId);
    };
  }, [value, open, api, isBank, whatToSearch, kdltSearchBy]);

  const execute = useMemo(() => () => {
    executeSearch(whatToSearch, { string: value }, kdltSearchBy);
  }, [value, executeSearch, kdltSearchBy, whatToSearch]);

  return (
    <SearchContext.Provider value={{
      value,
      setValue,
      whatToSearch,
      setWhatToSearch,
      kdltSearchBy,
      changeKdltSearchKey,
      open,
      setOpen,
      result,
      execute,
      isBank,
    }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
