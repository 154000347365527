import { Country } from 'src/api/zrm';

import { partOfString } from './partOfString';

export const formatPNI = (pni: string, country: Country) => {
  if (!pni?.length) return pni;

  let prefix = '20';
  switch (country) {
    case Country.SE:
      if (pni.length === 13) return pni; // YYYYMMDD-XXXX
      if (pni.length === 12) return `${partOfString(pni, 0, 7)}-${partOfString(pni, 8, 11)}`; // YYYYMMDDXXXX
      if (new Date(`${prefix}${partOfString(pni, 0, 1)}-${partOfString(pni, 2, 3)}-01`) > new Date()) prefix = '19';
      if (pni.length === 10) return `${prefix}${partOfString(pni, 0, 5)}-${partOfString(pni, 6, 9)}`; // YYMMDDXXXX
      if (pni.length === 11) return `${prefix}${pni}`; // YYMMDD-XXXX

      return pni;
    case Country.NO:
    default:
      return pni;
  }
};
