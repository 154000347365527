import { ChangeEvent, FC } from 'react';

import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import CenteredTooltip from 'src/components/widgets/info/CenteredTooltip';

interface CustomerSearchBoxSelectorProps {
  handleChange: (e: ChangeEvent<any>) => void;
  value: string;
}

const CustomerSearchBoxSelector: FC<CustomerSearchBoxSelectorProps> = (props) => {
  const { handleChange, value } = props;

  const { t } = useTranslation();

  return (
    <Grid
      item
      sx={{ px: 2, display: 'flex', alignItems: 'center' }}
    >
      <FormControl>
        <RadioGroup
          row
          name="searchBy"
          onChange={handleChange}
          value={value}
          sx={{ fontSize: '0.8rem' }}
        >
          <FormControlLabel
            value="pni"
            control={(
              <Radio
                size="small"
                sx={{ px: 0.5 }}
              />
            )}
            label="PNI"
            disableTypography
          />
          <FormControlLabel
            value="phone_number"
            control={(
              <Radio
                size="small"
                sx={{ px: 0.5 }}
              />
            )}
            label={t('Phone number')}
            disableTypography
          />
          <FormControlLabel
            value="email"
            control={(
              <Radio
                size="small"
                sx={{ px: 0.5 }}
              />
            )}
            label={t('E-mail')}
            disableTypography
          />
          <FormControlLabel
            value="sverker_id"
            control={(
              <Radio
                size="small"
                sx={{ px: 0.5 }}
              />
            )}
            label="Sverker ID"
            disableTypography
          />
          <FormControlLabel
            value="id"
            control={(
              <Radio
                size="small"
                sx={{ px: 0.5 }}
              />
            )}
            label={t('Customer ID')}
            disableTypography
          />
        </RadioGroup>
      </FormControl>
      <CenteredTooltip title={t('Remember to enter full value of selected search option')}>
        <HelpOutline
          color="action"
          fontSize="small"
        />
      </CenteredTooltip>
    </Grid>

  );
};

export default CustomerSearchBoxSelector;
