import { Country } from 'src/api/zrm';

export const getLocale = (country: Country) => {
  switch (country) {
    case Country.NO:
      return 'nb-NO';
    case Country.SE:
    default:
      return 'sv-SE';
  }
};

export const getLanguage = (country: Country) => {
  switch (country) {
    case Country.NO:
      return 'nb';
    case Country.SE:
    default:
      return 'sv';
  }
};
