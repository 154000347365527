import React from 'react';

import { isSweden } from 'src/utils/country';

const Sweden: React.FC<{}> = (props) => {
  if (!isSweden()) return <></>;

  const { children } = props;

  return (
    <>
      { children }
    </>
  );
};

export default Sweden;
