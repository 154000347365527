import { Country } from 'src/api/zrm';
import useSettings from 'src/hooks/useSettings';

const isCountry = (countryCode: string): boolean => {
  const { country } = useSettings();

  return country === countryCode;
};

export function isSweden(): boolean { return isCountry(Country.SE); }
export function isNorway(): boolean { return isCountry(Country.NO); }
