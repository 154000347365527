import i18n from 'i18next';
import Backend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';
import LastUsed from 'locize-lastused';
import { initReactI18next } from 'react-i18next';

const isProduction = process.env.NODE_ENV === 'production';

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID ?? 'd85e43d9-c971-48dc-adb4-bf42042e20f0',
  apiKey: !isProduction && process.env.REACT_APP_LOCIZE_APIKEY, // YOU should not expose your apps API key to production!!!
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG ?? 'en',
  version: process.env.REACT_APP_LOCIZE_VERSION ?? 'latest',
};

// locize-lastused
// sets a timestamp of last access on every translation segment on locize
// -> safely remove the ones not being touched for weeks/months
// https://github.com/locize/locize-lastused
if (!isProduction) i18n.use(LastUsed);

i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProduction,
    fallbackLng: 'en',
    saveMissing: false, // Save missing translations via npm save-translations script
    // keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
