import { Country } from 'src/api/zrm';

import { partOfString } from './partOfString';

export const formatPostalCode = (postalCode: string, country: Country) => {
  if (!postalCode?.length) return postalCode;

  switch (country) {
    case Country.SE:
      if (postalCode.length === 6) return postalCode; // 112 43
      if (postalCode.length === 5) return `${partOfString(postalCode, 0, 2)} ${partOfString(postalCode, 3, 4)}`; // 11243

      return postalCode;
    case Country.NO:
    default:
      return postalCode;
  }
};
